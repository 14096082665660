import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'
import allDataMapReducer from './reducers/getAllDataMap'
import allDataLevelReducer from './reducers/getDataLevel'
import loadingReducer from './reducers/loading'
import authorizedReducer from './reducers/authorized'
import dataUserReducer from './reducers/users'
import postGuruReducer from './reducers/postGuru'
import profileSekolahReducer from './reducers/profileSekolah'
import getSchoolSearch from './reducers/getSchoolSearch'
import vervalReducer from './reducers/verval';
import educationStepReducer from './reducers/getEducationStep'
import markerDetailReducer from './reducers/getMarkerDetail'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  allDataMapReducer,
  allDataLevelReducer,
  postGuruReducer,
  loading: loadingReducer,
  authorizedReducer,
  dataUserReducer,
  profileSekolahReducer,
  getSchoolSearch,
  vervalReducer,
  educationStepReducer,
  markerDetailReducer
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}
