/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, useEffect, useState } from "react";
import BootstrapTable, { BootstrapTableProps, ColumnDescription, SortOrder } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {KTSVG, toAbsoluteUrl} from "../../../../_metronic/helpers";
import { FileUploader } from "react-drag-drop-files";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

export function Projects(){
  // @ts-ignore
  interface CustomColumnDescription {
    dataField: string;
    text: string;
    sort: boolean;
    sortCaret: (order: SortOrder | undefined, column: any) => JSX.Element | null;
    headerClasses: string;
    formatter?: (cell: any, row: any) => JSX.Element;
  }

  const columns: CustomColumnDescription[] = [
    {
      dataField: 'nama_sekolah',
      text: 'NAMA SEKOLAH/MADRASAH',
      sort: false,
      sortCaret: generateSortCaret,
      headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
    },
    {
      dataField: 'total_siswa',
      text: 'TOTAL SISWA/SISWA THN AJAR BARU',
      sort: true,
      sortCaret: generateSortCaret,
      headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
    },
    {
      dataField: 'jml_guru',
      text: 'JUMLAH GURU PNS/NON PNS',
      sort: true,
      sortCaret: generateSortCaret,
      headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
    },
    {
      dataField: 'jml_karyawan',
      text: 'JUMLAH KARYAWAN',
      sort: true,
      sortCaret: generateSortCaret,
      headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
    },
    {
      dataField: 'create_update',
      text: 'DATA DIBUAT DIREVISI',
      sort: true,
      sortCaret: generateSortCaret,
      headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
    },
    {
      dataField: 'k_dana_taawun',
      text: 'KEWAJIBAN DANA TA’AWUN',
      sort: true,
      sortCaret: generateSortCaret,
      headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
    },
    {
      dataField: 'k_uis',
      text: 'KEWAJIBAN UIS PERTAHUN',
      sort: true,
      sortCaret: generateSortCaret,
      headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
    },
    {
      dataField: 'k_uig',
      text: 'KEWAJIBAN UIG PERTAHUN',
      sort: true,
      sortCaret: generateSortCaret,
      headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
    },
    {
      dataField: 'k_uik',
      text: 'KEWAJIBAN UIK PERTAHUN',
      sort: true,
      sortCaret: generateSortCaret,
      headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
    },
    {
      dataField: 'k_dpp',
      text: 'KEWAJIBAN DPP PERTAHUN',
      sort: true,
      sortCaret: generateSortCaret,
      headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
    },
    {
      dataField: 'k_data_taawun_dibayarkan',
      text: 'DANA TA’AWUN DIBAYARKAN',
      sort: true,
      sortCaret: generateSortCaret,
      headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
    },
    {
      dataField: 'k_uis_dibayarkan',
      text: 'UIS PERTAHUN DIBAYARKAN',
      sort: true,
      sortCaret: generateSortCaret,
      headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
    },
    {
      dataField: 'k_uig_dibayarkan',
      text: 'UIG PERTAHUN DIBAYARKAN',
      sort: true,
      sortCaret: generateSortCaret,
      headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
    },
    {
      dataField: 'k_uik_dibayarkan',
      text: 'UIK PERTAHUN DIBAYARKAN',
      sort: true,
      sortCaret: generateSortCaret,
      headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
    },
    {
      dataField: 'k_dpp_dibayarkan',
      text: 'DPP PERTAHUN DIBAYARKAN',
      sort: true,
      sortCaret: generateSortCaret,
      headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
    },
    {
      dataField: 'selisih_pembayaran',
      text: 'SELISIH PEMBAYARAN',
      sort: true,
      sortCaret: generateSortCaret,
      headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
    },
    {
      dataField: 'penyetor_pembayaran',
      text: 'PENYETOR PEMBAYARAN',
      sort: true,
      sortCaret: generateSortCaret,
      headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
    },
    {
      dataField: 'status_pembayaran',
      text: 'STATUS PEMBAYARAN',
      sort: true,
      sortCaret: generateSortCaret,
      headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
    },
    {
      dataField: 'aksi',
      text: 'Aksi',
      sort: false,
      sortCaret: generateSortCaret,
      headerClasses: 'fixed-header',
      formatter: (cell, row) => (
        <div>
          {/*Ganti dengan ikon mata jika Anda memiliki ikon yang sesuai*/}
          <a className="btn btn-primary btn-sm fa fa-eye" onClick={() => showDetail('sekolah_id_terpilih')} role="button" style={{ marginBottom: '5px' }}></a>
          {/*<button onClick={() => showDetail('sekolah_id_terpilih')}>Show Detail</button>*/}
          <a className="btn btn-info btn-sm fas fa-print" role="button"></a>
        </div>

      )
    },
  ];

  function generateSortCaret(order: SortOrder | undefined, column: any): JSX.Element | null {
    if (!order) return <span>&nbsp;&nbsp;<i className="fa fa-sort"></i></span>;
    else if (order === 'asc') return <span>&nbsp;&nbsp;<i className="fa fa-sort-up"></i></span>;
    else if (order === 'desc') return <span>&nbsp;&nbsp;<i className="fa fa-sort-down"></i></span>;
    return null;
  }
  const products = [
    { id: 1, nama_sekolah: 'SMK Muhammadiyah 3 Karanganyar', total_siswa: '1304/186', jml_guru: '186', jml_karyawan: 62, create_update: '02/20/24 / 02/25/24', k_dana_taawun: ' Rp32.658.680', k_uis: 'Rp19.595.208', k_uig: ' Rp3.919.042', k_uik: 'Rp2.612.694', k_dpp: 'Rp6.531.736', k_data_taawun_dibayarkan: 'Rp32.444.060', k_uis_dibayarkan: 'Rp19.466.436', k_uig_dibayarkan: 'Rp3.893.287', k_uik_dibayarkan: 'Rp2.595.525', k_dpp_dibayarkan: 'Rp6.488.812', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Muh. Andika', status_pembayaran: 'Dibayar', aksi: 'edit print'},
    { id: 2, nama_sekolah: 'SMA Negeri 1 Surakarta', total_siswa: '1200/50', jml_guru: '50', jml_karyawan: 40, create_update: '01/15/24 / 01/20/24', k_dana_taawun: 'Rp30.000.000', k_uis: 'Rp18.000.000', k_uig: 'Rp3.600.000', k_uik: 'Rp2.400.000', k_dpp: 'Rp6.000.000', k_data_taawun_dibayarkan: 'Rp29.785.380', k_uis_dibayarkan: 'Rp17.871.228', k_uig_dibayarkan: 'Rp3.574.246', k_uik_dibayarkan: 'Rp2.382.830', k_dpp_dibayarkan: 'Rp5.956.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Budi Santoso', status_pembayaran: 'Dibayar', aksi: 'edit print' },
    { id: 3, nama_sekolah: 'SMK Negeri 2 Solo', total_siswa: '1000/60', jml_guru: '60', jml_karyawan: 45, create_update: '03/05/24 / 03/10/24', k_dana_taawun: 'Rp35.000.000', k_uis: 'Rp21.000.000', k_uig: 'Rp4.200.000', k_uik: 'Rp2.800.000', k_dpp: 'Rp7.000.000', k_data_taawun_dibayarkan: 'Rp34.785.380', k_uis_dibayarkan: 'Rp20.871.228', k_uig_dibayarkan: 'Rp4.174.246', k_uik_dibayarkan: 'Rp2.782.830', k_dpp_dibayarkan: 'Rp6.956.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Lina Setiawan', status_pembayaran: 'Dibayar', aksi: 'edit print' },
    { id: 4, nama_sekolah: 'SMP Islam Terpadu Solo', total_siswa: '800/40', jml_guru: '40', jml_karyawan: 30, create_update: '04/10/24 / 04/15/24', k_dana_taawun: 'Rp25.000.000', k_uis: 'Rp15.000.000', k_uig: 'Rp3.000.000', k_uik: 'Rp2.000.000', k_dpp: 'Rp5.000.000', k_data_taawun_dibayarkan: 'Rp24.785.380', k_uis_dibayarkan: 'Rp14.871.228', k_uig_dibayarkan: 'Rp2.974.246', k_uik_dibayarkan: 'Rp1.982.830', k_dpp_dibayarkan: 'Rp4.956.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Rudi Hartono', status_pembayaran: 'Dibayar', aksi: 'edit print' },
    { id: 5, nama_sekolah: 'SD Islam Al-Azhar Solo', total_siswa: '600/30', jml_guru: '30', jml_karyawan: 25, create_update: '05/20/24 / 05/25/24', k_dana_taawun: 'Rp20.000.000', k_uis: 'Rp12.000.000', k_uig: 'Rp2.400.000', k_uik: 'Rp1.600.000', k_dpp: 'Rp4.000.000', k_data_taawun_dibayarkan: 'Rp19.785.380', k_uis_dibayarkan: 'Rp11.871.228', k_uig_dibayarkan: 'Rp2.374.246', k_uik_dibayarkan: 'Rp1.582.830', k_dpp_dibayarkan: 'Rp3.956.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Siti Rahayu', status_pembayaran: 'Dibayar', aksi: 'edit print' },
    { id: 6, nama_sekolah: 'TK Islam Terpadu Solo', total_siswa: '400/20', jml_guru: '20', jml_karyawan: 15, create_update: '06/30/24 / 07/05/24', k_dana_taawun: 'Rp15.000.000', k_uis: 'Rp9.000.000', k_uig: 'Rp1.800.000', k_uik: 'Rp1.200.000', k_dpp: 'Rp3.000.000', k_data_taawun_dibayarkan: 'Rp14.785.380', k_uis_dibayarkan: 'Rp8.871.228', k_uig_dibayarkan: 'Rp1.774.246', k_uik_dibayarkan: 'Rp1.182.830', k_dpp_dibayarkan: 'Rp2.956.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Dewi Setyawati', status_pembayaran: 'Dibayar', aksi: 'edit print' },
    { id: 7, nama_sekolah: 'SMA Kristen Petra Solo', total_siswa: '1100/55', jml_guru: '55', jml_karyawan: 45, create_update: '07/10/24 / 07/15/24', k_dana_taawun: 'Rp32.000.000', k_uis: 'Rp19.200.000', k_uig: 'Rp3.840.000', k_uik: 'Rp2.560.000', k_dpp: 'Rp6.400.000', k_data_taawun_dibayarkan: 'Rp31.785.380', k_uis_dibayarkan: 'Rp19.071.228', k_uig_dibayarkan: 'Rp3.814.246', k_uik_dibayarkan: 'Rp2.542.830', k_dpp_dibayarkan: 'Rp6.356.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Hendro Wibowo', status_pembayaran: 'Dibayar', aksi: 'edit print' },
    { id: 8, nama_sekolah: 'SMK Muhammadiyah 1 Surakarta', total_siswa: '1300/65', jml_guru: '65', jml_karyawan: 50, create_update: '08/25/24 / 08/30/24', k_dana_taawun: 'Rp36.000.000', k_uis: 'Rp21.600.000', k_uig: 'Rp4.320.000', k_uik: 'Rp2.880.000', k_dpp: 'Rp7.200.000', k_data_taawun_dibayarkan: 'Rp35.785.380', k_uis_dibayarkan: 'Rp21.471.228', k_uig_dibayarkan: 'Rp4.294.246', k_uik_dibayarkan: 'Rp2.862.830', k_dpp_dibayarkan: 'Rp7.156.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Wahyu Kurniawan', status_pembayaran: 'Dibayar', aksi: 'edit print' },
    { id: 9, nama_sekolah: 'SMP Negeri 3 Surakarta', total_siswa: '900/45', jml_guru: '45', jml_karyawan: 35, create_update: '09/05/24 / 09/10/24', k_dana_taawun: 'Rp28.000.000', k_uis: 'Rp16.800.000', k_uig: 'Rp3.360.000', k_uik: 'Rp2.240.000', k_dpp: 'Rp5.600.000', k_data_taawun_dibayarkan: 'Rp27.785.380', k_uis_dibayarkan: 'Rp16.671.228', k_uig_dibayarkan: 'Rp3.334.246', k_uik_dibayarkan: 'Rp2.222.830', k_dpp_dibayarkan: 'Rp5.556.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Fitri Ananda', status_pembayaran: 'Dibayar', aksi: 'edit print' },
    { id: 10, nama_sekolah: 'SD Negeri 1 Solo', total_siswa: '700/35', jml_guru: '35', jml_karyawan: 30, create_update: '10/20/24 / 10/25/24', k_dana_taawun: 'Rp22.000.000', k_uis: 'Rp13.200.000', k_uig: 'Rp2.640.000', k_uik: 'Rp1.760.000', k_dpp: 'Rp4.400.000', k_data_taawun_dibayarkan: 'Rp21.785.380', k_uis_dibayarkan: 'Rp13.071.228', k_uig_dibayarkan: 'Rp2.614.246', k_uik_dibayarkan: 'Rp1.742.830', k_dpp_dibayarkan: 'Rp4.356.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Rina Widya', status_pembayaran: 'Dibayar', aksi: 'edit print' },
    { id: 11, nama_sekolah: 'TK Kartika Solo', total_siswa: '500/25', jml_guru: '25', jml_karyawan: 20, create_update: '11/30/24 / 12/05/24', k_dana_taawun: 'Rp18.000.000', k_uis: 'Rp10.800.000', k_uig: 'Rp2.160.000', k_uik: 'Rp1.440.000', k_dpp: 'Rp3.600.000', k_data_taawun_dibayarkan: 'Rp17.785.380', k_uis_dibayarkan: 'Rp10.671.228', k_uig_dibayarkan: 'Rp2.134.246', k_uik_dibayarkan: 'Rp1.422.830', k_dpp_dibayarkan: 'Rp3.556.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Fita Wulandari', status_pembayaran: 'Dibayar', aksi: 'edit print' },
    { id: 12, nama_sekolah: 'SD Kristen Immanuel Solo', total_siswa: '800/40', jml_guru: '40', jml_karyawan: 35, create_update: '12/10/24 / 12/15/24', k_dana_taawun: 'Rp24.000.000', k_uis: 'Rp14.400.000', k_uig: 'Rp2.880.000', k_uik: 'Rp1.920.000', k_dpp: 'Rp4.800.000', k_data_taawun_dibayarkan: 'Rp23.785.380', k_uis_dibayarkan: 'Rp14.271.228', k_uig_dibayarkan: 'Rp2.854.246', k_uik_dibayarkan: 'Rp1.902.830', k_dpp_dibayarkan: 'Rp4.756.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Larasati', status_pembayaran: 'Dibayar', aksi: 'edit print' },
    { id: 13, nama_sekolah: 'SMA Negeri 5 Solo', total_siswa: '1000/50', jml_guru: '50', jml_karyawan: 40, create_update: '01/15/25 / 01/20/25', k_dana_taawun: 'Rp30.000.000', k_uis: 'Rp18.000.000', k_uig: 'Rp3.600.000', k_uik: 'Rp2.400.000', k_dpp: 'Rp6.000.000', k_data_taawun_dibayarkan: 'Rp29.785.380', k_uis_dibayarkan: 'Rp17.871.228', k_uig_dibayarkan: 'Rp3.574.246', k_uik_dibayarkan: 'Rp2.382.830', k_dpp_dibayarkan: 'Rp5.956.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Firman Maulana', status_pembayaran: 'Dibayar', aksi: 'edit print' },
    { id: 14, nama_sekolah: 'SMK Negeri 1 Surakarta', total_siswa: '1100/55', jml_guru: '55', jml_karyawan: 45, create_update: '02/25/25 / 03/02/25', k_dana_taawun: 'Rp32.000.000', k_uis: 'Rp19.200.000', k_uig: 'Rp3.840.000', k_uik: 'Rp2.560.000', k_dpp: 'Rp6.400.000', k_data_taawun_dibayarkan: 'Rp31.785.380', k_uis_dibayarkan: 'Rp19.071.228', k_uig_dibayarkan: 'Rp3.814.246', k_uik_dibayarkan: 'Rp2.542.830', k_dpp_dibayarkan: 'Rp6.356.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Sofyan', status_pembayaran: 'Dibayar', aksi: 'edit print' },
    { id: 15, nama_sekolah: 'SMP Negeri 2 Surakarta', total_siswa: '900/45', jml_guru: '45', jml_karyawan: 35, create_update: '03/10/25 / 03/15/25', k_dana_taawun: 'Rp28.000.000', k_uis: 'Rp16.800.000', k_uig: 'Rp3.360.000', k_uik: 'Rp2.240.000', k_dpp: 'Rp5.600.000', k_data_taawun_dibayarkan: 'Rp27.785.380', k_uis_dibayarkan: 'Rp16.671.228', k_uig_dibayarkan: 'Rp3.334.246', k_uik_dibayarkan: 'Rp2.222.830', k_dpp_dibayarkan: 'Rp5.556.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Ani Wijaya', status_pembayaran: 'Dibayar', aksi: 'edit print' },
    { id: 16, nama_sekolah: 'SD Kristen Petra Solo', total_siswa: '800/40', jml_guru: '40', jml_karyawan: 35, create_update: '04/20/25 / 04/25/25', k_dana_taawun: 'Rp24.000.000', k_uis: 'Rp14.400.000', k_uig: 'Rp2.880.000', k_uik: 'Rp1.920.000', k_dpp: 'Rp4.800.000', k_data_taawun_dibayarkan: 'Rp23.785.380', k_uis_dibayarkan: 'Rp14.271.228', k_uig_dibayarkan: 'Rp2.854.246', k_uik_dibayarkan: 'Rp1.902.830', k_dpp_dibayarkan: 'Rp4.756.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Rina Pratiwi', status_pembayaran: 'Dibayar', aksi: 'edit print' },
    { id: 17, nama_sekolah: 'TK Islam Al-Azhar Solo', total_siswa: '600/30', jml_guru: '30', jml_karyawan: 25, create_update: '05/05/25 / 05/10/25', k_dana_taawun: 'Rp20.000.000', k_uis: 'Rp12.000.000', k_uig: 'Rp2.400.000', k_uik: 'Rp1.600.000', k_dpp: 'Rp4.000.000', k_data_taawun_dibayarkan: 'Rp19.785.380', k_uis_dibayarkan: 'Rp11.871.228', k_uig_dibayarkan: 'Rp2.374.246', k_uik_dibayarkan: 'Rp1.582.830', k_dpp_dibayarkan: 'Rp3.956.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Lia Agustina', status_pembayaran: 'Dibayar', aksi: 'edit print' },
    { id: 18, nama_sekolah: 'SD Kristen Dharma Wacana Solo', total_siswa: '700/35', jml_guru: '35', jml_karyawan: 30, create_update: '06/10/25 / 06/15/25', k_dana_taawun: 'Rp22.000.000', k_uis: 'Rp13.200.000', k_uig: 'Rp2.640.000', k_uik: 'Rp1.760.000', k_dpp: 'Rp4.400.000', k_data_taawun_dibayarkan: 'Rp21.785.380', k_uis_dibayarkan: 'Rp13.071.228', k_uig_dibayarkan: 'Rp2.614.246', k_uik_dibayarkan: 'Rp1.742.830', k_dpp_dibayarkan: 'Rp4.356.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Devi Lestari', status_pembayaran: 'Dibayar', aksi: 'edit print' },
    { id: 19, nama_sekolah: 'SMA Islam Terpadu Solo', total_siswa: '1200/60', jml_guru: '60', jml_karyawan: 50, create_update: '07/20/25 / 07/25/25', k_dana_taawun: 'Rp35.000.000', k_uis: 'Rp21.000.000', k_uig: 'Rp4.200.000', k_uik: 'Rp2.800.000', k_dpp: 'Rp7.000.000', k_data_taawun_dibayarkan: 'Rp34.785.380', k_uis_dibayarkan: 'Rp20.871.228', k_uig_dibayarkan: 'Rp4.174.246', k_uik_dibayarkan: 'Rp2.782.830', k_dpp_dibayarkan: 'Rp6.956.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Putri Haryanto', status_pembayaran: 'Dibayar', aksi: 'edit print' },
    { id: 20, nama_sekolah: 'SMK Kristen Immanuel Solo', total_siswa: '1300/65', jml_guru: '65', jml_karyawan: 50, create_update: '08/05/25 / 08/10/25', k_dana_taawun: 'Rp36.000.000', k_uis: 'Rp21.600.000', k_uig: 'Rp4.320.000', k_uik: 'Rp2.880.000', k_dpp: 'Rp7.200.000', k_data_taawun_dibayarkan: 'Rp35.785.380', k_uis_dibayarkan: 'Rp21.471.228', k_uig_dibayarkan: 'Rp4.294.246', k_uik_dibayarkan: 'Rp2.862.830', k_dpp_dibayarkan: 'Rp7.156.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Agus Susanto', status_pembayaran: 'Dibayar', aksi: 'edit print' },
    { id: 21, nama_sekolah: 'SMP Kristen Petra Solo', total_siswa: '1000/50', jml_guru: '50', jml_karyawan: 40, create_update: '09/20/25 / 09/25/25', k_dana_taawun: 'Rp30.000.000', k_uis: 'Rp18.000.000', k_uig: 'Rp3.600.000', k_uik: 'Rp2.400.000', k_dpp: 'Rp6.000.000', k_data_taawun_dibayarkan: 'Rp29.785.380', k_uis_dibayarkan: 'Rp17.871.228', k_uig_dibayarkan: 'Rp3.574.246', k_uik_dibayarkan: 'Rp2.382.830', k_dpp_dibayarkan: 'Rp5.956.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Dewi Susanti', status_pembayaran: 'Dibayar', aksi: 'edit print' },
    { id: 22, nama_sekolah: 'SD Kristen Alfa Omega Solo', total_siswa: '800/40', jml_guru: '40', jml_karyawan: 35, create_update: '10/05/25 / 10/10/25', k_dana_taawun: 'Rp24.000.000', k_uis: 'Rp14.400.000', k_uig: 'Rp2.880.000', k_uik: 'Rp1.920.000', k_dpp: 'Rp4.800.000', k_data_taawun_dibayarkan: 'Rp23.785.380', k_uis_dibayarkan: 'Rp14.271.228', k_uig_dibayarkan: 'Rp2.854.246', k_uik_dibayarkan: 'Rp1.902.830', k_dpp_dibayarkan: 'Rp4.756.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Nita Pratiwi', status_pembayaran: 'Dibayar', aksi: 'edit print' },
    { id: 23, nama_sekolah: 'TK Kristen Joyful Solo', total_siswa: '600/30', jml_guru: '30', jml_karyawan: 25, create_update: '11/20/25 / 11/25/25', k_dana_taawun: 'Rp20.000.000', k_uis: 'Rp12.000.000', k_uig: 'Rp2.400.000', k_uik: 'Rp1.600.000', k_dpp: 'Rp4.000.000', k_data_taawun_dibayarkan: 'Rp19.785.380', k_uis_dibayarkan: 'Rp11.871.228', k_uig_dibayarkan: 'Rp2.374.246', k_uik_dibayarkan: 'Rp1.582.830', k_dpp_dibayarkan: 'Rp3.956.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Dian Fitriani', status_pembayaran: 'Dibayar', aksi: 'edit print' },
    { id: 24, nama_sekolah: 'SD Islam Al-Khairiyah Solo', total_siswa: '700/35', jml_guru: '35', jml_karyawan: 30, create_update: '12/05/25 / 12/10/25', k_dana_taawun: 'Rp22.000.000', k_uis: 'Rp13.200.000', k_uig: 'Rp2.640.000', k_uik: 'Rp1.760.000', k_dpp: 'Rp4.400.000', k_data_taawun_dibayarkan: 'Rp21.785.380', k_uis_dibayarkan: 'Rp13.071.228', k_uig_dibayarkan: 'Rp2.614.246', k_uik_dibayarkan: 'Rp1.742.830', k_dpp_dibayarkan: 'Rp4.356.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Nur Cahyani', status_pembayaran: 'Dibayar', aksi: 'edit print' },
    { id: 25, nama_sekolah: 'SMA Kristen Mardi Waluyo Solo', total_siswa: '1300/65', jml_guru: '65', jml_karyawan: 50, create_update: '01/05/26 / 01/10/26', k_dana_taawun: 'Rp36.000.000', k_uis: 'Rp21.600.000', k_uig: 'Rp4.320.000', k_uik: 'Rp2.880.000', k_dpp: 'Rp7.200.000', k_data_taawun_dibayarkan: 'Rp35.785.380', k_uis_dibayarkan: 'Rp21.471.228', k_uig_dibayarkan: 'Rp4.294.246', k_uik_dibayarkan: 'Rp2.862.830', k_dpp_dibayarkan: 'Rp7.156.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Andi Setiawan', status_pembayaran: 'Dibayar', aksi: 'edit print' }
  ];

  const customTotal = (from: number, to: number, size: number) => (
    <span className="react-bootstrap-table-pagination-total">
    Showing { from } to { to } of { size } Results
  </span>
  );

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: 'First',
    prePageText: 'Previous',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [{
      text: '5', value: 5
    }, {
      text: '10', value: 10
    }, {
      text: 'All', value: products.length
    }]
  };

  const [selectedSekolahId, setSelectedSekolahId] = useState(null); // State untuk menyimpan sekolah_id yang dipilih

  // Fungsi untuk menampilkan detail sekolah
  const showDetail = (sekolah_id: any) => {
    setSelectedSekolahId(sekolah_id); // Set sekolah_id yang dipilih
  };

// Fungsi untuk menutup detail sekolah dan kembali ke tabel
  const backToTable = () => {
    setSelectedSekolahId(null); // Reset sekolah_id yang dipilih
  };


  const [selectedValue, setSelectedValue] = useState('');
  const isLoading = useSelector((state: RootState) => state.loading.loading)
  const history = useHistory();
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(e.target.value);
  };

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const handleFileChange = (files: File[]) => {
    // Handle the uploaded files here
    setUploadedFiles(files);
    sendFilesToBackend(files);
  };

  const fileTypes = ["pdf"];
  const [file, setFile] = useState<File[] | null>(null);
  const handleChange = (filex: File[]) => {
    setFile(filex);
    sendFilesToBackend(filex);
    console.log(filex);
  };

  const handleRemoveFile = () => {
    setFile(null); // Menggunakan null ketika ingin menghapus file
  };

  const uuid = localStorage.getItem('sch_id');

  const infos = "Drag n drop unggah seluruh file bukti pembayaran Dana Ta'awun disini (PDF maks 2MB, min width 400px)"
  const [formData, setFormData] = useState({
    provinsi: "",
    kabupaten: "",
    kecamatan: "",
    npsnData: "",
    nama: "",
    alamat: "",
    jumlah_siswa: "",
    jumlah_siswa_baru: "",
    jumlah_karyawan: "",
    total_guru: "",
    bendahara_sekolah: "",
    total_guru_pns: "",
    tahun_ajaran: "",
    selectedMonth: "",
    nama_kepsek: "",
    no_bendahara: "",
    no_kepsek: "",
    tahun_kepimpinan: "",
    max_take_home_pay: "",
    min_take_home_pay: "",
    total_allowence_non_pns: "",
    total_allowence_pns: "",
    total_allowence_employee: "",
    nonSpp: false,
    sppSame: false,
    sppDifferent: false,
    dppSame: false,
    dppDifferent: false,
    spp: [
      {
        total_student: "",
        nominal: ""
      }
    ],
    dpp: [
      {
        total_student: "",
        nominal: ""
      }
    ],
  });

  const [formDatax, setFormDatax] = useState({
    disdasmen_cabang: "",
    disdasmen_daerah: "",
    disdasmen_pusat: "",
    disdasmen_wilayah: "",
    pimpinan_cabang: "",
    pimpinan_daerah: "",
    pimpinan_pusat: "",
    pimpinan_ranting: "",
    pimpinan_wilayah: "",
    kewajiban_dpp: "",
    kewajiban_uig: "",
    kewajiban_uik: "",
    kewajiban_uis: "",
    total_dpp_majelis:"",
    kewajiban_uis_uig_uik: "",
    total_dana_taawun: "",
    disdasmen_cabangx: "",
    disdasmen_daerahx: "",
    disdasmen_pusatx: "",
    disdasmen_wilayahx: "",
  });

  // type NomorRekeningItem = {
  //   ppm: {
  //     nama_group: string;
  //     nama_bank: string;
  //     no_rekening: string;
  //     nama_rekening: string;
  //   }[];
  //   pwm: {
  //     nama_group: string;
  //     nama_bank: string;
  //     no_rekening: string;
  //     nama_rekening: string;
  //   }[];
  //   pdm: {
  //     nama_group: string;
  //     nama_bank: string;
  //     no_rekening: string;
  //     nama_rekening: string;
  //   }[];
  //   pcm: {
  //     nama_group: string;
  //     nama_bank: string;
  //     no_rekening: string;
  //     nama_rekening: string;
  //   }[];
  //   pp: {
  //     nama_group: string;
  //     nama_bank: string;
  //     no_rekening: string;
  //     nama_rekening: string;
  //   }[];
  //   pw: {
  //     nama_group: string;
  //     nama_bank: string;
  //     no_rekening: string;
  //     nama_rekening: string;
  //   }[];
  //   pd: {
  //     nama_group: string;
  //     nama_bank: string;
  //     no_rekening: string;
  //     nama_rekening: string;
  //   }[];
  //   pc: {
  //     nama_group: string;
  //     nama_bank: string;
  //     no_rekening: string;
  //     nama_rekening: string;
  //   }[];
  //   pr: {
  //     nama_group: string;
  //     nama_bank: string;
  //     no_rekening: string;
  //     nama_rekening: string;
  //   }[];
  //   // Sisipkan jenis Akun Rekening lainnya jika diperlukan
  // };

  // State untuk menyimpan pesan kesalahan validasi
  type NomorRekeningItem = {
    [jenisRekening: string]: {
      nama_group: string;
      nama_bank: string;
      no_rekening: string;
      nama_rekening: string;
    }[];
  };

  const initialDataNR: NomorRekeningItem = {
    ppm: [
      {
        nama_group: "Majelis Dikdasmen dan PNF PPM",
        nama_bank: "Bank BRI",
        no_rekening: "0230-01-000532-30-9",
        nama_rekening: "Majlis Dikdasmen PP Muhammadyah"
      }
    ],
    pwm: [
      {
        nama_group: "Dikdasmen dan PNF PWM",
        nama_bank: "",
        no_rekening: "",
        nama_rekening: ""
      }
    ],
    pdm: [
      {
        nama_group: "Dikdasmen dan PNF PDM",
        nama_bank: "",
        no_rekening: "",
        nama_rekening: ""
      }
    ],
    pcm: [
      {
        nama_group: "Dikdasmen dan PNF PCM",
        nama_bank: "",
        no_rekening: "",
        nama_rekening: ""
      }
    ],
    pp: [
      {
        nama_group: "Pimpinan Pusat Muhammadiyah",
        nama_bank: "",
        no_rekening: "",
        nama_rekening: ""
      }
    ],
    pw: [
      {
        nama_group: "Pimpinan Wilayah Muhammadiyah",
        nama_bank: "",
        no_rekening: "",
        nama_rekening: ""
      }
    ],
    pd: [
      {
        nama_group: "Pimpinan Daerah Muhammadiyah",
        nama_bank: "",
        no_rekening: "",
        nama_rekening: ""
      }
    ],
    pc: [
      {
        nama_group: "Pimpinan Cabang Muhammadiyah",
        nama_bank: "",
        no_rekening: "",
        nama_rekening: ""
      }
    ],
    pr: [
      {
        nama_group: "Pimpinan Ranting Muhammadiyah",
        nama_bank: "",
        no_rekening: "",
        nama_rekening: ""
      }
    ]
  };

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [notification, setNotification] = useState({
    message: "",
  });

  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  // State untuk mengendalikan modal
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const formatRupiah = (angka: string) => {
    const numberString = angka.replace(/[^,\d]/g, '').toString();
    const split = numberString.split(',');
    const sisa = split[0].length % 3;
    let rupiah = split[0].substr(0, sisa);
    const ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    if (ribuan) {
      const separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
    return rupiah;
  };

  const handleInputChangeSpp = (event: ChangeEvent<HTMLInputElement>, fieldName: string, index: number | null = null) => {
    // Mengecek jika fieldName adalah salah satu dari 'spp' atau 'dpp'
    if (fieldName === 'spp' || fieldName === 'dpp') {
      const updatedFields = [...formData[fieldName]];
      if (index !== null && index >= 0 && index < updatedFields.length) {
        updatedFields[index] = {
          ...updatedFields[index],
          nominal: formatRupiah(event.target.value),
        };

        setFormData((prevFormData) => ({
          ...prevFormData,
          [fieldName]: updatedFields,
        }));
      }
    } else {
      // Penanganan lain jika fieldName bukan 'spp' atau 'dpp'
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: formatRupiah(event.target.value),
      }));
    }
  };

  // State yang menggabungkan semua jenis Akun Rekening dengan nama_group
  const [dataNR, setdataNR] = useState<NomorRekeningItem>({
    ppm: [
      {
        nama_group: "Majelis Dikdasmen dan PNF PPM",
        nama_bank: "Bank BRI",
        no_rekening: "0230-01-000532-30-9",
        nama_rekening: "Majlis Dikdasmen PP Muhammadyah"
      }
    ],
    pwm: [
      {
        nama_group: "Dikdasmen dan PNF PWM ",
        nama_bank: "",
        no_rekening: "",
        nama_rekening: ""
      }
    ],
    pdm: [
      {
        nama_group: "Dikdasmen dan PNF PDM",
        nama_bank: "",
        no_rekening: "",
        nama_rekening: ""
      }
    ],
    pcm: [
      {
        nama_group: "Dikdasmen dan PNF PCM",
        nama_bank: "",
        no_rekening: "",
        nama_rekening: ""
      }
    ],
    pp: [
      {
        nama_group: "Pimpinan Pusat Muhammadiyah",
        nama_bank: "",
        no_rekening: "",
        nama_rekening: ""
      }
    ],
    pw: [
      {
        nama_group: "Pimpinan Wilayah Muhammadiyah",
        nama_bank: "",
        no_rekening: "",
        nama_rekening: ""
      }
    ],
    pd: [
      {
        nama_group: "Pimpinan Daerah Muhammadiyah",
        nama_bank: "",
        no_rekening: "",
        nama_rekening: ""
      }
    ],
    pc: [
      {
        nama_group: "Pimpinan Cabang Muhammadiyah",
        nama_bank: "",
        no_rekening: "",
        nama_rekening: ""
      }
    ],
    pr: [
      {
        nama_group: "Pimpinan Ranting Muhammadiyah",
        nama_bank: "",
        no_rekening: "",
        nama_rekening: ""
      }
    ]
    // Tambahkan jenis Akun Rekening lainnya jika diperlukan
  });

  // useEffect(() => {
  //   // Periksa jika semua data telah terisi
  //   const isDataComplete = Object.values(initialResult).every((data) => {
  //     if (Array.isArray(data)) {
  //       // Periksa jika semua elemen dalam array ada yang tidak terisi (kosong)
  //       return !data.includes('');
  //     } else {
  //       // Periksa jika data selain array ada yang tidak terisi (kosong)
  //       return data !== '';
  //     }
  //   });
  //
  //   setIsFormValid(isDataComplete);
  // }, [dataNR]);

  const handleInputChange = (jenisRekening: string, index: number, field: string, value: string) => {
    setdataNR((prevData) => ({
      ...prevData,
      [jenisRekening]: prevData[jenisRekening].map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      ),
    }));
  };

  const handlePrint = () => {
    window.scrollTo(0, 0);
    const currentPosition = window.scrollY;
    console.log(currentPosition)
    // window.scrollTo(0, 0);
    if (currentPosition == 0) {
      setTimeout(() => {
        window.print();
      }, 1000); // Ubah jumlah milidetik sesuai kebutuhan Anda
    }

  };

  interface GroupedData {
    nama_group: string[];
    nama_bank: string[];
    no_rekening: string[];
    nama_rekening: string[];
  }

  const initialResult: GroupedData = {
    nama_group: [],
    nama_bank: [],
    no_rekening: [],
    nama_rekening: [],
  };

  const groupedData: GroupedData = Object.keys(dataNR).reduce((result, key) => {
    dataNR[key].forEach((item) => {
      if (!result.nama_group) {
        result.nama_group = [];
      }
      if (!result.nama_bank) {
        result.nama_bank = [];
      }
      if (!result.no_rekening) {
        result.no_rekening = [];
      }
      if (!result.nama_rekening) {
        result.nama_rekening = [];
      }

      result.nama_group.push(item.nama_group);
      result.nama_bank.push(item.nama_bank);
      result.no_rekening.push(item.no_rekening);
      result.nama_rekening.push(item.nama_rekening);
    });

    return result;
  }, initialResult);

  // const [isFormValid, setIsFormValid] = useState(false);

  // Handle klik tombol "Simpan"
  const handleSimpanClick = () => {

    console.log(initialResult);
    // Kirim data ke server menggunakan Axios
    const API = process.env.REACT_APP_API_URL_NEW
    const TOKEN = process.env.REACT_APP_TOKEN_NEW

    axios
      .post(`${API}/dana-taawun/create-rekening`, initialResult, {
        headers: {
          'Content-Type': 'application/json',
          key: TOKEN,
          Authorization: `Bearer ${localStorage.access_token}`,
        },
      })

      .then((response) => {
        // Tangani respon dari server jika diperlukan
        toast.success('Data berhasil disimpan', {
          position: "bottom-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        // Tangani error jika ada
        toast.warn(`Gagal menyimpan data`, {
          position: "bottom-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  useEffect(() => {
    if (!localStorage.getItem('access_token')) {
      history.push('/auth/login')
    }
    // Ganti URL dengan URL yang sesuai untuk mengambil data Anda
    const API = process.env.REACT_APP_API_URL_NEW;
    const TOKEN = process.env.REACT_APP_TOKEN_NEW;
    axios.post(`${API}/dana-taawun/sekolah/${uuid}`, {
      headers: {
        key: TOKEN,
        Authorization: `Bearer ${localStorage.access_token}`,
      },
    })
      .then((response) => {
        setFormData({
          ...formData,
          provinsi: response.data.data.provinsi,
          kabupaten: response.data.data.kabupaten,
          kecamatan: response.data.data.kecamatan,
          npsnData: response.data.data.npsn,
          nama: response.data.data.nama,
          alamat: response.data.data.alamat,
          jumlah_siswa: response.data.data.total.siswa,
          total_guru: response.data.data.total.total_guru,
          total_guru_pns: response.data.data.total.total_guru_pns,
          // npsnData: response.data.npsnData // Sesuaikan dengan respons dari backend
        });
        // Setelah mendapatkan data provinsi, perbarui array pwm
        const newPWM = [
          {
            nama_group: `Dikdasmen dan PNF PWM ${response.data.data.detail.provinsi}`,
            nama_bank: "",
            no_rekening: "",
            nama_rekening: ""
          }
        ];
        const newPDM = [
          {
            nama_group: `Dikdasmen dan PNF PDM ${response.data.data.detail.kabupaten}`,
            nama_bank: "",
            no_rekening: "",
            nama_rekening: ""
          }
        ];
        const newPCM = [
          {
            nama_group: `Dikdasmen dan PNF PCM ${response.data.data.detail.kecamatan}`,
            nama_bank: "",
            no_rekening: "",
            nama_rekening: ""
          }
        ];
        const newPW = [
          {
            nama_group: `Pimpinan Wilayah Muhammadiyah ${response.data.data.detail.provinsi}`,
            nama_bank: "",
            no_rekening: "",
            nama_rekening: ""
          }
        ];
        const newPD = [
          {
            nama_group: `Pimpinan Daerah Muhammadiyah ${response.data.data.detail.kabupaten}`,
            nama_bank: "",
            no_rekening: "",
            nama_rekening: ""
          }
        ];
        const newPC = [
          {
            nama_group: `Pimpinan Cabang Muhammadiyah ${response.data.data.detail.kecamatan}`,
            nama_bank: "",
            no_rekening: "",
            nama_rekening: ""
          }
        ];
        const newPR = [
          {
            nama_group: `Pimpinan Ranting Muhammadiyah ${response.data.data.detail.desa_kelurahan}`,
            nama_bank: "",
            no_rekening: "",
            nama_rekening: ""
          }
        ];

        setdataNR((prevData) => ({
          ...prevData,
          pwm: newPWM,
          pdm: newPDM,
          pcm: newPCM,
          pw: newPW,
          pd: newPD,
          pc: newPC,
          pr: newPR,
        }));

        console.log(response.data.data.total.siswa);
        toast.success('Berhasil mengambil data', {
          position: "bottom-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        console.error('Error saat menarik data:', error);
        toast.warn(`Gagal menarik data`, {
          position: "bottom-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }, []);

  const handleYearChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedYear = e.target.value;
    setFormData({ ...formData, tahun_ajaran: selectedYear });

    if (selectedYear) {
      try {
        const params = {
          sekolah_id: uuid,
          tahun_ajaran: selectedYear,
        }
        // Kirim permintaan ke backend untuk memeriksa data sesuai tahun ajaran
        const API = process.env.REACT_APP_API_URL_NEW;
        const TOKEN = process.env.REACT_APP_TOKEN_NEW;
        const response = await axios.post(`${API}/dana-taawun/sekolah-detail`,params, {
          headers: {
            key: TOKEN,
            Authorization: `Bearer ${localStorage.access_token}`,
          },
        });
        if (response.data.status) {
          if (response.data.keterangan === 'sekolah'){
            setFormData({
              ...formData,
              tahun_ajaran: selectedYear,
              npsnData: response.data.data.npsn,
              nama: response.data.data.nama,
              alamat: response.data.data.alamat,
              jumlah_siswa: response.data.data.total.siswa,
              jumlah_siswa_baru: '',
              total_guru: response.data.data.total.total_guru,
              total_guru_pns: response.data.data.total.total_guru_pns,
              jumlah_karyawan: '',
              nama_kepsek: '',
              no_kepsek: '',
              tahun_kepimpinan: '',
              bendahara_sekolah: '',
              no_bendahara: '',
            });
          }else{
            setFormData({
              ...formData,
              tahun_ajaran: selectedYear,
              npsnData: response.data.data.npsn,
              nama: response.data.data.nama,
              alamat: response.data.data.alamat_sekolah,
              jumlah_siswa: response.data.data.detail.jumlah_siswa,
              jumlah_siswa_baru: response.data.data.detail.jumlah_siswa_baru,
              total_guru: response.data.data.detail.guru_non_pns,
              total_guru_pns: response.data.data.detail.guru_pns,
              jumlah_karyawan: response.data.data.detail.jumlah_karyawan,
              nama_kepsek: response.data.data.detail.nama_kepsek,
              no_kepsek: response.data.data.detail.no_kepsek,
              tahun_kepimpinan: response.data.data.detail.tahun_pimpinan,
              bendahara_sekolah: response.data.data.detail.bendahara_sekolah,
              no_bendahara: response.data.data.detail.no_bendahara,
            });
          }
          toast.success('Berhasil mengambil data', {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // console.log(response.data.data.detail.tahun_ajar);
        } else {
          // Data tidak ditemukan
          toast.warn(`Tahun ajaran belum dipilih!`, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (error) {
        // Tangani kesalahan di sini
        toast.error('Server sedang sibuk', {
          position: "bottom-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.error('Terjadi kesalahan dalam permintaan', error);
      }
    }
  };

  const handleCheckboxChangeSpp = (e: ChangeEvent<HTMLInputElement>, checkboxName: string) => {
    if (checkboxName === "nonSpp") {
      setFormData({
        ...formData,
        nonSpp: e.target.checked,
        sppSame: false,
        sppDifferent: false,
        spp: [
          {
            total_student: "",
            nominal: ""
          }
        ]
      });
    } else if (checkboxName === "sppSame") {
      const updatedFields = formData.spp.map((field) => ({
        total_student: "",
        nominal: ""
      }));
      setFormData({
        ...formData,
        nonSpp: false,
        sppSame: e.target.checked,
        sppDifferent: false,
        spp: [
          {
            total_student: "",
            nominal: ""
          }
        ]
      });
    } else if (checkboxName === "sppDifferent") {
      const updatedFields = formData.spp.map((field) => ({
        total_student: "",
        nominal: ""
      }));
      setFormData({
        ...formData,
        nonSpp: false,
        sppSame: false,
        sppDifferent: e.target.checked,
        spp: updatedFields
      });
    } else {
      setFormData({
        ...formData,
        [checkboxName]: e.target.checked
      });
    }
  };

  const handleCheckboxChangeDpp = (e: ChangeEvent<HTMLInputElement>, checkboxName: string) => {
    if (checkboxName === "dppSame") {
      const updatedFields = formData.dpp.map((field) => ({
        total_student: "",
        nominal: ""
      }));
      setFormData({
        ...formData,
        dppSame: e.target.checked,
        dppDifferent: false,
        dpp: [
          {
            total_student: "",
            nominal: ""
          }
        ]
      });
    } else if (checkboxName === "dppDifferent") {
      const updatedFields = formData.dpp.map((field) => ({
        total_student: "",
        nominal: ""
      }));
      setFormData({
        ...formData,
        dppSame: false,
        dppDifferent: e.target.checked,
        dpp: updatedFields
      });
    } else {
      setFormData({
        ...formData,
        [checkboxName]: e.target.checked
      });
    }
  };

  const handleAddFieldSpp = () => {
    setFormData({
      ...formData,
      spp: [...formData.spp, { total_student: "", nominal: "" }]
    });
  };

  const handleRemoveFieldSpp = (index: number) => {
    const updatedFields = [...formData.spp];
    updatedFields.splice(index, 1);
    setFormData({
      ...formData,
      spp: updatedFields
    });
  };

  const handleAddFieldDpp = () => {
    setFormData({
      ...formData,
      dpp: [...formData.dpp, { total_student: "", nominal: "" }]
    });
  };

  const handleRemoveFieldDpp = (index: number) => {
    const updatedFields = [...formData.dpp];
    updatedFields.splice(index, 1);
    setFormData({
      ...formData,
      dpp: updatedFields
    });
  };

  // Fungsi untuk mengambil data dari backend
  const fetchDataFromBackend = () => {

    const API = process.env.REACT_APP_API_URL_NEW
    const TOKEN = process.env.REACT_APP_TOKEN_NEW

    const params = {
      sekolah_id: uuid,
      tahun_ajaran: formData.tahun_ajaran,
    }

    axios
      .post(`${API}/dana-taawun/calculate`, params, {
        headers: {
          'Content-Type': 'application/json',
          key: TOKEN,
          Authorization: `Bearer ${localStorage.access_token}`,
        },
      })
      .then((response) => {
        console.log('Data berhasil diambils:', response.data.uis_uig_uik.disdasmen_cabang);
        setFormDatax({
          ...formDatax,
          disdasmen_cabang: response.data.dpp.disdasmen_cabang,
          disdasmen_daerah: response.data.dpp.disdasmen_daerah,
          disdasmen_pusat: response.data.dpp.disdasmen_pusat,
          disdasmen_wilayah: response.data.dpp.disdasmen_wilayah,
          pimpinan_cabang: response.data.dpp.pimpinan_cabang,
          pimpinan_daerah: response.data.dpp.pimpinan_daerah,
          pimpinan_pusat: response.data.dpp.pimpinan_pusat,
          pimpinan_ranting: response.data.dpp.pimpinan_ranting,
          pimpinan_wilayah: response.data.dpp.pimpinan_wilayah,
          kewajiban_dpp: response.data.kewajiban_dpp,
          total_dpp_majelis: response.data.total_dpp_majelis,
          kewajiban_uig: response.data.kewajiban_uig,
          kewajiban_uik: response.data.kewajiban_uik,
          kewajiban_uis: response.data.kewajiban_uis,
          kewajiban_uis_uig_uik: response.data.kewajiban_uis_uig_uik,
          total_dana_taawun: response.data.total_dana_taawun,
          disdasmen_cabangx: response.data.uis_uig_uik.disdasmen_cabang,
          disdasmen_daerahx: response.data.uis_uig_uik.disdasmen_daerah,
          disdasmen_pusatx: response.data.uis_uig_uik.disdasmen_pusat,
          disdasmen_wilayahx: response.data.uis_uig_uik.disdasmen_wilayah,
        });
        toast.success('Berhasil mengambil data', {
          position: "bottom-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        console.error('Error saat mengambil data:', error);
        toast.warn(`Tahun ajaran belum dipilih!`, {
          position: "bottom-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newJumlahSiswa = parseInt(formData.jumlah_siswa) || 0; // Menggunakan parseInt untuk memastikan nilai adalah angka
    const newJumlahSiswaBaru = parseInt(formData.jumlah_siswa_baru) || 0;
    const totalJumlah = newJumlahSiswa + newJumlahSiswaBaru;

    const totalSppStudents = formData.spp.reduce((total, item) => {
      // Gunakan parseInt untuk mengonversi nilai menjadi angka dan hindari masalah jika ada nilai yang tidak valid
      const studentCount = parseInt(item.total_student) || 0;
      return total + studentCount;
    }, 0);
    const totalDppStudents = formData.dpp.reduce((total, item) => {
      // Gunakan parseInt untuk mengonversi nilai menjadi angka dan hindari masalah jika ada nilai yang tidak valid
      const studentCount = parseInt(item.total_student) || 0;
      return total + studentCount;
    }, 0);

    if (totalJumlah !== totalSppStudents) {
      toast.warn(`Total siswa pada Nominal SPP tidak sesuai dengan Data Isian pada Informasi Satuan Pendidikan`, {
        position: "bottom-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      // Menampilkan notifikasi
    } else if(totalJumlah !== totalDppStudents){
      toast.warn(`Total siswa pada Nominal DPP tidak sesuai dengan Data Isian pada Informasi Satuan Pendidikan`, {
        position: "bottom-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else{

      // Gantilah dengan kode untuk mengirim data ke BE sesuai dengan kebutuhan Anda
      let status_spp = "";
      if (formData.sppSame) {
        status_spp = 'same';
      } else if (formData.sppDifferent) {
        status_spp = 'different';
      } else {
        status_spp = "";
      }

      let status_dpp = "";
      if (formData.dppSame) {
        status_dpp = 'same';
      } else if (formData.dppDifferent) {
        status_dpp = 'different';
      } else {
        status_dpp = "";
      }

      const dataToSend = {
        sekolah_id: uuid,
        tahun_ajaran: formData.tahun_ajaran,
        npsn: formData.npsnData,
        nama_sekolah: formData.nama,
        alamat_sekolah: formData.alamat,
        guru_non_pns: formData.total_guru,
        guru_pns: formData.total_guru_pns,
        jumlah_siswa: formData.jumlah_siswa,
        jumlah_siswa_baru: formData.jumlah_siswa_baru,
        jumlah_karyawan: formData.jumlah_karyawan,
        nama_kepsek: formData.nama_kepsek,
        no_kepsek: formData.no_kepsek,
        tahun_kepimpinan: formData.tahun_kepimpinan,
        bendahara_sekolah: formData.bendahara_sekolah,
        no_bendahara: formData.no_bendahara,
        max_take_home_pay: formData.max_take_home_pay,
        min_take_home_pay: formData.min_take_home_pay,
        total_allowence_non_pns: formData.total_allowence_non_pns,
        total_allowence_pns: formData.total_allowence_pns,
        total_allowence_employee: formData.total_allowence_employee,
        is_spp: formData.nonSpp == false ? '1' : '0',
        is_dpp: '1',
        status_spp: status_spp,
        status_dpp: status_dpp,
        spp: formData.spp,
        dpp: formData.dpp,

        selectedMonth: formData.selectedMonth, //ditanyakan ke geral masa Berakhir SK Kepemimpinan
      };

      console.log(dataToSend);
      // Kirim data ke BE menggunakan fetch atau library HTTP lainnya

      const API = process.env.REACT_APP_API_URL_NEW
      const TOKEN = process.env.REACT_APP_TOKEN_NEW

      axios
        .post(`${API}/dana-taawun/create`, dataToSend, {
          headers: {
            'Content-Type': 'application/json',
            key: TOKEN,
            Authorization: `Bearer ${localStorage.access_token}`,
          },
        })
        .then((responseData) => {
          toast.success('Data berhasil disimpan', {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((error) => {
          console.error('Error saat menyimpan data:', error);
          toast.warn(`Gagal menyimpan data`, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  const sendFilesToBackend = (files: File[]) => {
    const formDatax = new FormData();
    // files.forEach((file, index) => {
    // });
    formDatax.append(`file`, files[0]);
    formDatax.append('sekolah_id', uuid ?? '');
    formDatax.append('tahun_ajaran', formData.tahun_ajaran);
    // const params ={'tahun_ajaran': formData.tahun_ajaran}
    console.log(formData.tahun_ajaran);
    // Kirim data ke BE menggunakan fetch atau library HTTP lainnya

    const API = process.env.REACT_APP_API_URL_NEW
    const TOKEN = process.env.REACT_APP_TOKEN_NEW

    axios
      .post(`${API}/dana-taawun/upload-bukti-pembayaran`, formDatax, {
        headers: {
          'Content-Type': 'application/json',
          key: TOKEN,
          Authorization: `Bearer ${localStorage.access_token}`,
        },
      })
      .then((responseData) => {
        toast.success('Data berhasil disimpan', {
          position: "bottom-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        console.error('Error saat menyimpan data:', error);
        toast.warn(`Gagal menyimpan data`, {
          position: "bottom-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const dropzoneText = "Drag n drop unggah seluruh file bukti pembayaran Dana Ta’awun disini \n\n\n" + '\n' +
    "atau klik untuk memilih file"


  return (
    <div className='row g-5 g-xxl-8'>
      <div className='col-xl-12'>
        <div className={`card mb-5 mb-xxl-8`}>
          {/* begin::Body */}
          <div className='card-body pb-0'>
            {/* begin::Header */}
            <div className='d-flex align-items-center mb-5'>
              {/* begin::User */}
              <div className='d-flex align-items-center flex-grow-1'>
                {/* begin::Info */}
                <div className='d-flex flex-column'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bolder'>
                    Rekapitulasi Dana Ta’awun
                  </a>
                  <span className='text-gray-400 fw-bold'>Rekapitulasi Dana Ta’awun berdasarkan jenjang sekolah atau madrasah</span>
                </div>
                {/* end::Info */}
              </div>
              {/* end::User */}
            </div>
            {/* end::Header */}

            {/* begin::Post */}
            <div className='mb-5'>
              {/* begin::Text */}
              <div className='row'>

                <div className='col-xl-2 col-md-2'>
                  <div className='card bg-light-paud'>
                    {/* begin::Body */}
                    <div className='card-body bg-light-paud align-items-center justify-content-center'>
                      <h1 className='card-title text-color-paud text-center'>7436</h1>
                      <p className='text-dark text-center fw-bolder'>TK/PAUD</p>
                    </div>
                    <div className='align-items-center justify-content-center bg-paud'>
                      <p className='text-dark align-items-center text-center'><span className="bi bi-calculator"></span> 253</p>

                    </div>
                    {/* end:: Body */}
                  </div>
                </div>
                <div className='col-xl-2 col-md-2'>
                  <div className='card bg-light-paud-FEECF0'>
                    {/* begin::Body */}
                    <div className='card-body bg-light-paud-FEECF0 align-items-center justify-content-center'>
                      <h1 className='card-title text-color-paud-FEECF0 text-center'>2343</h1>
                      <p className='text-dark text-center fw-bolder'>SD/MI</p>
                    </div>
                    <div className='align-items-center justify-content-center bg-paud-FEECF0'>
                      <p className='text-dark align-items-center text-center'><span className="bi bi-calculator"></span> 1253</p>

                    </div>
                    {/* end:: Body */}
                  </div>
                </div>
                <div className='col-xl-2 col-md-2'>
                  <div className='card bg-light-paud-E7F5FD'>
                    {/* begin::Body */}
                    <div className='card-body bg-light-paud-E7F5FD align-items-center justify-content-center'>
                      <h1 className='card-title text-color-paud-E7F5FD text-center'>1522</h1>
                      <p className='text-dark text-center fw-bolder'>SMP/MTS</p>
                    </div>
                    <div className='align-items-center justify-content-center bg-paud-E7F5FD'>
                      <p className='text-dark text-color-paud-E7F5FD align-items-center text-center'><span className="bi bi-calculator"></span> 823</p>

                    </div>
                    {/* end:: Body */}
                  </div>
                </div>
                <div className='col-xl-2 col-md-2'>
                  <div className='card bg-light-paud-EFEFEF'>
                    {/* begin::Body */}
                    <div className='card-body bg-light-paud-EFEFEF align-items-center justify-content-center'>
                      <h1 className='card-title text-color-paud-EFEFEF text-center'>668</h1>
                      <p className='text-dark text-center fw-bolder'>SMA/MA</p>
                    </div>
                    <div className='align-items-center justify-content-center bg-paud-EFEFEF'>
                      <p className='text-dark align-items-center text-center'><span className="bi bi-calculator"></span> 553</p>

                    </div>
                    {/* end:: Body */}
                  </div>
                </div>
                <div className='col-xl-2 col-md-2'>
                  <div className='card bg-light-paud-FEF3EC'>
                    {/* begin::Body */}
                    <div className='card-body bg-light-paud-FEF3EC align-items-center justify-content-center'>
                      <h1 className='card-title text-color-paud-FEF3EC text-center'>608</h1>
                      <p className='text-dark text-center fw-bolder'>SMK/MAK</p>
                    </div>
                    <div className='align-items-center justify-content-center bg-paud-FEF3EC'>
                      <p className='text-dark align-items-center text-center'><span className="bi bi-calculator"></span> 457</p>

                    </div>
                    {/* end:: Body */}
                  </div>
                </div>
                <div className='col-xl-2 col-md-2'>
                  <div className='card bg-light-paud-E9B600'>
                    {/* begin::Body */}
                    <div className='card-body bg-light-paud-E9B600 align-items-center justify-content-center'>
                      <h1 className='card-title text-color-paud-E9B600 text-center'>37</h1>
                      <p className='text-dark text-center fw-bolder'>SDLB/SMPLB/SLB</p>
                    </div>
                    <div className='align-items-center justify-content-center bg-paud-E9B600'>
                      <p className='text-dark align-items-center text-center'><span className="bi bi-calculator"></span> 12</p>

                    </div>
                    {/* end:: Body */}
                  </div>
                </div>
              </div>
              {/* end::Text */}
            </div>
            {/* end::Post */}

          </div>
          {/* end::Body */}
        </div>
      </div>

      {!selectedSekolahId ? (
        // Jika tidak ada sekolah_id terpilih, tampilkan tabel

        <div className='col-xl-12'>
          <div className={`card mb-5 mb-xxl-8`}>
            {/* begin::Body */}
            <div className='card-body pb-0'>
              {/* begin::Header */}
              <div className='d-flex align-items-center mb-5'>
                {/* begin::User */}
                <div className='d-flex align-items-center flex-grow-1'>
                  {/* begin::Info */}
                  <div className='d-flex flex-column'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bolder'>
                      Data Dana Ta’awun
                    </a>
                    <span className='text-gray-400 fw-bold'>Detail data Dana Ta’awun</span>
                  </div>
                  {/* end::Info */}
                </div>
                {/* end::User */}
              </div>
              {/* end::Header */}

              {/* begin::Post */}
              <div className='mb-5'>
                {/* begin::Text */}
                <div className='row'>

                  <div className="col-xl-3 col-md-3 col-sm-3 mb-3">
                    <label className='font-size-16 mb-2'>Provinsi</label>

                    <select
                      className='form-select form-select-lg form-select-solid'
                      data-control='select2'
                      // id="tahun_ajaran"
                      // value={formData.tahun_ajaran}
                      // onChange={(e) =>
                      //   setFormData({ ...formData, tahun_ajaran: e.target.value })
                      // }
                      // onChange={handleYearChange}
                    >
                      <option value=""></option>
                      <option value="1">Jawa Timur</option>
                      <option value="2">Jawa Tengah</option>
                      <option value="3">Jawa Barat</option>
                      {/* Tambahkan pilihan tahun ajaran lainnya sesuai kebutuhan */}
                    </select>

                  </div>

                  <div className="col-xl-3 col-md-3 col-sm-3 mb-3">
                    <label className='font-size-16 mb-2'>Kabupaten/Kota</label>

                    <select
                      className='form-select form-select-lg form-select-solid'
                      data-control='select2'
                      // id="tahun_ajaran"
                      // value={formData.tahun_ajaran}
                      // onChange={(e) =>
                      //   setFormData({ ...formData, tahun_ajaran: e.target.value })
                      // }
                      // onChange={handleYearChange}
                    >
                      <option value=""></option>
                      <option value="1">Pacitan</option>
                      <option value="2">Gunung Kidul</option>
                      <option value="3">Wonosari</option>
                      {/* Tambahkan pilihan tahun ajaran lainnya sesuai kebutuhan */}
                    </select>

                  </div>

                  <div className="col-xl-3 col-md-3 col-sm-3 mb-3">
                    <label className='font-size-16 mb-2'>Kecamatan</label>

                    <select
                      className='form-select form-select-lg form-select-solid'
                      data-control='select2'
                      // id="tahun_ajaran"
                      // value={formData.tahun_ajaran}
                      // onChange={(e) =>
                      //   setFormData({ ...formData, tahun_ajaran: e.target.value })
                      // }
                      // onChange={handleYearChange}
                    >
                      <option value=""></option>
                      <option value="1">Arjosari</option>
                      <option value="2">Sawahan</option>
                      <option value="3">Ponjong</option>
                      {/* Tambahkan pilihan tahun ajaran lainnya sesuai kebutuhan */}
                    </select>

                  </div>

                  <div className="col-xl-3 col-md-3 col-sm-3 mb-3">
                    <label className='font-size-16 mb-2'>Tahun Ajar</label>

                    <select
                      className='form-select form-select-lg form-select-solid'
                      data-control='select2'
                      // id="tahun_ajaran"
                      // value={formData.tahun_ajaran}
                      // onChange={(e) =>
                      //   setFormData({ ...formData, tahun_ajaran: e.target.value })
                      // }
                      // onChange={handleYearChange}
                    >
                      <option value=""></option>
                      <option value="1">2023</option>
                      <option value="2">2024</option>
                      <option value="3">2025</option>
                      {/* Tambahkan pilihan tahun ajaran lainnya sesuai kebutuhan */}
                    </select>

                  </div>

                  <div className="col-xl-3 col-md-3 col-sm-3 mb-3">
                    <label className='font-size-16 mb-2'>Jenjang</label>

                    <select
                      className='form-select form-select-lg form-select-solid'
                      data-control='select2'
                      // id="tahun_ajaran"
                      // value={formData.tahun_ajaran}
                      // onChange={(e) =>
                      //   setFormData({ ...formData, tahun_ajaran: e.target.value })
                      // }
                      // onChange={handleYearChange}
                    >
                      <option value=""></option>
                      <option value="1">D3</option>
                      <option value="2">S1</option>
                      <option value="3">S2</option>
                      {/* Tambahkan pilihan tahun ajaran lainnya sesuai kebutuhan */}
                    </select>

                  </div>

                  <div className="col-xl-3 col-md-3 col-sm-3 mb-3">
                    <label className='font-size-16 mb-2'>Status</label>

                    <select
                      className='form-select form-select-lg form-select-solid'
                      data-control='select2'
                      // id="tahun_ajaran"
                      // value={formData.tahun_ajaran}
                      // onChange={(e) =>
                      //   setFormData({ ...formData, tahun_ajaran: e.target.value })
                      // }
                      // onChange={handleYearChange}
                    >
                      <option value=""></option>
                      <option value="1">Aktif</option>
                      <option value="2">Tidak Aktif</option>
                      {/* Tambahkan pilihan tahun ajaran lainnya sesuai kebutuhan */}
                    </select>

                  </div>

                  <div className="col-xl-3 col-md-3 col-sm-3 mb-3">
                    <label className='font-size-16 mb-2'>Rentang Jumlah Siswa</label>
                    <div className='input-group input-group-solid flex-nowrap'>
                                    <span className="input-group-text">
                                        <KTSVG
                                          path='/media/icons/Icon-UsersThree.svg'
                                          className='svg-icon-1'
                                        />
                                    </span>
                      <div className='flex-grow-1'>
                        <select
                          className='form-select form-select-lg form-select-solid'
                          data-control='select2'
                          // id="tahun_ajaran"
                          // value={formData.tahun_ajaran}
                          // onChange={(e) =>
                          //   setFormData({ ...formData, tahun_ajaran: e.target.value })
                          // }
                          // onChange={handleYearChange}
                        >
                          <option value=""></option>
                          <option value="1">D3</option>
                          <option value="2">S1</option>
                          <option value="3">S2</option>
                          {/* Tambahkan pilihan tahun ajaran lainnya sesuai kebutuhan */}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-md-3 col-sm-3 mb-3">
                    <label className='font-size-16 mb-2'>Cari</label>

                    <div className='input-group input-group-solid flex-nowrap'>
                                    <span className="input-group-text">
                                        <KTSVG
                                          path='/media/icons/Icon-MagnifyingGlass.svg'
                                          className='svg-icon-1'
                                        />
                                    </span>
                      <div className='flex-grow-1'>
                        <input
                          id="npsn"
                          // value={formData.npsnData}
                          // onChange={(e) =>
                          //     setFormData({ ...formData, npsnData: e.target.value })
                          // }
                          className="form-control form-control-solid mb-2"
                          type="text"
                        />
                      </div>
                    </div>

                  </div>
                </div>
                {/* end::Text */}
              </div>

              <div className="table-responsive mb-5">
                <BootstrapTable
                  keyField='id'
                  data={products}
                  columns={columns}
                  pagination={paginationFactory(options)}
                />
              </div>
              <div className="separator border-dark my-10"></div>
              {/* end::Post */}

            </div>
            {/* end::Body */}
          </div>
        </div>
      ) : (
        // Jika sekolah_id terpilih, tampilkan detail sekolah
        <>
          <div className='card mb-5 mb-xl-10 mt-9'>
            <div className='card-body pt-10 pb-5'>
              <div className='justify-content-center mb-9'>
                <button onClick={backToTable} className="btn button-right">
                  <KTSVG
                    path='/media/icons/Icon-XCircle.svg'
                    className='svg-icon-1'
                  />
                </button>

                {/*<a className="btn btn-primary button-right"*/}
                {/*   onClick={handlePrint}*/}
                {/*><i className="fa fa-print"></i>Cetak Rincian</a>*/}

                <h2 className='font-size-22-bold'>Sistem Informasi Dana Ta’awun Sekolah/Madrasah</h2>
                <p className='font-size-20'>Isi data sesuai dengan informasi sekolah/madrasah</p>
              </div>
            </div>
          </div>

          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-10 pb-5'>
              <div className='justify-content-center mb-9'>

                <form onSubmit={handleSubmit}>
                  <div className='row'>
                    <div className="col-xl-4 col-md-4 col-sm-4 mt-7">
                      <h2 className="font-size-20-bold"> Informasi Satuan Pendidikan</h2>
                      <label className="font-size-16"> Informasi dasar mengenai sekolah/madrasah </label>
                    </div>
                    <div className="col-xl-8 col-md-8 col-sm-8">
                      <div className="row">
                        <div className="col-xl-6 col-md-6 col-sm-6 mt-7">
                          <label className='font-size-16 mb-2'>NPSN</label>
                          <input
                            id="npsn"
                            value={formData.npsnData}
                            onChange={(e) =>
                              setFormData({ ...formData, npsnData: e.target.value })
                            }
                            className="form-control form-control-solid mb-2"
                            type="text"
                          />
                        </div>
                        <div className="col-xl-6 col-md-6 col-sm-6 mt-7">
                          <label className='font-size-16 mb-2'>Tahun Ajaran</label>

                          <select
                            className="form-control form-control-solid mb-2"
                            id="tahun_ajaran"
                            value={formData.tahun_ajaran}
                            // onChange={(e) =>
                            //   setFormData({ ...formData, tahun_ajaran: e.target.value })
                            // }
                            onChange={handleYearChange}
                          >
                            <option value="">Pilih Tahun Ajaran</option>
                            <option value="2022/2023">2022/2023</option>
                            <option value="2023/2024">2023/2024</option>
                            {/* Tambahkan pilihan tahun ajaran lainnya sesuai kebutuhan */}
                          </select>

                        </div>
                        <div className="col-xl-12 col-md-12 col-sm-12 mt-7">
                          <label className='font-size-16 mb-2'>Nama Sekolah/Madrasah</label>
                          <input
                            id="nama"
                            value={formData.nama}
                            onChange={(e) =>
                              setFormData({ ...formData, nama: e.target.value })
                            }
                            className="form-control form-control-solid mb-2"
                            type="text"
                          />
                        </div>
                        <div className="col-xl-12 col-md-12 col-sm-12 mt-7">
                          <label className='font-size-16 mb-2'>Alamat Sekolah/Madrasah</label>
                          <input
                            id="alamat"
                            value={formData.alamat}
                            onChange={(e) =>
                              setFormData({ ...formData, alamat: e.target.value })
                            }
                            className="form-control form-control-solid mb-2"
                            type="text"
                          />
                        </div>
                        <div className="col-xl-6 col-md-6 col-sm-6 mt-7">
                          <label className='font-size-16 mb-2'>Jumlah Siswa</label>
                          <input
                            id="jumlah_siswa"
                            value={formData.jumlah_siswa}
                            onChange={(e) =>
                              setFormData({ ...formData, jumlah_siswa: e.target.value })
                            }
                            className="form-control form-control-solid mb-2"
                            type="text"
                          />
                        </div>
                        <div className="col-xl-6 col-md-6 col-sm-6 mt-7">
                          <label className='font-size-16 mb-2'>Jumlah Siswa Tahun Ajaran Baru</label>
                          <input
                            id="jumlah_siswa_baru"
                            value={formData.jumlah_siswa_baru}
                            onChange={(e) =>
                              setFormData({ ...formData, jumlah_siswa_baru: e.target.value })
                            }
                            className="form-control form-control-solid mb-2"
                            type="text"
                          />
                        </div>
                        <div className="col-xl-4 col-md-4 col-sm-4 mt-7">
                          <label className='font-size-16 mb-2'>Jumlah Guru Non PNS</label>
                          <input
                            id="total_guru"
                            value={formData.total_guru}
                            onChange={(e) =>
                              setFormData({ ...formData, total_guru: e.target.value })
                            }
                            className="form-control form-control-solid mb-2"
                            type="text"
                          />
                        </div>
                        <div className="col-xl-4 col-md-4 col-sm-4 mt-7">
                          <label className='font-size-16 mb-2'>Jumlah Guru PNS</label>
                          <input
                            id="total_guru_pns"
                            value={formData.total_guru_pns}
                            onChange={(e) =>
                              setFormData({ ...formData, total_guru_pns: e.target.value })
                            }
                            className="form-control form-control-solid mb-2"
                            type="text"
                          />
                        </div>
                        <div className="col-xl-4 col-md-4 col-sm-4 mt-7">
                          <label className='font-size-16 mb-2'>Jumlah Karyawan</label>
                          <input
                            id="jumlah_karyawan"
                            value={formData.jumlah_karyawan}
                            onChange={(e) =>
                              setFormData({ ...formData, jumlah_karyawan: e.target.value })
                            }
                            className="form-control form-control-solid mb-2"
                            type="text" />
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-4 col-md-4 col-sm-4 mt-9">
                      <h2 className="font-size-20-bold mt-2">Data Identitas</h2>
                      <label className='font-size-16'>Data identitas pimpinan dan bendahara</label>
                      <label className='font-size-16'>sekolah/madrasah</label>
                    </div>
                    <div className="col-xl-8 col-md-8 col-sm-8 mt-9 form-identitas">
                      <div className="row">
                        <div className="col-xl-8 col-md-8 col-sm-8 ">
                          <label className='font-size-16 mb-2'>Nama Kepala Sekolah/Madrasah</label>
                          <input
                            id="nama_kepsek"
                            value={formData.nama_kepsek}
                            onChange={(e) =>
                              setFormData({ ...formData, nama_kepsek: e.target.value })
                            }
                            className="form-control form-control-solid mb-2"
                            type="text"
                          />
                        </div>
                        <div className="col-xl-4 col-md-4 col-sm-4">
                          <label className='font-size-16 mb-2'>No. WhatsApp</label>
                          <input
                            id="no_kepsek"
                            value={formData.no_kepsek}
                            onChange={(e) =>
                              setFormData({ ...formData, no_kepsek: e.target.value })
                            }
                            className="form-control form-control-solid mb-2"
                            type="text"
                          />
                        </div>
                        <div className="col-xl-6 col-md-6 col-sm-6 mt-7">
                          <label className='font-size-16 mb-2'>Periode Kepemimpinan Ke</label>

                          <select
                            className="form-control form-control-solid mb-2"
                            id="tahun_kepimpinan"
                            value={formData.tahun_kepimpinan}
                            onChange={(e) =>
                              setFormData({ ...formData, tahun_kepimpinan: e.target.value })
                            }
                          >
                            <option value="">Pilih Periode Kepemimpinan</option>
                            <option value="Periode Kesatu">Periode Kesatu</option>
                            <option value="Periode Kedua">Periode Kedua</option>
                            <option value="Periode Ketiga">Periode Ketiga</option>
                            {/* Tambahkan pilihan tahun ajaran lainnya sesuai kebutuhan */}
                          </select>
                        </div>
                        <div className="col-xl-6 col-md-6 col-sm-6 mt-7">
                          <label className='font-size-16 mb-2'>Masa Berakhir SK Kepemimpinan</label>
                          <input
                            id="tanggal"
                            className="form-control form-control-solid mb-2"
                            type="month"
                            value={formData.selectedMonth}
                            onChange={(e) =>
                              setFormData({ ...formData, selectedMonth: e.target.value })
                            }
                          />
                        </div>
                        <div className="col-xl-8 col-md-8 col-sm-8 mt-7">
                          <label className='font-size-16 mb-2'>Nama Bendahara Sekolah/Madrasah</label>
                          <input
                            value={formData.bendahara_sekolah}
                            onChange={(e) =>
                              setFormData({ ...formData, bendahara_sekolah: e.target.value })
                            }
                            className="form-control form-control-solid mb-2"
                            type="text"
                          />
                        </div>
                        <div className="col-xl-4 col-md-4 col-sm-4 mt-7">
                          <label className='font-size-16 mb-2'>No. WhatsApp</label>
                          <input
                            value={formData.no_bendahara}
                            onChange={(e) =>
                              setFormData({ ...formData, no_bendahara: e.target.value })
                            }
                            className="form-control form-control-solid mb-2"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-12 col-md-12 col-sm-12 bg-form-spesial">
                      <div className="d-flex flex-column align-items-center justify-content-center mt-3">
                        <h2 className="font-white-20-bold mt-3">DATA INFORMASI KEUANGAN</h2>
                        <label className="font-white-20 mb-5">Tahun Ajaran {formData.tahun_ajaran}</label>
                      </div>

                      {/* form input dinamis spp */}
                      <div className="row bg-light-form">
                        <div className="col-xl-4 col-md-4 col-sm-4">
                          <h2 className="font-size-20-bold">Data Informasi Keuangan</h2>
                          <label className='font-size-16'>Data detail informasi keuangan</label>
                          <label className='font-size-16'>sekolah/madrasah</label>
                        </div>
                        <div className="col-xl-8 col-md-8 col-sm-8">
                          <div className="row">
                            <div className="col-xl-12 col-md-12 col-sm-12 bg-light-form1 posisi-rights">
                              <div className="row">

                                <div className='col-xl-12 col-md-12 col-sm-12 posisi-lefts'>
                                  <div className='row'>

                                    <div className='col-xl-12 col-md-12 col-sm-12'>
                                      <div className='row'>
                                        <div className="col-xl-9 col-md-9 col-sm-9 mt-4">
                                          <label className="font-size-16-bold">Nominal SPP</label>
                                        </div>
                                        <div className="col-xl-2 col-md-2 col-sm-2 d-flex align-items-center justify-content-center bg-colors mt-4">
                                          <input
                                            className="form-check-inputs"
                                            type="checkbox"
                                            checked={formData.nonSpp}
                                            onChange={(e) => handleCheckboxChangeSpp(e, "nonSpp")} />
                                          <label className='font-size-16-bold'>NON SPP</label>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-xl-5 col-md-5 col-sm-5 mt-5">
                                      <input
                                        className="form-check-inputs"
                                        type="checkbox"
                                        checked={formData.sppSame}
                                        onChange={(e) => handleCheckboxChangeSpp(e, "sppSame")} />
                                      <label className='font-size-16'>Nominal SPP Sama Semua</label>
                                    </div>
                                    <div className="col-xl-5 col-md-5 col-sm-5 mt-5">
                                      <input
                                        className="form-check-inputs"
                                        type="checkbox"
                                        checked={formData.sppDifferent}
                                        onChange={(e) => handleCheckboxChangeSpp(e, "sppDifferent")}
                                      />
                                      <label className='font-size-16'>Nominal SPP Berbeda-beda</label>
                                    </div>
                                  </div>
                                </div>

                                {/* form input dinamis spp */}
                                <div className='posisi-lefts'>
                                  {formData.spp.map((field, index) => (
                                    <div className='row' key={index}>
                                      <div className="col-xl-5 col-md-5 col-sm-5 mt-5">
                                        <label className='font-size-16 mb-3'>Jumlah Siswa</label>
                                        <input
                                          className="form-control form-control-solid"
                                          type="text"
                                          value={field.total_student}
                                          onChange={(e) => {
                                            const updatedFields = [...formData.spp];
                                            updatedFields[index].total_student = e.target.value;
                                            setFormData({
                                              ...formData,
                                              spp: updatedFields
                                            });
                                          }}
                                          disabled={formData.nonSpp}
                                        />
                                      </div>
                                      <div className="col-xl-5 col-md-5 col-sm-5 mt-5">
                                        <label className='font-size-16 mb-3'>Nominal SPP</label>
                                        <div className="input-group input-group-md">
                                          <span className="input-group-text btn-data1">Rp</span>
                                          <input
                                            className="form-control"
                                            type="text"
                                            value={field.nominal}
                                            onChange={(e) => handleInputChangeSpp(e, 'spp', index)}
                                            disabled={formData.nonSpp}
                                          />
                                        </div>
                                      </div>
                                      {formData.spp.length > 1 && (
                                        <div className="col-xl-1 col-md-1 col-sm-1 mt-3 d-flex align-items-center justify-content-between">
                                          <button
                                            type="button"
                                            className="btn-x rounded"
                                            onClick={() => handleRemoveFieldSpp(index)}
                                          >
                                            <span className="fa fa-times-circle font-white"></span>
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </div>
                                {formData.sppDifferent && (
                                  <div className="col-xl-6 posisi-lefts mt-3 mb-3">
                                    <button
                                      type="button"
                                      onClick={handleAddFieldSpp}
                                      className="btn-tambah font-white rounded">
                                  <span className="fa fa-plus-circle font-white" color="primary">
                                  </span> Tambah Data
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>

                            {/* form dinamis dpp */}
                            <div className="col-xl-12 col-md-12 col-sm-12 bg-light-form2 posisi-rights">
                              <div className="row">
                                <div className="col-xl-12 col-md-12 col-sm-12 mt-4">
                                  <label className="font-spp">Nominal DPP</label>
                                </div>
                                <div className='col-xl-12 col-md-12 col-sm-12 posisi-lefts'>
                                  <div className='row'>
                                    <div className="col-xl-5 col-md-5 col-sm-5 mt-5">
                                      <input
                                        className="form-check-inputs"
                                        type="checkbox"
                                        checked={formData.dppSame}
                                        onChange={(e) => handleCheckboxChangeDpp(e, "dppSame")}
                                      />
                                      <label className='font-size-16'>Nominal DPP Sama Semua</label>
                                    </div>
                                    <div className="col-xl-5 col-md-5 col-sm-5 mt-5">
                                      <input
                                        className="form-check-inputs"
                                        type="checkbox"
                                        checked={formData.dppDifferent}
                                        onChange={(e) => handleCheckboxChangeDpp(e, "dppDifferent")}
                                      />
                                      <label className='font-size-16'>Nominal DPP Berbeda-beda</label>
                                    </div>
                                  </div>
                                </div>

                                {/* form dinamis dpp */}
                                <div className='posisi-lefts'>
                                  {formData.dpp.map((field, index) => (
                                    <div className='row' key={index}>
                                      <div className="col-xl-5 col-md-5 col-sm-5 mt-5">
                                        <label className='font-size-16 mb-3'>Jumlah Siswa</label>
                                        <input
                                          className="form-control form-control-solid"
                                          type="text"
                                          value={field.total_student}
                                          onChange={(e) => {
                                            const updatedFields = [...formData.dpp];
                                            updatedFields[index].total_student = e.target.value;
                                            setFormData({
                                              ...formData,
                                              dpp: updatedFields
                                            });
                                          }}
                                          // disabled={formData.nonSpp}
                                        />
                                      </div>
                                      <div className="col-xl-5 col-md-5 col-sm-5 mt-5">
                                        <label className='font-size-16 mb-3'>Nominal DPP</label>
                                        <div className="input-group input-group-md">
                                          <span className="input-group-text btn-data1">Rp</span>
                                          <input
                                            className="form-control"
                                            type="text"
                                            value={field.nominal}
                                            // onChange={(e) => {
                                            //   const updatedFields = [...formData.dpp];
                                            //   updatedFields[index].nominal = e.target.value;
                                            //   setFormData({
                                            //     ...formData,
                                            //     dpp: updatedFields
                                            //   });
                                            // }}

                                            onChange={(e) => handleInputChangeSpp(e, 'dpp', index)}
                                            // disabled={formData.nonSpp}
                                          />
                                        </div>
                                      </div>

                                      {formData.dpp.length > 1 && (
                                        <div className="col-xl-1 col-md-1 col-sm-1 mt-3 d-flex align-items-center justify-content-between">
                                          <button
                                            type="button"
                                            className="btn-x rounded"
                                            onClick={() => handleRemoveFieldDpp(index)}
                                          >
                                            <span className="fa fa-times-circle font-white"></span>
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </div>
                                {formData.dppDifferent && (
                                  <div className="col-xl-6 mt-3 mb-3 posisi-lefts">
                                    <button
                                      type="button"
                                      onClick={handleAddFieldDpp}
                                      className="btn-tambah font-white rounded">
                                  <span className="fa fa-plus-circle font-white" color="primary">
                                  </span> Tambah Data
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-xl-12 col-md-12 col-sm-12 posisi-rights">
                              <div className="row">
                                <div className="col-xl-6 col-md-6 col-sm-6 mt-7">
                                  <label className='font-size-16 mb-3'>Gaji Take Home Pay Tertinggi</label>
                                  <div className="input-group input-group-md">
                                    <span className="input-group-text btn-data1">Rp</span>
                                    <input
                                      id="max_take_home_pay"
                                      value={formData.max_take_home_pay}
                                      // onChange={(e) =>
                                      //   setFormData({ ...formData, max_take_home_pay: e.target.value })
                                      // }
                                      onChange={(e) => handleInputChangeSpp(e, "max_take_home_pay")}
                                      className="form-control"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-xl-6 col-md-6 col-sm-6 mt-7">
                                  <label className='font-size-16 mb-3'>Gaji Take Home Pay Terendah</label>
                                  <div className="input-group input-group-md">
                                    <span className="input-group-text btn-data1">Rp</span>
                                    <input
                                      id="min_take_home_pay"
                                      value={formData.min_take_home_pay}
                                      // onChange={(e) =>
                                      //   setFormData({ ...formData, min_take_home_pay: e.target.value })
                                      // }
                                      onChange={(e) => handleInputChangeSpp(e, "min_take_home_pay")}
                                      className="form-control"
                                      type="text" />
                                  </div>
                                </div>
                                <div className="col-xl-12 col-md-12 col-sm-12 mt-7">
                                  <label className='font-size-16 mb-3'>Total Gaji Pokok Seluruh Guru Non PNS</label>
                                  <div className="input-group input-group-md">
                                    <span className="input-group-text btn-data1">Rp</span>
                                    <input
                                      id="total_allowence_non_pns"
                                      value={formData.total_allowence_non_pns}
                                      // onChange={(e) =>
                                      //   setFormData({ ...formData, total_allowence_non_pns: e.target.value })
                                      // }
                                      onChange={(e) => handleInputChangeSpp(e, "total_allowence_non_pns")}
                                      className="form-control"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-xl-12 col-md-12 col-sm-12 mt-7">
                                  <label className='font-size-16 mb-3'>Total Gaji Pokok Guru PNS</label>
                                  <div className="input-group input-group-md">
                                    <span className="input-group-text btn-data1">Rp</span>
                                    <input
                                      id="total_allowence_pns"
                                      value={formData.total_allowence_pns}
                                      // onChange={(e) =>
                                      //   setFormData({ ...formData, total_allowence_pns: e.target.value })
                                      // }
                                      onChange={(e) => handleInputChangeSpp(e, "total_allowence_pns")}
                                      className="form-control"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-xl-12 col-md-12 col-sm-12 mt-7">
                                  <label className='font-size-16 mb-3'>Total Gaji Pokok Seluruh Karyawan</label>
                                  <div className="input-group input-group-md">
                                    <span className="input-group-text btn-data1">Rp</span>
                                    <input
                                      id="total_allowence_employee"
                                      value={formData.total_allowence_employee}
                                      // onChange={(e) =>
                                      //   setFormData({ ...formData, total_allowence_employee: e.target.value })
                                      // }
                                      onChange={(e) => handleInputChangeSpp(e, "total_allowence_employee")}
                                      className="form-control"
                                      type="text"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* begin::Action */}
                        <div className='col-xl-12 col-md-12 col-sm-12 d-flex flex-column align-items-center justify-content-center mt-10'>
                          <div className='col-xl-2 text-center'>
                            <button
                              type='submit'
                              id='kt_sign_in_submit'
                              className='btn btn-lg btn-primary w-100 mb-5'
                            >Simpan</button>
                          </div>
                        </div>
                        {/* end::Action */}
                      </div>
                    </div>
                    {/* sampai sini */}
                  </div>
                </form>

                <div className='row'>
                  <div className="col-xl-12 col-md-12 col-sm-12 bg-form-spesial2">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <div className="col-xl-11 col-md-11 col-sm-11 bg-light-form4">
                        <div className="row">

                          <div className='col-xl-3 col-md-3 col-sm-3 bg-form-biaya1'>
                            <a className="btn btn-hitung d-none d-sm-block" onClick={fetchDataFromBackend}><strong>Klik Untuk Melanjutkan</strong>
                            </a>
                          </div>
                          <div className='col-xl-6 col-md-6 col-sm-6'>
                            <h2 className="font-white-20-bold mt-8">TOTAL KEWAJIBAN PEMBAYARAN</h2>
                            <label className="font-white-20 mb-5">Dana Ta'awun (Tahun Ajaran {formData.tahun_ajaran})</label>
                          </div>
                          <div className="col-xl-3 col-md-3 col-sm-3 bg-form-biaya1">
                            <a className="btn btn-hitung d-sm-none" onClick={fetchDataFromBackend}>
                              <strong>Klik Untuk Melanjutkan</strong>
                            </a>
                          </div>
                          <div className="col-xl-12 col-md-12 col-sm-12 d-flex flex-column align-items-center justify-content-center">
                            <div className='col-xl-12 col-md-12 col-sm-12 bg-form-biaya1'>
                              <label className='text-data'>Rp. {formDatax.total_dana_taawun}</label>
                            </div>
                            <div className='row col-xl-8 col-md-8 col-sm-8 justify-content-center bg-forms mb-5 mt-5'>
                              <div className='col-xl-5 col-md-5 col-sm-6'>
                                <label className='font-size-16 mb-2'>Kewajiban UIS, UIG, UIK Pertahun</label>
                                <div className="input-group">
                                  <span className="input-group-text btn-data1">Rp</span>
                                  <input
                                    disabled
                                    id="kewajiban_uis_uig_uik"
                                    value={formDatax.kewajiban_uis_uig_uik}
                                    onChange={(e) =>
                                      setFormDatax({ ...formDatax, kewajiban_uis_uig_uik: e.target.value })
                                    }
                                    className="form-control btn-data1"
                                    type="text" />
                                </div>
                              </div>
                              <div className='col-xl-5 col-md-5 col-sm-6'>
                                <label className='font-size-16 mb-2'>Kewajiban DPP Pertahun</label>
                                <div className="input-group">
                                  <span className="input-group-text btn-data1">Rp</span>
                                  <input
                                    disabled
                                    id="kewajiban_dpp"
                                    value={formDatax.kewajiban_dpp}
                                    onChange={(e) =>
                                      setFormDatax({ ...formDatax, kewajiban_dpp: e.target.value })
                                    }
                                    className="form-control btn-data1"
                                    type="text"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className='row col-xl-8 col-md-8 col-sm-11 bordereds mb-5'>
                              <div className='col-xl-4 col-md-4 col-sm-4 mt-5 mb-5'>
                                <label className='font-size-16 mb-2'>Kewajiban UIS Pertahun</label>
                                <div className="input-group">
                                  <span className="input-group-text btn-data1">Rp</span>
                                  <input
                                    disabled
                                    id="kewajiban_uis"
                                    value={formDatax.kewajiban_uis}
                                    onChange={(e) =>
                                      setFormDatax({ ...formDatax, kewajiban_uis: e.target.value })
                                    }
                                    className="form-control btn-data1"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div className='col-xl-4 col-md-4 col-sm-4 mt-5 mb-5'>
                                <label className='font-size-16 mb-2'>Kewajiban UIG Pertahun</label>
                                <div className="input-group">
                                  <span className="input-group-text btn-data1">Rp</span>
                                  <input
                                    disabled
                                    id="kewajiban_uig"
                                    value={formDatax.kewajiban_uig}
                                    onChange={(e) =>
                                      setFormDatax({ ...formDatax, kewajiban_uig: e.target.value })
                                    }
                                    className="form-control btn-data1"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div className='col-xl-4 col-md-4 col-sm-4 mt-5 mb-5'>
                                <label className='font-size-16 mb-2'>Kewajiban UIK Pertahun</label>
                                <div className="input-group">
                                  <span className="input-group-text btn-data1">Rp</span>
                                  <input
                                    disabled
                                    id="kewajiban_uik"
                                    value={formDatax.kewajiban_uik}
                                    onChange={(e) =>
                                      setFormDatax({ ...formDatax, kewajiban_uik: e.target.value })
                                    }
                                    className="form-control btn-data1"
                                    type="text"
                                  />
                                </div>
                              </div>
                            </div>

                            {/* <div className='col-xl-12 col-md-12 col-sm-12 bg-form-biaya1'>
                          <a className="btn btn-hitung font-size-16" onClick={fetchDataFromBackend}>
                            <i className="fa fa-calculator">
                            </i>Hitung Dana Ta'awun
                          </a>
                        </div> */}

                            <div className="col-xl-11 col-md-11 col-sm-11 bg-form-Ketentuan">
                              <div className='col-xl-12 col-md-12 col-sm-12 posisi-font'>
                                <p className="text-dark-100 font-size-5 font-white-14-bold">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;PERHATIAN!</p>
                                <ol className='font-white-14 posisi-text'>
                                  <li>
                                    Pembayaran Dana Ta'awun wajib mengikuti ketentuan dari Pusat yaitu dana dikirimkan ke rekening masing-masing
                                    Persyarikatan (pusat, wilayah, daerah, cabang dan ranting) dan Majelis Dikdasmen dan PNF (pusat, wilayah, daerah dan cabang).
                                  </li>
                                  <li>
                                    Untuk porsi Majelis Dikdasmen dan PNF PP Muhammadiyah dikirim ke BANK BRI Cab. Cut Mutiah No. Rek.: 0230-01-000532-30-9 an. Majlis Dikdasmen PP Muhammadyah.
                                  </li>
                                  <li>
                                    Untuk mempermudah proses verfikasi pembayaran :
                                    <ul>
                                      <li>
                                        Pada saat menyetorkan DPP, UIS, UIG dan UIK untuk mengganti 4 angka
                                        terakhir dari nominal yang akan dikirim dengan angka 1912, contoh: 50.000.000 menjadi 50.001.912
                                      </li>
                                      <li>
                                        Pada bukti penyetoran ditulis nama lengkap sekolah/madrasah Muhammadiyah, daerah, provinsi dan nomor kontak kepala sekolah/madrasah
                                      </li>
                                      <li>
                                        Bukti penyetoran ke Persyarikatan dan ke Majelis Dikdasmen dan PNF seluruh
                                        jenjang untuk dikirimkan ke nomor WhatsApp: 0811.940.1912 (Telpon dan WhatsApp Majelis Dikdasmen dan PNF PPM)
                                      </li>
                                    </ul>
                                  </li>
                                </ol>
                                {/* </label> */}
                              </div>
                              <div className='col-xl-12 col-md-12 col-sm-12'>
                                <label className='text-dark-100 font-size-5 colors-font'><strong>UNTUK TIDAK MENITIPKAN/MENGIRIMKAN KESELURUHAN DANA TA'AWUN HANYA KE SATU TITIK SAJA</strong><br/>
                                  <strong>UNTUK LANGSUNG DIKIRIM KE REKENING MASING-MASING
                                  </strong></label>
                              </div>
                            </div>

                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-12 col-md-12 col-sm-12 bg-form-spesial3">
                    <div className="d-flex flex-column align-items-center justify-content-center mt-3">
                      <h2 className="font-white-20-bold mt-3">PENGALOKASIAN DANA TA'AWUN - UIS UIG UIK</h2>
                      <label className="font-white-20 mb-5">Tahun Ajaran {formData.tahun_ajaran}</label>
                    </div>

                    <div className="row bg-light-form6">
                      <div className="col-xl-4 col-md-4 col-sm-4 bg-form-uis mt-7">
                        <div className='posisi-lefts'>
                          <h3 className="font-size-20-bold">ALOKASI DANA UIS, UIG, UIK Majelis DIKDASMEN dan PNF </h3>
                          <div className='col-xl-8 col-md-8 col-sm-8'>
                            <label className='font-size-16 mb-2'>Total Dana <strong>UIS, UIG, UIK</strong></label>
                            <div className="input-group">
                              <span className="input-group-text form-con">Rp</span>
                              <input
                                disabled
                                id="kewajiban_uis_uig_uik"
                                value={formDatax.kewajiban_uis_uig_uik}
                                onChange={(e) =>
                                  setFormDatax({ ...formDatax, kewajiban_uis_uig_uik: e.target.value })
                                }
                                className="form-control form-con"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-8 col-md-8 col-sm-8 bg-form-uis">
                        <div className="row">
                          <div className="col-xl-6 col-md-6 col-sm-6 mt-7">
                            <label className='font-size-16 mb-2'>Majelis Dikdasmen dan PNF PCM (40%)</label>
                            <div className="input-group">
                              <span className="input-group-text form-cont">Rp</span>
                              <input
                                disabled
                                id="disdasmen_cabangx"
                                value={formDatax.disdasmen_cabangx}
                                onChange={(e) =>
                                  setFormDatax({ ...formDatax, disdasmen_cabangx: e.target.value })
                                }
                                className="form-control form-cont"
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 col-md-6 col-sm-6 mt-7">
                            <label className='font-size-16 mb-2'>Majelis Dikdasmen dan PNF PDM (25%)</label>
                            <div className="input-group">
                              <span className="input-group-text form-cont">Rp</span>
                              <input
                                disabled
                                id="disdasmen_daerahx"
                                value={formDatax.disdasmen_daerahx}
                                onChange={(e) =>
                                  setFormDatax({ ...formDatax, disdasmen_daerahx: e.target.value })
                                }
                                className="form-control form-cont" type="text"
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 col-md-6 col-sm-6 mt-7">
                            <label className='font-size-16 mb-2'>Majelis Dikdasmen dan PNF PWM (20%)</label>
                            <div className="input-group">
                              <span className="input-group-text form-cont">Rp</span>
                              <input
                                disabled
                                id="disdasmen_wilayahx"
                                value={formDatax.disdasmen_wilayahx}
                                onChange={(e) =>
                                  setFormDatax({ ...formDatax, disdasmen_wilayahx: e.target.value })
                                }
                                className="form-control form-cont" type="text"
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 col-md-6 col-sm-6 mt-7">
                            <label className='font-size-16 mb-2'>Majelis Dikdasmen dan PNF PPM (15%)</label>
                            <div className="input-group">
                              <span className="input-group-text form-cont">Rp</span>
                              <input
                                disabled
                                id="disdasmen_pusatx"
                                value={formDatax.disdasmen_pusatx}
                                onChange={(e) =>
                                  setFormDatax({ ...formDatax, disdasmen_pusatx: e.target.value })
                                }
                                className="form-control form-cont" type="text"
                              />
                            </div>
                          </div>
                          <div className="col-xl-12 col-md-12 col-sm-12 mt-7 mb-5">
                            <label className='font-size-16' style={{ textTransform:'uppercase'}}><strong>Dana UIS, UIG, UIK diserahkan secara langsung melalui tunai/transfer bank ke masing-masing tingkatan Majelis Dikdasmen dan PNF</strong></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-12 col-md-12 col-sm-12 bg-form-dpp">
                    <div className="d-flex flex-column align-items-center justify-content-center mt-3">
                      <h2 className="font-white-20-bold mt-3">PENGALOKASIAN DANA TA'AWUN - DPP</h2>
                      <label className="font-white-20 mb-5">Tahun Ajaran {formData.tahun_ajaran}</label>
                    </div>

                    <div className="row bg-light-dpp">
                      <div className="col-xl-4 col-md-4 col-sm-4 bg-form-didasmen mt-7">
                        <div className='posisi-lefts'>
                          <h3 className="font-size-20-bold">ALOKASI DANA DPP<br></br> UNTUK MAJELIS DIKDASMEN DAN PNF</h3>
                          <label className='font-size-16'>Sebesar 50% dari total 30% DPP untuk pengembangan Sekolah/Madrasah dan Pesantren</label>
                          <div className='col-xl-8 col-md-8 col-sm-8 mt-7'>
                            <label className='font-size-16 mb-2'><strong>TOTAL DANA DPP TAHUNAN MAJELIS</strong></label>
                            <div className="input-group">
                              <span className="input-group-text form-cons">Rp</span>
                              <input
                                disabled
                                id="total_dpp_majelis"
                                value={formDatax.total_dpp_majelis}
                                onChange={(e) =>
                                  setFormDatax({ ...formDatax, total_dpp_majelis: e.target.value })
                                }
                                className="form-control form-cons"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-8 col-md-8 col-sm-8 mt-3 posisi-rights">
                        <div className="row">
                          <div className="col-xl-6 col-md-6 col-sm-6 mt-3">
                            <label className='font-size-16 mb-2'>Majelis Dikdasmen dan PNF PCM (40%)</label>
                            <div className="input-group">
                              <span className="input-group-text form-consx">Rp</span>
                              <input
                                disabled
                                id="disdasmen_cabang"
                                value={formDatax.disdasmen_cabang}
                                onChange={(e) =>
                                  setFormDatax({ ...formDatax, disdasmen_cabang: e.target.value })
                                }
                                className="form-control form-consx" type="text"
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 col-md-6 col-sm-6 mt-3">
                            <label className='font-size-16 mb-2'>Majelis Dikdasmen dan PNF PDM (25%)</label>
                            <div className="input-group">
                              <span className="input-group-text form-consx">Rp</span>
                              <input
                                disabled
                                id="disdasmen_daerah"
                                value={formDatax.disdasmen_daerah}
                                onChange={(e) =>
                                  setFormDatax({ ...formDatax, disdasmen_daerah: e.target.value })
                                }
                                className="form-control form-consx" type="text"
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 col-md-6 col-sm-6 mt-7">
                            <label className='font-size-16 mb-2'>Majelis Dikdasmen dan PNF PWM (20%)</label>
                            <div className="input-group">
                              <span className="input-group-text form-consx">Rp</span>
                              <input
                                disabled
                                id="disdasmen_wilayah"
                                value={formDatax.disdasmen_wilayah}
                                onChange={(e) =>
                                  setFormDatax({ ...formDatax, disdasmen_wilayah: e.target.value })
                                }
                                className="form-control form-consx" type="text"
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 col-md-6 col-sm-6 mt-7">
                            <label className='font-size-16 mb-2'>Majelis Dikdasmen dan PNF PPM (15%)</label>
                            <div className="input-group">
                              <span className="input-group-text form-consx">Rp</span>
                              <input
                                disabled
                                id="disdasmen_pusat"
                                value={formDatax.disdasmen_pusat}
                                onChange={(e) =>
                                  setFormDatax({ ...formDatax, disdasmen_pusat: e.target.value })
                                }
                                className="form-control form-consx" type="text"
                              />
                            </div>
                          </div>
                          <div className="col-xl-12 col-md-12 col-sm-12 mt-3">
                            <label className='font-size-16 mb-2'>Dana DPP diserahkan secara langsung melalui tunai/transfer bank ke masing-masing tingkatan Majelis Dikdasmen dan PNF</label>
                          </div>
                        </div>
                      </div>

                      <hr className="garis-horiss mt-5 mb-5"></hr>


                      <div className="col-xl-4 col-md-4 col-sm-4 bg-form-didasmen">
                        <div className='posisi-lefts'>
                          <h3 className="font-size-20-bold">ALOKASI DANA DPP UNTUK PERSYARIKATAN</h3>
                          <label className='font-size-16'>Sebesar 50% dari total 30% DPP untuk operasional Persyarikatan atau pengembangan ortom</label>
                          <div className='col-xl-8 col-md-8 col-sm-8 mt-7'>
                            <label className='font-size-16 mb-2'><strong>TOTAL DANA DPP TAHUNAN PERSYARIKATAN</strong></label>
                            <div className="input-group">
                              <span className="input-group-text form-cons">Rp</span>
                              <input
                                disabled
                                id="total_dpp_majelis"
                                value={formDatax.total_dpp_majelis}
                                onChange={(e) =>
                                  setFormDatax({ ...formDatax, total_dpp_majelis: e.target.value })
                                }
                                className="form-control form-cons" type="text"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-8 col-md-8 col-sm-8 posisi-rights">
                        <div className="row">
                          <div className="col-xl-6 col-md-6 col-sm-6">
                            <label className='font-size-16 mb-2'>Pimpinan Ranting Muhammadiyah (5%)</label>
                            <div className="input-group">
                              <span className="input-group-text form-consx">Rp</span>
                              <input
                                disabled
                                id="pimpinan_ranting"
                                value={formDatax.pimpinan_ranting}
                                onChange={(e) =>
                                  setFormDatax({ ...formDatax, pimpinan_ranting: e.target.value })
                                }
                                className="form-control form-consx" type="text"
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 col-md-6 col-sm-6">
                            <label className='font-size-16 mb-2'>Pimpinan Cabang Muhammadiyah (35%)</label>
                            <div className="input-group">
                              <span className="input-group-text form-consx">Rp</span>
                              <input
                                disabled
                                id="pimpinan_cabang"
                                value={formDatax.pimpinan_cabang}
                                onChange={(e) =>
                                  setFormDatax({ ...formDatax, pimpinan_cabang: e.target.value })
                                }
                                className="form-control form-consx" type="text"
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 col-md-6 col-sm-6 mt-7">
                            <label className='font-size-16 mb-2'>Pimpinan Daerah Muhammadiyah (25%)</label>
                            <div className="input-group">
                              <span className="input-group-text form-consx">Rp</span>
                              <input
                                disabled
                                id="pimpinan_daerah"
                                value={formDatax.pimpinan_daerah}
                                onChange={(e) =>
                                  setFormDatax({ ...formDatax, pimpinan_daerah: e.target.value })
                                }
                                className="form-control form-consx" type="text"
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 col-md-6 col-sm-6 mt-7">
                            <label className='font-size-16 mb-2'>Pimpinan Wilayah Muhammadiyah (20%)</label>
                            <div className="input-group">
                              <span className="input-group-text form-consx">Rp</span>
                              <input
                                disabled
                                id="pimpinan_wilayah"
                                value={formDatax.pimpinan_wilayah}
                                onChange={(e) =>
                                  setFormDatax({ ...formDatax, pimpinan_wilayah: e.target.value })
                                }
                                className="form-control form-consx" type="text"
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 col-md-6 col-sm-6 mt-7">
                            <label className='font-size-16 mb-2'>Pimpinan Pusat Muhammadiyah (15%)</label>
                            <div className="input-group">
                              <span className="input-group-text form-consx">Rp</span>
                              <input
                                disabled
                                id="pimpinan_pusat"
                                value={formDatax.pimpinan_pusat}
                                onChange={(e) =>
                                  setFormDatax({ ...formDatax, pimpinan_pusat: e.target.value })
                                }
                                className="form-control form-consx" type="text"
                              />
                            </div>
                          </div>
                          <div className="col-xl-12 col-md-12 col-sm-12 mt-7 mb-5">
                            <label className='font-size-16' style={{ textTransform:'uppercase'}}><strong>Dana DPP diserahkan secara langsung melalui tunai/transfer bank ke masing-masing tingkatan Persyarikatan</strong></label>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>

                  <div className="col-xl-12 col-md-12 col-sm-12 bg-form-spesial4">
                    <div className="d-flex flex-column align-items-center justify-content-center mt-3">
                      <h2 className="font-white-20-bold mt-3">AKUN REKENING PENERIMA DANA TA'AWUN</h2>
                      <label className="font-white-20 mb-5"> Tahun Ajaran {formData.tahun_ajaran} </label>
                    </div>

                    <div className="row bg-light-form11">

                      {Object.keys(dataNR).map((jenisRekening) => (
                        <div className='mt-5 mb-5' key={jenisRekening}>
                          {dataNR[jenisRekening].map((item, index) => (
                            <div className='row' key={index}>
                              <div className='col-xl-12 col-md-12 col-sm-12'>
                                <h2 className="text-dark-100 font-size-xl">Akun Rekening {item.nama_group}</h2>
                              </div>
                              <div className="col-xl-4 col-md-4 col-sm-4 mt-3">
                                <label className='font-size-16 mb-2'>Nama Bank</label>
                                <input
                                  type="text"
                                  value={item.nama_bank}
                                  onChange={(e) => handleInputChange(jenisRekening, index, 'nama_bank', e.target.value)}
                                  className="form-control form-control-solid mb-2"
                                />
                              </div>
                              <div className="col-xl-4 col-md-4 col-sm-4 mt-3">
                                <label className='font-size-16 mb-2'>Akun Rekening</label>
                                <input
                                  type="text"
                                  value={item.no_rekening}
                                  onChange={(e) => handleInputChange(jenisRekening, index, 'no_rekening', e.target.value)}
                                  className="form-control form-control-solid mb-2"
                                />
                              </div>
                              <div className="col-xl-4 col-md-4 col-sm-4 mt-3">
                                <label className='font-size-16 mb-2'>Nama Pemilik Rekening</label>
                                <input
                                  type="text"
                                  value={item.nama_rekening}
                                  onChange={(e) => handleInputChange(jenisRekening, index, 'nama_rekening', e.target.value)}
                                  className="form-control form-control-solid mb-2"
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className='col-xl-12 col-md-12 col-sm-12 mb-7'>
                  <div className='bg-bukti-pembayaran mb-5'>
                    <h2 className='font-size-20-bold'>BUKTI PEMBAYARAN</h2>
                    <label className='font-size-16'>Unggah seluruh bukti pembayaran Dana Ta'awun <br></br>sekolah/madrasah</label>
                  </div>
                  <div className='d-flex flex-column align-items-center justify-content-center'
                       style={{
                         border: "2px dashed #ccc",
                         padding: "20px",
                         textAlign: "center",
                       }}>
                    <FileUploader
                      multiple={true}
                      handleChange={handleChange}
                      name="file"
                      types={fileTypes}
                    >
                      {file && file[0] ? (
                        <div>
                          <i className="fas fa-cloud-upload-alt fa-lg fs-4x mb-3"></i>
                          <p>Drag n drop unggah seluruh file bukti pembayaran Dana Ta’awun disini</p>
                          <p>atau</p>
                          <button className='btn btn-primary mb-5' disabled>Pilih file disini</button>
                          <p> (PDF maks 2MB, min width 400px)</p>
                          <p>File Name: {file[0].name}</p>
                          <button className='btn btn-primary' onClick={handleRemoveFile}>Remove</button>
                        </div>
                      ) : (
                        <div>
                          <i className="fas fa-cloud-upload-alt fa-lg fs-4x mb-3"></i>
                          <p>Drag n drop unggah seluruh file bukti pembayaran Dana Ta’awun disini</p>
                          <p>atau</p>
                          <button className='btn btn-primary mb-5'>Pilih file disini</button>
                          <p> (PDF maks 2MB, min width 400px)</p>
                        </div>
                      )}
                    </FileUploader>
                  </div>
                </div>

                <div className='col-xl-12 col-md-12 col-sm-12 bg-petunjuk'>
                  <div className='col-xl-12 col-md-12 col-sm-12 posisi-rightss'>
                    <h2 className='style-petunjuk'>PETUNJUK PENGIRIMAN BUKTI PEMBAYARAN</h2>
                    <div className='posisi-text'>
                      <ol>
                        <li style={{ textTransform:'uppercase'}}>Pada resi (cetak) pembayaran melalui transfer bank mohon untuk menambahkan informasi nama lengkap sekolah/madrasah, daerah, provinsi, nama dan nomor WhatsApp kepala sekolah/madrasah.
                        </li>
                        <li style={{ textTransform:'uppercase'}}>Seluruh bukti pembayaran yang dilakukan melalui setor tunai atau dengan transfer bank ke masing-masing rekening Persyarikatan dan ke masing-masing rekening Majelis Dikdasmen dan PNF untuk di gabungkan kedalam file Ms. Word lalu di simpan (save) ke dalam file PDF lalu diunggah pada form bukti pembayaran diatas.
                        </li>
                        <li style={{ textTransform:'uppercase'}}>Bila mengalami kendala saat melakukan unggah file bukti transfer pada sistem, maka file bukti transfer dapat dikirimkan melalui WhatsApp ke nomor <strong>Admin 0811-940-1912</strong>.
                        </li>

                      </ol>
                    </div>
                  </div>
                  {/* <div className='row'>
              </div> */}
                </div>
                <hr className="garis-hori mt-10"></hr>

                <div className="col-xl-12 col-md-12 col-sm-12">
                  <button
                    type="button"
                    className="btn btn-primary button-right"
                    onClick={handleSimpanClick}
                    // disabled={!isFormValid}
                  >
                    <i className="fa fa-file-text-o"></i> Simpan
                  </button>

                  <a className="btn btn-primary button-right"
                     onClick={handlePrint}
                  ><i className="fa fa-print"></i>Cetak Rincian</a>
                </div>

                {/* Modal untuk menampilkan pesan kesalahan */}
                {validationErrors.length > 0 && (
                  <div className="modal fade show" style={{ display: isErrorModalOpen ? "block" : "none" }}>
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Pesan Kesalahan</h5>
                          <button type="button" className="close" onClick={() => setIsErrorModalOpen(false)}>
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <ul>
                            {validationErrors.map((error, index) => (
                              <li key={index}>{error}</li>
                            ))}
                          </ul>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => setIsErrorModalOpen(false)}>Tutup</button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
