import React, {useState} from 'react'

function SearchArea(props) {
  const [search, setSearchArea] = useState('')
  const handleInputSearch = (e) => {
    e.preventDefault()
    props.handleFilter({
      search,
    })
    setSearchArea('')
  }
  return (
    <form className='d-flex input-group mb-3' onSubmit={handleInputSearch}>
      <input
        style={{height: '35px'}}
        onChange={(e) => {
          setSearchArea(e.target.value)
        }}
        type='number'
        placeholder='Cari Sekolah Berdasarkan NPSN'
        className='form-control'
        disabled={props.disabled}
      />
      <div className='input-group-append'>
        <button
          style={{
            height: '35px',
            width: '100px',
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0',
          }}
          className='btn btn-icon btn-info'
          type='submit'
          disabled={props.disabled}
        >
          Cari
        </button>
      </div>
    </form>
  )
}

export default SearchArea
