/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Card2} from '../../../../_metronic/partials/content/cards/Card2'
import {IconUserModel} from '../ProfileModels'

export function Akademik() {
  return (
    <>
      <div className='row'>
        <div className='col-xl-4' style={{marginTop: '10px'}}>
          <div className='card card-custom gutter-b card-stretch'>
            {/* begin::Body */}
            <div className='card-body'>
              {/* begin::Foto */}
              <div className='card card-customs bgi-no-repeat'>
                <img
                  style={{height: '245px'}}
                  src='https://peda.edumu.id/media/sekolah/pimpinan.jpg'
                ></img>
                {/* begin::Item */}
              </div>
              {/* end::Foto */}

              {/* begin::Items */}
              <div className='gutter-b d-flex flex-column align-items-center justify-content-center pt-10 pb-10 bgi-size-cover bgi-no-repeat rounded-top'>
                {/* begin::Item */}
                <h4> Sambutan ketua Majelis Dikdasmen</h4>
                <h5> Prof. Dr. Baedhowi. M. Si.</h5>
                {/* end::Item */}
                {/* begin::Item */}
                <div className='gutter-b d-flex flex-column align-items-center justify-content-center pt-10 pb-10'>
                  <p>
                    Puji syukur kita panjatkan ke hadirat Tuhan Yang Maha Kuasa atas penerbitan
                    Peraturan Menteri Pendidikan dan Kebudayaan RI Nomor 79 Tahun 2015 tentang Data
                    Pokok Pendidikan.
                  </p>
                </div>
                {/* end::Item */}
              </div>
              {/* end::Items */}
            </div>
            {/* end::Body */}
          </div>
        </div>
        <div className='col-xl-8' style={{marginTop: '10px'}}>
          <div className='card card-custom gutter-b card-stretch'>
            {/* begin::Body */}
            <div className='card-body'></div>
            {/* end::Body */}
          </div>
        </div>
      </div>
    </>
  )
}
