import React, { useEffect } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import imageDummy from '../../../assets/images/dummy/dummy-slide-1.jpeg'
import CustomPaginationsActionsVerval from '../../../app/modules/verval/CustomPaginationActionsVerval'
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../setup";
import { useLocation } from "react-router-dom";
import { tableYayasan } from "../../../setup/redux/actions/vervalYayasan";

const dataYayasanPusat = [
  {
    id: "nama_pimpinan_yayasan",
    icon: 'fa fa-user',
    text: 'Operator Yayasan :',
    value: "Fajar Hibatul Wafi"
  },
  {
    id: "nomor_telepon",
    icon: 'fa fa-phone',
    text: 'Telp : ',
    value: '(0274) 553132'
  },
  {
    id: "nomor_fax",
    icon: 'bi bi-link-45deg',
    text: 'Fax : ',
    value: '55223'
  },
  {
    id: "email",
    icon: 'fa fa-envelope',
    text: 'Email : ',
    value: 'muhammadiyah@gmail.com'
  },
  {
    id: "kode_pos",
    icon: 'fa fa-globe',
    text: 'Kode Pos : ',
    value: '55223'
  },
  {
    id: "no_pendirian_yayasan",
    icon: 'fa fa-tag',
    text: 'No. Pendirian Yayasan : ',
    value: "1"
  },
  {
    id: "tanggal_pendirian_yayasan",
    icon: 'bi bi-clock',
    text: 'Tgl Pendirian Yayasan : ',
    value: "20 Jan 2021"
  },
  {
    id: "nomor_pengesahan_pn_ln",
    icon: 'fa fa-tag',
    text: 'No. Pengesahan PN LN : ',
    value: '1'
  },
  {
    id: "nomor_sk_bn",
    icon: 'fa fa-tag',
    text: 'No. SK Pengesahan Badan Hukum Menkumham : ',
    value: '1'
  },
  {
    id: "tanggal_sk_bn",
    icon: 'bi bi-clock',
    text: 'Tgl SK Pengesahan Badan Hukum Menkumham : ',
    value: "2021-01-20"
  },
]
type Props = {
  center: [number, number];
}

const ChangeCenter: React.FC<Props> = ({center}) => {
  const map = useMap()
  map.setView(center, 18)
  return null
}

export const VervalPage: React.FC = () => {
  const dispatch = useDispatch()
  const location = useLocation();
  const path = location.pathname.split('/').reverse()[0]
  const {allYayasan, detailYayasan} = useSelector((state: RootState) => state.vervalReducer)
  const loading = useSelector((state: RootState) => state.loading.loading)

  const [position, setPosition] = React.useState<[number, number]>([-7.7780500852061305, 110.37545209633608]);

  useEffect(() => {
    const path = location.pathname.split('/').reverse()[0]
    if (path === 'AW8673') {
      setPosition([-7.7780500852061305, 110.37545209633608])
    }else{
      if (detailYayasan.length !== 0) {
        if (detailYayasan.lintang !== null && detailYayasan.bujur !== null) {
          setPosition([detailYayasan.lintang, detailYayasan.bujur])
        }
      }
    }

    console.log({detailYayasan});
    
  }, [location, detailYayasan])

  useEffect(() => {

    return () => {
      let payload = {
        page:1,
        limit:10,
        order_by:"nama",
        short:"asc",
        search:null,
        path
      }
      setPosition([-7.7780500852061305, 110.37545209633608])
      // dispatch({type: "DETAIL_YAYASAN", payload: []})
    }
  }, [])
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card mb-5 mb-xl-5">
          <div className="card-body">
            <div className="row">
              <div className="col-xl-12">
                <h1>
                  ({detailYayasan?.npyp || 'AW8673'}) {detailYayasan?.nama || 'Pimpinan Pusat Muhammadiyah'}
                </h1>
                <p>
                  <i className="fa fa-road" aria-hidden="true"></i>
                  {" "}
                  {`${detailYayasan?.alamat_jalan || 'Jl. Cik Di Tiro No.23'}, ${detailYayasan?.desa_kelurahan || '-'}, ${detailYayasan?.kecamatanm || 'Kec. Gondokusuman'}, ${detailYayasan?.kabupaten || 'Kota Yogyakarta'} ${detailYayasan?.provinsi || 'Prov. D.I. Yogyakarta'}`}
                </p>
              </div>
              <div className="col-xl-8">
                <div className="card mb-5 mb-xl-5">
                  <div className="card-body">
                    {
                      detailYayasan && (
                        <img
                          className='w-100 rounded'
                          src={
                            detailYayasan.length && detailYayasan.images !== "undefined" ? detailYayasan?.images[0]?.image : imageDummy
                          }
                          alt='Img'
                        />
                      )
                    }
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="card mb-5 mb-xl-10">
                  <div className="card-body">
                    {
                      dataYayasanPusat.map((item, id) => {
                        return (
                          <div className="item-row d-flex justify-content-start align-items-center border-bottom py-3 border-dark" key={id}>
                            <span className="col-1">
                              <i className={`text-black ${item.icon}`} aria-hidden="true"></i>
                            </span>
                            <span>{item.text} {detailYayasan[item.id] || item.value}</span>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-12">
        <div className="card mb-5 mb-xl-5">
          <div className="card-body">
            {
              loading && detailYayasan.length == 0  ? (
                "Loading..."
              ): (
                <MapContainer
                  style={{height: '500px', width: '100%', zIndex: '0'}}
                  zoom={18}
                  maxZoom={18}
                  // [-7.7780500852061305, 110.37545209633608]
                  center={position}
                >
                  <ChangeCenter center={position} />
                  <TileLayer
                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  ></TileLayer>
                  <Marker
                    position={position}
                  >
                    <Popup>
                      <span className="text-dark">{detailYayasan.nama}</span>
                    </Popup>
                  </Marker>
                </MapContainer>
              )
            }
          </div>
        </div>
      </div>

      <div className="col-xl-12">
        <div className="card md-5 mb-xl-5">
          <div className="card-body">
            <div className="col-xl-12 mb-5">
              <h1>
                <i className="fa fa-home fs-1 me-3 text-black" aria-hidden="true"></i>
                {path === 'AW8673' ? 'Cabang Yayasan' : "Sekolah Naungan"}
              </h1>
            </div>
            <div className="col-xl-12">
              <CustomPaginationsActionsVerval dataCabang={allYayasan} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}