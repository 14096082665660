import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'

export function HalamanUnduh() {
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {}, [])

  return (
    <div>
      <div className='row'>
        <div className='col-xl-4'>
          <div className='card card-custom gutter-b card-stretch mb-3' style={{height: '99%'}}>
            <div className='card-body'>
              <div className='card card-customs bgi-no-repeat'>
                <img style={{height: '245px'}} src={toAbsoluteUrl('/media/books/img-72.jpg')} />
              </div>
              <div className='flex-grow-1 card-spacer-x'>
                <div className='gutter-b d-flex flex-column align-items-center justify-content-center pt-10 pb-10'>
                  <h4> Maths Class</h4>
                  <h5> 11:20-12:00, Mark Liam</h5>
                </div>
                <div className='d-flex align-items-center justify-content-between mb-10'>
                  <div className='d-flex align-items-center mr-2'>
                    <div className='symbol symbol-50 symbol-light flex-shrink-0'>
                      <div className='symbol-label' style={{marginRight: '1rem'}}>
                        <span className='svg-icon'>
                          <KTSVG className='h-50' path='/media/svg/misc/eolic-energy.svg'></KTSVG>
                        </span>
                      </div>
                    </div>
                    <div>
                      <a
                        href='#'
                        className='font-size-h6 text-dark-75 text-hover-primary font-weight-bolder'
                      >
                        Top Authors{' '}
                      </a>
                      <div className='font-size-sm text-muted font-weight-bold mt-1'>
                        Ricky Hunt, Sandra Trepp
                      </div>
                    </div>
                  </div>
                  <div className='label label-light label-inline font-weight-bold text-dark-50 py-4 px-3 font-size-base'>
                    +105$
                  </div>
                </div>
                {/* end::Item */}

                {/* begin::Item */}
                <div className='d-flex align-items-center justify-content-between mb-10'>
                  <div className='d-flex align-items-center mr-2'>
                    <div className='symbol symbol-50 symbol-light flex-shrink-0'>
                      <div className='symbol-label' style={{marginRight: '1rem'}}>
                        <span className='svg-icon'>
                          <KTSVG className='h-50' path='/media/svg/misc/eolic-energy.svg'></KTSVG>
                        </span>
                      </div>
                    </div>
                    <div>
                      <a
                        href='#'
                        className='font-size-h6 text-dark-75 text-hover-primary font-weight-bolder'
                      >
                        Bestsellers{' '}
                      </a>
                      <div className='font-size-sm text-muted font-weight-bold mt-1'>
                        Pitstop Email Marketing
                      </div>
                    </div>
                  </div>
                  <div className='label label-light label-inline font-weight-bold text-dark-50 py-4 px-3 font-size-base'>
                    +60$
                  </div>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                  <div className='d-flex align-items-center mr-2'>
                    <div className='symbol symbol-50 symbol-light flex-shrink-0'>
                      <div className='symbol-label' style={{marginRight: '1rem'}}>
                        <span className='svg-icon'>
                          <KTSVG className='h-50' path='/media/svg/misc/eolic-energy.svg'></KTSVG>
                        </span>
                      </div>
                    </div>
                    <div>
                      <a
                        href='#'
                        className='font-size-h6 text-dark-75 text-hover-primary font-weight-bolder'
                      >
                        Top Engagement{' '}
                      </a>
                      <div className='font-size-sm text-muted font-weight-bold mt-1'>
                        KT.com solution provider
                      </div>
                    </div>
                  </div>
                  <div className='label label-light label-inline font-weight-bold text-dark-50 py-4 px-3 font-size-base'>
                    +75$
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-8'>
          {/* bigin::Dokumentasi */}
          <div className='row mb-3'>
            <div className='col-xl-12'>
              <div className='card card-custom gutter-b d-flex flex-column bgi-size-cover bgi-no-repeat rounded-top'>
                {/* Header */}
                <div className='card-header border-0'>
                  <div className='d-flex flex-column py-2 w-75'>
                    <h3 className='card-title font-weight-bolder text-dark'>UNDUH PANDUAN</h3>
                    <span className='text-muted font-weight-bold'>Total Dokumen 4 file</span>
                  </div>
                </div>

                {/* Body */}
                <div className='card-body pt-0'>
                  {/* begin::Item */}
                  <div className='mb-6'>
                    <div className='d-flex align-items-center flex-grow-1'>
                      {/* begin::logo */}
                      <div className='symbol symbol-50 symbol-light mt-1'>
                        <span className='symbol-label' style={{marginRight: '1rem'}}>
                          {/* <SVG
                                              src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")}
                                              className="h-75 align-self-end"
                                          ></SVG> */}
                        </span>
                      </div>
                      {/* end::logo */}
                      <div className='d-flex flex-wrap align-items-center justify-content-between w-100'>
                        <div className='d-flex flex-column align-items-cente py-2 w-75'>
                          <p className='text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1'>
                            Panduan Penggunaan Aplikasi Dapodik
                          </p>

                          <span className='text-muted font-weight-bold'>PDF</span>
                        </div>

                        <div>
                          <p className='text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1'>
                            1MB
                          </p>

                          <span className='text-muted font-weight-bold'>ukuran file</span>
                        </div>

                        <a
                          href='https://dapo.kemdikbud.go.id/files/panduan/Panduan%20Aplikasi%20Dapodikdasmen%20Versi%202021.pdf'
                          target='_blank'
                          className='btn btn-icon btn-light btn-hover-primary btn-sm'
                        >
                          <span className='svg-icon svg-icon-md svg-icon-primary'>
                            <KTSVG path='/media/svg/files/upload.svg'></KTSVG>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* end::Item */}

                  {/* begin::Item */}
                  <div className='mb-6'>
                    <div className='d-flex align-items-center flex-grow-1'>
                      {/* begin::logo */}
                      <div className='symbol symbol-50 symbol-light mt-1'>
                        <span className='symbol-label' style={{marginRight: '1rem'}}>
                          {/* <SVG
                                              src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")}
                                              className="h-75 align-self-end"
                                          ></SVG> */}
                        </span>
                      </div>
                      {/* end::logo */}
                      <div className='d-flex flex-wrap align-items-center justify-content-between w-100'>
                        <div className='d-flex flex-column align-items-cente py-2 w-75'>
                          <p className='text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1'>
                            Panduan Pengisian Sanitasi Sekolah
                          </p>

                          <span className='text-muted font-weight-bold'>PDF</span>
                        </div>

                        <div>
                          <p className='text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1'>
                            1MB
                          </p>

                          <span className='text-muted font-weight-bold'>ukuran file</span>
                        </div>

                        <a
                          href='https://cdn-dapodik.kemdikbud.go.id/pmp/FA_Panduan_Pengisian_Sanitasi_Sekolah_-_150920_Resize.pdf'
                          target='_blank'
                          className='btn btn-icon btn-light btn-hover-primary btn-sm'
                        >
                          <span className='svg-icon svg-icon-md svg-icon-primary'>
                            <KTSVG path='/media/svg/files/upload.svg'></KTSVG>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* end::Item */}

                  {/* begin::Item */}
                  <div className='mb-6'>
                    <div className='d-flex align-items-center flex-grow-1'>
                      {/* begin::logo */}
                      <div className='symbol symbol-50 symbol-light mt-1'>
                        <span className='symbol-label' style={{marginRight: '1rem'}}>
                          {/* <SVG
                                              src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")}
                                              className="h-75 align-self-end"
                                          ></SVG> */}
                        </span>
                      </div>
                      {/* end::logo */}
                      <div className='d-flex flex-wrap align-items-center justify-content-between w-100'>
                        <div className='d-flex flex-column align-items-cente py-2 w-75'>
                          <p className='text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1'>
                            Tanya Jawab Aplikasi Dapodik
                          </p>

                          <span className='text-muted font-weight-bold'>FAQ - Umum</span>
                        </div>

                        <div>
                          <p className='text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1'>
                            1MB
                          </p>

                          <span className='text-muted font-weight-bold'>ukuran file</span>
                        </div>

                        <a
                          href='https://simpandata.kemdikbud.go.id/index.php/s/tF8KJGodKNky75w/download?path=%2F&files=FAQ%202%20Aplikasi%20Dapodikdasmen%20versi%202021%20-%20Umum.pdf'
                          target='_blank'
                          className='btn btn-icon btn-light btn-hover-primary btn-sm'
                        >
                          <span className='svg-icon svg-icon-md svg-icon-primary'>
                            <KTSVG path='/media/svg/files/upload.svg'></KTSVG>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* end::Item */}

                  {/* begin::Item */}
                  <div className='mb-6'>
                    <div className='d-flex align-items-center flex-grow-1'>
                      {/* begin::logo */}
                      <div className='symbol symbol-50 symbol-light mt-1'>
                        <span className='symbol-label' style={{marginRight: '1rem'}}>
                          {/* <SVG
                                              src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")}
                                              className="h-75 align-self-end"
                                          ></SVG> */}
                        </span>
                      </div>
                      {/* end::logo */}
                      <div className='d-flex flex-wrap align-items-center justify-content-between w-100'>
                        <div className='d-flex flex-column align-items-cente py-2 w-75'>
                          <p className='text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1'>
                            Tanya Jawab Aplikasi Dapodik
                          </p>

                          <span className='text-muted font-weight-bold'>FAQ - Kategori</span>
                        </div>

                        <div>
                          <p className='text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1'>
                            1MB
                          </p>

                          <span className='text-muted font-weight-bold'>ukuran file</span>
                        </div>

                        <a
                          href='https://simpandata.kemdikbud.go.id/index.php/s/tF8KJGodKNky75w/download?path=%2F&files=FAQ%201%20Aplikasi%20Dapodikdasmen%20versi%202021%20-%20Kategori.pdf'
                          target='_blank'
                          className='btn btn-icon btn-light btn-hover-primary btn-sm'
                        >
                          <span className='svg-icon svg-icon-md svg-icon-primary'>
                            <KTSVG path='/media/svg/files/upload.svg'></KTSVG>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* end::Item */}
                </div>
              </div>
            </div>
          </div>
          {/* end::Dokumentasi */}
          {/* bigin::Data Prefill */}
          <div className='row mb-3'>
            <div className='col-xl-12'>
              <div className='card card-custom gutter-b d-flex flex-column bgi-size-cover bgi-no-repeat rounded-top'>
                {/* Header */}
                <div className='card-header border-0'>
                  <h3 className='card-title font-weight-bolder text-dark'>UNDUH DOKUMENTASI</h3>
                </div>

                {/* Body */}
                <div className='card-body pt-0'>
                  {/* begin::Item */}
                  <div className='mb-6'>
                    <div className='d-flex align-items-center flex-grow-1'>
                      {/* begin::logo */}
                      <div className='symbol symbol-50 symbol-light mt-1'>
                        <span className='symbol-label' style={{marginRight: '1rem'}}>
                          {/* <SVG
                                              src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")}
                                              className="h-75 align-self-end"
                                          ></SVG> */}
                        </span>
                      </div>
                      {/* end::logo */}
                      <div className='d-flex flex-wrap align-items-center justify-content-between w-100'>
                        <div className='d-flex flex-column align-items-cente py-2 w-75'>
                          <p className='text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1'>
                            Panduan Penggunaan Aplikasi Dapodik
                          </p>

                          <span className='text-muted font-weight-bold'>PDF</span>
                        </div>

                        <div>
                          <p className='text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1'>
                            1MB
                          </p>

                          <span className='text-muted font-weight-bold'>ukuran file</span>
                        </div>

                        <a
                          href='https://dapo.kemdikbud.go.id/files/panduan/Panduan%20Aplikasi%20Dapodikdasmen%20Versi%202021.pdf'
                          target='_blank'
                          className='btn btn-icon btn-light btn-hover-primary btn-sm'
                        >
                          <span className='svg-icon svg-icon-md svg-icon-primary'>
                            <KTSVG path='/media/svg/files/upload.svg'></KTSVG>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* end::Item */}
                </div>
              </div>
            </div>
          </div>
          {/* end::Data Prefill */}
          {/* bigin::Box Video */}
          <div className='row mb-3'>
            <div className='col-xl-12'>
              <div className='card card-custom gutter-b d-flex flex-column bgi-size-cover bgi-no-repeat rounded-top'>
                {/* Header */}
                <div className='card-header border-0'>
                  <div className='d-flex flex-column align-items-cente py-2 w-75'>
                    <h3 className='card-title font-weight-bolder text-dark'>
                      Video Infografis Pengenalan Aplikasi Dapodik
                    </h3>
                    <span className='text-muted font-weight-bold'>
                      Video Infografis Pengenalan Aplikasi Dapodik dapat dilihat{' '}
                      <a href='https://www.youtube.com/watch?v=phKuFc2FHtQ' target='_blank'>
                        disini
                      </a>
                    </span>
                  </div>
                </div>

                {/* Body */}
                <div className='card-body pt-0'>
                  {/* begin::Item */}
                  <div className='mb-6'>
                    <div className='d-flex align-items-center flex-grow-1'>
                      <iframe
                        id='ytplayer'
                        width='100%'
                        height='450px'
                        src='https://www.youtube.com/embed/phKuFc2FHtQ'
                        frameBorder='0'
                      ></iframe>
                    </div>
                  </div>
                  {/* end::Item */}
                </div>
              </div>
            </div>
          </div>
          {/* end::Box Video */}
        </div>
      </div>
      {/* end::Row */}

      {/* end::Dashboard */}
    </div>
  )
}
