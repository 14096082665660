const initialState = {
  allDataLevel: [],
}

const allDataLevelReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ALL_DATA_LEVEL':
      return {...state, allDataLevel: action.payload}
    default:
      return state
  }
}

export default allDataLevelReducer
