import React from 'react'
import {MenuItem} from './MenuItem'
// import {MenuInnerWithSub} from './MenuInnerWithSub'
// import {MegaMenu} from './MegaMenu'
import {useIntl} from 'react-intl'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'Beranda'})} to='/beranda' />
       {/*<MenuItem title='Profile Sekolah' to='/sekolah/profile' />*/}
      {/* <MenuItem title='Unduh' to='/unduh' /> */}
      <MenuItem title='Berita' to='/berita' />
      {/* <MenuItem title='Data Pokok' to='/data-pokok' /> */}
      <MenuItem title='Bantuan' to='/bantuan' />
      {/* <MenuItem title='Import' to='/import' /> */}
      <MenuItem title='Verval' to='/verval/AW8673' />
        {/*<MenuItem title="Dana Ta'awun" to='/dana-taawun' />*/}
    </>
  )
}
