import axios from 'axios'

const all_data_map = () => {
  console.log('aaa');
  return function (dispatch) {
    const API = process.env.REACT_APP_API_URL_NEW
    const TOKEN = process.env.REACT_APP_TOKEN_NEW
    dispatch({type: 'LOADING', payload: true})
    const dataMap = localStorage.getItem('allDataMap')
    if (dataMap !== "undefined" && JSON.parse(dataMap)) {
      dispatch({type: 'ALL_DATA_MAP', payload: JSON.parse(dataMap)})
      dispatch({type: 'FILTER_DATA_MAP', payload: JSON.parse(dataMap)})
      dispatch({type: 'LOADING', payload: false})
    } else {
      axios
        .get(`${API}/school`, {
          headers: {
            key: TOKEN,
            Authorization: `Bearer ${localStorage.access_token}`,
          },
        })
        .then(({data}) => {
          dispatch({type: 'LOADING', payload: false})
          // // if (data.data.length > 0) {
            dispatch({type: 'ALL_DATA_MAP', payload: data.data})
            dispatch({type: 'FILTER_DATA_MAP', payload: data.data})
            localStorage.setItem('allDataMap', JSON.stringify(data.data))
          // }
        })
        .catch((err) => {
        })
    }
  }
}


export default all_data_map
