import React, {FC, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet'
import * as L from 'leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import {NavLink} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import getAllDataMap from '../../../setup/redux/actions/getAllDataMap'
import {RootState} from '../../../setup'
import CustomPaginationActionsTable from '../../modules/dashboard/CustomPaginationActionsTable'
import SearchArea from '../../modules/dashboard/SearchArea'
import moment from 'moment'
import { getDatatableEducationStep, getTotalEducationStep } from '../../../setup/redux/actions/getEducationStep'
import { getMarkerDetail } from '../../../setup/redux/actions/getMarkerDetail'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'dashboard',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

function DashboardPage() {
  const dispatch = useDispatch()
  const isLoading = useSelector((state: RootState) => state.loading.loading)
  const allDataMap = useSelector((state: RootState) => state.allDataMapReducer.allDataMap)
  const filterDataMap = useSelector((state: RootState) => state.allDataMapReducer.filterDataMap)
  const {dataTableEducationStep, totalEducationStep} = useSelector((state: RootState) => state.educationStepReducer)
  const {markerDetail} = useSelector((state: RootState) => state.markerDetailReducer)
  const [loading, setLoading] = useState(false)
  const [loadingMarker, setLoadingMarker] = useState(false)
  const [date, setDate] = useState(moment().format('DD MMMM YYYY'))

  const handleFilter = (e: any) => {
    setLoading(true)
    if (e.search.length > 0) {
      const regex = new RegExp(e.search, 'i')
      const filterSekolah = allDataMap.filter((sekolah: any) => {
        return regex.test(sekolah.npsn)
      })
      // const keySearch = e.search
      // const filterSekolah = allDataMap.filter((sekolah: any) => {
      //   return JSON.stringify(sekolah).toLowerCase().includes(keySearch.toLowerCase())
      // })

      dispatch({type: 'FILTER_DATA_MAP', payload: filterSekolah})
    } else {
      dispatch(getAllDataMap())
    }
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }

  // icon marker
  const iconMarker = (color: string = "SMK/MAK") => {
    const tkPaudIcon = new L.Icon({
      iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png"
    })
    const sdMiIcon = new L.Icon({
      iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png"
    })
    const smpMtsIcon = new L.Icon({
      iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png"
    })
    const smaMaIcon = new L.Icon({
      iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-grey.png"
    })
    const smkMakIcon = new L.Icon({
      iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-orange.png"
    })
    const sdlbSmplbSlbIcon = new L.Icon({
      iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-yellow.png"
    })
    const pkbmIcon = new L.Icon({
      iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-violet.png"
    })
    return color === "TK/PAUD" ? tkPaudIcon : color === "SD/MI" ? sdMiIcon : color === "SMP/MTS" ? smpMtsIcon : color === "SMA/MA" ? smaMaIcon : color === "SMK/MAK" ? smkMakIcon : color === "SDLB/SMPLB/SLB" ? sdlbSmplbSlbIcon : color === "PKBM" ? pkbmIcon : smkMakIcon

  }

  const setBgColor = (jenjang: string) => {
    switch (jenjang.toUpperCase()) {
      case "TK/PAUD":
        return {
          box: "#e5f9f9",
          angka: "#04c8c8",
          text: "#000000"
        }
      case "SD/MI":
        return {
          box: "#feecf0",
          angka: "#f1416c",
          text: "#000000"
        }

      case "SMP/MTS":
        return {
          box: "#e7f5fd",
          angka: "#159bea",
          text: "#000000"
        }

      case "SMA/MA":
        return {
          box: "#efefef",
          angka: "#7f7f7f",
          text: "#000000"
        }

      case "SMK/MAK":
        return {
          box: "#fef3ec",
          angka: "#d45812",
          text: "#000000"
        }

      case "SDLB/SMPLB/SLB":
        return {
          box: "#fef6da",
          angka: "#e9b600",
          text: "#000000"
        }

      case "PKBM":
        return {
          box: "#ede7fd",
          angka: "#5308c4",
          text: "#000000"
        }

      default:
        return {
          box: "#ffffff",
          angka: "#00000",
          text: "#00000"
        }
    }
  }

  const getDetailMarker = (schoolId: string) => {
    setLoadingMarker(true)
    dispatch(getMarkerDetail(schoolId))
  }

  useEffect(() => {
    if (markerDetail) {
      setLoadingMarker(false)
    }
  }, [markerDetail])

  useEffect(() => {
    dispatch({type: 'FILTER_DATA_MAP', payload: []})
    dispatch(getAllDataMap())
    dispatch(getDatatableEducationStep())
    dispatch(getTotalEducationStep())
  }, [])

  return (
    <div className='row'>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            {isLoading || loading ? (
              <div
                className='justify-content-center'
                style={{position: 'absolute', zIndex: '5', top: '45%', width: '94%'}}
              >
                <div className='text-center mx-auto mb-4'>
                  <h2>Sedang Mengambil Data</h2>
                </div>
                <div className='text-center col-1 mx-auto'>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            <MapContainer
              style={{height: '500px', width: '100%', zIndex: '0'}}
              zoom={5}
              maxZoom={18}
              center={[-2.548926, 118.0148634]}
            >
              <TileLayer
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                attribution=''
              ></TileLayer>
              <MarkerClusterGroup>
                {filterDataMap?.length === 0
                  ? ''
                  : filterDataMap?.map(
                      (item: {
                        latitude: number
                        lat: number
                        longitude: number
                        long: number
                        sekolah_id: any
                        name_new: string
                        npsn:
                          | boolean
                          | React.ReactChild
                          | React.ReactFragment
                          | React.ReactPortal
                          | null
                          | undefined
                        sekolah_nama:
                          | boolean
                          | React.ReactChild
                          | React.ReactFragment
                          | React.ReactPortal
                          | null
                          | undefined
                        alamat:
                          | boolean
                          | React.ReactChild
                          | React.ReactFragment
                          | React.ReactPortal
                          | null
                          | undefined
                        type_education: string
                      }) => {
                        return (
                          <Marker
                            key={item.sekolah_id}
                            position={[item.latitude || item.lat, item.longitude || item.long]}
                            icon={iconMarker(item.type_education)}
                            eventHandlers={{
                              click: () => {
                                getDetailMarker(item.sekolah_id);
                              }
                            }}
                          >
                            <Popup>
                              {
                                loadingMarker ? (
                                  <div className='text-center'>
                                    <div className='spinner-border' role='status'>
                                      <span className='visually-hidden'>Loading...</span>
                                    </div>
                                  </div>
                                ): (
                                  <>
                                    <NavLink to={`/sekolah/profile/${markerDetail.sekolah_id}`}>
                                      {markerDetail.name_new}
                                    </NavLink>
                                    <br />
                                    <hr></hr>
                                    <span>Alamat : {markerDetail.alamat}</span>
                                    <br />
                                    <hr></hr>
                                    <a
                                      href={`https://www.google.co.id/maps/dir//${markerDetail.latitude},${markerDetail.longitude}/@${markerDetail.latitude},${markerDetail.longitude},12z?expflags=enable_star_based_justifications:true`}
                                      target={'_blank'}
                                    >
                                      {' '}
                                      Direction
                                    </a>
                                  </>
                                )
                              }
                            </Popup>
                          </Marker>
                        )
                      }
                    )}
              </MarkerClusterGroup>
            </MapContainer>
          </div>
          {isLoading || loading ? (
            <SearchArea disabled={true} />
          ) : (
            <SearchArea disabled={false} handleFilter={handleFilter} />
          )}
        </div>
      </div>
      <div className='col-xl-12'>
        <div
          className='d-flex justify-content-between align-items-center'
        >
          {totalEducationStep && totalEducationStep?.category && Object.keys(totalEducationStep.category).map((item: any) => {
            return (
              <div
                key={totalEducationStep.category[item].name}
                className='mb-10'
                style={{
                  width: `calc(${100/7}% - 20px)`,
                  // padding: '10px',
                }}
              >
                <div
                  className='card card-custom gutter-b d-flex flex-column align-items-center justify-content-center pt-10 pb-10 bgi-size-cover bgi-no-repeat rounded-top'
                  style={{
                    height: '150px',
                    // backgroundImage: `url("/media/sekolah/pattern.png")`,
                    backgroundColor: setBgColor(totalEducationStep.category[item].name).box
                  }}
                >
                  <h2
                    className='font-weight-bolder fs-2qx'
                    style={{
                      color: setBgColor(totalEducationStep.category[item].name).angka,
                    }}
                  >
                    {' '}
                    {totalEducationStep.category[item].schools}{' '}
                  </h2>
                  <p
                    className='font-size-5 fw-bold'
                    style={{
                      color: setBgColor(totalEducationStep.category[item].name).text,
                    }}
                  >
                    {' '}
                    {totalEducationStep.category[item].name}{' '}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className='col-xl-4'>
        <div className='card card-custom gutter-b' style={{height: '100%'}}>
          <div className='card-body'>
            <div className='card card-customs bgi-no-repeat'>
              <img src='https://peda.edumu.id/media/sekolah/pimpinan.jpg' alt='pemimpin' />
            </div>
            <div className='gutter-b d-flex flex-column align-items-center justify-content-center pt-10 pb-10 bgi-size-cover bgi-no-repeat rounded-top'>
              <h4> Sambutan ketua Majelis Dikdasmen</h4>
              <h5> Dr. Sungkowo Mudjiamano, M.Si.</h5>
              <div className='gutter-b d-flex flex-column align-items-center justify-content-center pt-10 pb-10'>
                <p>
                Puji syukur kehadirat Allah SWT akhirnya majelis dikdasmen punya Sistem Informasi Dikdasmen Muhammadiyah (SISDIKMU) yang bisa menjadi acuan untuk informasi sekaligus bahan untuk pengambilan keputusan. Data ini diambil dari hasil kerjasama Pusdatin Kemendikbud dan juga partisipasi dari pimpinan wilyah, daerah, cabang dan satuan pendidikan.
                </p>
              </div>
              {/* <div className="d-flex justify-content-start w-100">
                <a href="#" className="btn btn-sm btn-primary">
                  Selengkapnya
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className='col-xl-8'>
        <div className='row'>
          <div className='col-xl-12'>
            <div className='card card-custom gutter-b card-stretch'>
              <div className='card-header border-1 d-flex flex-column align-items-center justify-content-center pt-1 pb-1'>
                <h3 className='text-dark-75 font-weight-bolder font-size-5'>
                  {' '}
                  Rekapitulasi Data Pokok Pendidikan Nasional Muhammadiyah{' '}
                </h3>
                <h3 className='text-dark-75 font-weight-bolder font-size-5'>
                  {' '}
                  Per {date} 00:00:00
                </h3>
              </div>
              <CustomPaginationActionsTable dataTable={dataTableEducationStep} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Sistem Informasi Dikdasmen Muhammadiyah</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
