import React, {Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {DanaTaawun} from '../pages/danaTaawun/DanaTaawun'
import {DashboardMajelis} from '../pages/dashboardMajelis/DashboardMajelis'
import {ProfileSekolah} from '../pages/ProfileSekolah'
import {Unduh} from '../pages/unduh/Unduh'
import {Berita} from '../pages/berita/Berita'
import {DataPokok} from '../pages/dataPokok/Data-pokok'
import {Bantuan} from '../pages/bantuan/Bantuan'
import {Import} from '../pages/import/Import'
import { Verval } from '../pages/verval/Verval'

export function PrivateRoutes() {
  return (
    <Switch>
      <Route path='/beranda' component={DashboardWrapper} />
      <Route path='/sekolah' component={ProfileSekolah} />
      <Route path='/unduh' component={Unduh} />
      <Route path='/berita' component={Berita} />
      <Route path='/data-pokok' component={DataPokok} />
      <Route path='/dana-taawun' component={DanaTaawun} />
      <Route path='/profil-majelis' component={DashboardMajelis} />
      <Route path='/bantuan' component={Bantuan} />
      {/* <Route path='/import' component={Import} /> */}
      <Route path='/verval' component={Verval} />
      {/* <Redirect from='/auth' to='/dashboard' /> */}
      <Redirect exact from='/' to='/beranda' />
      {/* <Redirect to='error/404' /> */}
    </Switch>
  )
}
