const initialState = {
  user: [],
}

const dataUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER':
      return {...state, user: action.payload}
    default:
      return state
  }
}

export default dataUserReducer
