import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {HalamanUnduh} from './HalamanUnduh'

export default function UnduhPage() {
  return (
    <Switch>
      <Route path={'/unduh'} component={HalamanUnduh} />
    </Switch>
  )
}
