import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import {detailYayasan, tableYayasan} from "../../../setup/redux/actions/vervalYayasan";
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { VervalPage } from "../../modules/verval/VervalPage";

const profileBreadCrumbs: Array<PageLink> = [
    {
      title: 'Verval',
      path: '/verval',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

export const Verval = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const path = location.pathname.split('/').reverse()[0]

  useEffect(() => {
    let payload = {
      page:1,
      limit:10,
      order_by:"nama",
      short:"asc",
      search:null,
      path
    }
    if (path !== 'verval' && path !== 'AW8673') {
      dispatch(tableYayasan(payload))
      dispatch(detailYayasan({yayasan_id: path}))
    }else{
      payload.path = ''
      dispatch(tableYayasan(payload))
      dispatch({type: "DETAIL_YAYASAN", payload: []})
    }
  }, [path])
  return (
    <Switch>
      <Route path={`/verval/${location.pathname.split('/').reverse()[0]}`}>
        <PageTitle breadcrumbs={profileBreadCrumbs}>Verifikasi dan Validasi (Nomor Pokok Yayasan Nasional)</PageTitle>
        <VervalPage />
      </Route>
      <Redirect from="/verval" exact={true} to="/verval/AW8673" />
      <Redirect to={`/verval/${location.pathname.split('/').reverse()[0]}`} />
    </Switch>
  )
}