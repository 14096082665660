import React, {useEffect} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
// import {useLocation} from 'react-router-dom'
// import {useDispatch} from 'react-redux'
import DataGuruPage from '../../modules/DataGuru/HalamanDagu'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Data Pokok',
    path: '/data-pokok',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export function DataPokok() {
  // const location = useLocation()
  // const dispatch = useDispatch()

  useEffect(() => {}, [])

  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Sekolah Madrasah Muhammadiyah</PageTitle>
      <DataGuruPage />
    </>
  )
}
