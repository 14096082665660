/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {Dropdown1} from '../../../_metronic/partials'
import {useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {log} from 'console'

function ProfileHeader() {
  const location = useLocation()
  const detailSekolah = useSelector((state: RootState) => state.profileSekolahReducer.detailSekolah)
  const [totalSiswa, setTotalSiswa] = useState(0)
  const [totalGuru, setTotalGuru] = useState(0)
  const [menu, setMenu] = useState<boolean>(false)

  const hanldeDisplayMenu = () => {
    setMenu(!menu)
  }

  useEffect(() => {
    if (detailSekolah) {
      let guru: number = 0
      let siswa: number = 0

      for (let key in detailSekolah.details) {
        if (key.includes('guru')) guru += detailSekolah.details[key]
        if (key.includes('pd_tkt')) siswa += detailSekolah.details[key]
      }

      setTotalGuru(guru)
      setTotalSiswa(siswa)
    }
  }, [detailSekolah])

  return (
    <>
      {detailSekolah && detailSekolah.length === 0 ? (
        ''
      ) : (
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body pt-9 pb-0'>
            {/* style baru */}
            <div className="d-flex flex-wrap flex-sm-nowrap">
              {/* <!--begin: Pic--> */}
              <div className="me-7 mb-4">
                <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                  <img src={window.location.origin + '/media/logos/Logo_blank.png'} alt='Metornic' />
                  <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
                </div>
              </div>
              {/* <!--end::Pic--> */}
              {/* <!--begin::Info--> */}
              <div className="flex-grow-1">
                {/* <!--begin::Title--> */}
                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                  {/* <!--begin::User--> */}
                  <div className="d-flex flex-column">
                    {/* <!--begin::Name--> */}
                    <div className="d-flex align-items-center mb-2">
                      <a href="#" className="text-gray-900 text-hover-primary fs-2 fw-bold me-1">
                        ({detailSekolah?.npsn || 33202806}) {detailSekolah?.nama || 'SMA MUHAMMADIYAH'}
                      </a>
                      <a href='#'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen026.svg'
                          className='svg-icon-1 svg-icon-primary'
                        />
                      </a>
                    </div>
                    {/* <!--end::Name--> */}
                    {/* <!--begin::Info--> */}
                    <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                      <a href="#" className="d-flex align-items-start text-gray-400 text-hover-primary me-5 mb-2 w-100">
                        <span className="svg-icon svg-icon-4 me-3 pt-1">
                          <i 
                            className="fa fa-map-marker" 
                            style={{color: '#a1a5b7'}}
                            aria-hidden="true"></i>
                        </span>
                        {detailSekolah?.details?.alamat_jalan} RT. {detailSekolah?.details?.rt} RW.{' '}
                        {detailSekolah?.details?.rw} {detailSekolah?.details?.desa_kelurahan}{' '}
                        {detailSekolah?.details?.kecamatan} {detailSekolah?.details?.kabupaten}{' '}
                        {detailSekolah?.details?.provinsi}
                      </a>
                      <a href="#" className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                        <span className="svg-icon svg-icon-4 me-2">
                          <i
                            className='bi bi-telephone-fill'
                            style={{color: '#a1a5b7'}}
                          ></i>
                        </span>
                        {detailSekolah?.details?.nomor_telepon}
                      </a>
                      <a href="#" className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                        <span className="svg-icon svg-icon-4 me-2">
                          <i
                            className='bi bi-envelope-fill'
                            style={{color: '#a1a5b7'}}
                          ></i>
                        </span>
                        {detailSekolah?.details?.email}
                      </a>
                    </div>
                    {/* <!--end::Info--> */}
                  </div>
                  {/* <!--end::User--> */}
                </div>
                <div className="d-flex flex-wrap flex-stack">
                  <div className="d-flex flex-column flex-grow-1 pe-8">
                    <div className="d-flex flex-wrap">
                      <div className="text-center border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                        <div className="d-flex align-items-center justify-content-center">
                          <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                            <i className="las la-certificate fs-1 text-primary"></i>
                          </span>
                          <div className="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value="4500" data-kt-countup-prefix="$" data-kt-initialized="1">{detailSekolah?.details?.akreditasi}</div>
                        </div>
                        <div className="fw-semibold fs-6 text-gray-400">Akreditasi</div>
                      </div>
                      <div className="text-center border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                        {/* <!--begin::Number--> */}
                        <div className="d-flex align-items-center justify-content-center">
                          {/* <!--begin::Svg Icon | path: icons/duotune/arrows/arr065.svg--> */}
                          <span className="svg-icon svg-icon-3 svg-icon-danger me-2">
                            <i className="las la-chalkboard-teacher fs-1 text-primary"></i>
                          </span>
                          {/* <!--end::Svg Icon--> */}
                          <div className="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value="80" data-kt-initialized="1">{totalGuru}</div>
                        </div>
                        {/* <!--end::Number--> */}
                        {/* <!--begin::Label--> */}
                        <div className="fw-semibold fs-6 text-gray-400">Total guru</div>
                        {/* <!--end::Label--> */}
                      </div>
                      <div className="text-center border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                        <div className="d-flex align-items-center justify-content-center">
                          <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                            <i className="las la-graduation-cap fs-1 text-primary"></i>
                          </span>
                          <div className="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value="60" data-kt-countup-prefix="%" data-kt-initialized="1">{totalSiswa}</div>
                        </div>
                        <div className="fw-semibold fs-6 text-gray-400">Total Siswa</div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex my-4">
                    <a href="#" className="btn btn-sm btn-light me-2" id="kt_user_follow_button">
                      <span className="svg-icon svg-icon-3 d-none">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"></path>
                          <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"></path>
                        </svg>
                      </span>
                      <span className="indicator-label">Website</span>
                    </a>
                    <a href="#" className="btn btn-sm btn-primary me-2" data-bs-toggle="modal" data-bs-target="#kt_modal_offer_a_deal">Kontak</a>
                    {/* <!--begin::Menu--> */}
                    <div className="me-0 position-relative">
                      <button
                        className={`btn btn-sm btn-icon btn-bg-light btn-active-color-primary ${menu && 'show menu-dropdown'}`}
                        onClick={() => hanldeDisplayMenu()}
                      >
                        <i className="bi bi-three-dots fs-3"></i>
                      </button>
                      <div className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3 ${menu && 'show'}`} data-kt-menu="true" 
                        style={{
                          zIndex: 2,
                          position: 'absolute',
                          inset: 'auto 0px 0px auto',
                          margin: '0px',
                          transform: "translate(0, 100px)"
                        }} data-popper-placement="top-end">
                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">PPDB</a>
                        </div>
                        
                        <div className="menu-item px-3">
                          <a href="#" className="menu-link flex-stack px-3">Edit Data Sekolah</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex overflow-auto h-55px mt-10'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname ===
                        `/sekolah/profile/${location.pathname.split('/').reverse()[0]}` && 'active')
                    }
                    to={`/sekolah/profile/${location.pathname.split('/').reverse()[0]}`}
                  >
                    Profil
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname ===
                        `/sekolah/dana-taawun/` &&
                        'active')
                    }
                    to={`/sekolah/dana-taawun/`}
                  >
                    Dana Ta'awun
                  </Link>
                </li>
                {/*<li className='nav-item'>*/}
                {/*  <Link*/}
                {/*    className={*/}
                {/*      `nav-link text-active-primary me-6 ` +*/}
                {/*      (location.pathname ===*/}
                {/*        `/sekolah/akademik/${location.pathname.split('/').reverse()[0]}` &&*/}
                {/*        'active')*/}
                {/*    }*/}
                {/*    to={`/sekolah/akademik/${location.pathname.split('/').reverse()[0]}`}*/}
                {/*  >*/}
                {/*    Akademik*/}
                {/*  </Link>*/}
                {/*</li>*/}
                {/*<li className='nav-item'>*/}
                {/*  <Link*/}
                {/*    className={*/}
                {/*      `nav-link text-active-primary me-6 ` +*/}
                {/*      (location.pathname ===*/}
                {/*        `/sekolah/ekskul-mulok/${location.pathname.split('/').reverse()[0]}` &&*/}
                {/*        'active')*/}
                {/*    }*/}
                {/*    to={`/sekolah/ekskul-mulok/${location.pathname.split('/').reverse()[0]}`}*/}
                {/*  >*/}
                {/*    Ekskul & Mulok*/}
                {/*  </Link>*/}
                {/*</li>*/}
                {/*<li className='nav-item'>*/}
                {/*  <Link*/}
                {/*    className={*/}
                {/*      `nav-link text-active-primary me-6 ` +*/}
                {/*      (location.pathname ===*/}
                {/*        `/sekolah/sanitasi-tik/${location.pathname.split('/').reverse()[0]}` &&*/}
                {/*        'active')*/}
                {/*    }*/}
                {/*    to={`/sekolah/sanitasi-tik/${location.pathname.split('/').reverse()[0]}`}*/}
                {/*  >*/}
                {/*    Sanitasi & TIK*/}
                {/*  </Link>*/}
                {/*</li>*/}
                {/*<li className='nav-item'>*/}
                {/*  <Link*/}
                {/*    className={*/}
                {/*      `nav-link text-active-primary me-6 ` +*/}
                {/*      (location.pathname ===*/}
                {/*        `/sekolah/koneksi/${location.pathname.split('/').reverse()[0]}` && 'active')*/}
                {/*    }*/}
                {/*    to={`/sekolah/koneksi/${location.pathname.split('/').reverse()[0]}`}*/}
                {/*  >*/}
                {/*    Koneksi*/}
                {/*  </Link>*/}
                {/*</li>*/}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ProfileHeader
