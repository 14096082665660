import React, {useEffect} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
// import {useLocation} from 'react-router-dom'
// import {useDispatch} from 'react-redux'
import BantuanPage from '../../modules/bantuan/BantuanPage'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Bantuan',
    path: '/bantuan',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export function Bantuan() {
  //   const location = useLocation()
  //   const dispatch = useDispatch()

  useEffect(() => {}, [])

  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Sekolah/Madrasah Muhammadiyah</PageTitle>
      <BantuanPage />
    </>
  )
}
