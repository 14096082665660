import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {StatisticsWidget1} from '../../../_metronic/partials/widgets'

const dataDummy = [
  {
    title: 'Masalah Pencarian Sekolah ?',
    description: 'isi NPSN Pada form pencarian, lalu klik kirim tunggu beberapa saat maka akan muncul sekolah yang anda cari',
  },
  {
    title: 'Masalah download File ?',
    description: 'Klik icon download file dan pastikan anda memiliki ruang kosong ketika ingin mendownload',
  },
  {
    title: 'Cara Menuju profile sekolah ?',
    description: 'Klik pin marker pada map dashboard maka akan muncul popup lalu klik NPSN sekolah maka anda akan menuju ke profile sekolah tersebut'
  }
]

export function HalamanBantuan() {
  const location = useLocation()
  const dispatch = useDispatch()
  const [dataModal, setDataModal] = useState({
    title: '',
    description: ''
  })

  const onHandleClick = (title = '', description= '') => {
    setDataModal({
      title: title,
      description: description
    })
  }

  useEffect(() => {}, [])

  return (
    <>
      <div className='row g-5 g-xl-8'>
        {
          dataDummy && dataDummy.length > 0
          ? dataDummy.map((item, key) => (
            <div className='col-xl-4' key={key}>
              <StatisticsWidget1
                className='card-xl-stretch mb-xl-8'
                image='abstract-4.svg'
                title={item.title}
                time=''
                description={item.description}
                onHandleClick={(title: string | undefined, description: string | undefined) => onHandleClick(title, description)}
              />
            </div>
          )): null
        }
      </div>
      <div className="modal fade pt-20" tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog mt-20">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 py-2 d-flex justify-content-end mb-0 pb-0">
              <div
                className="btn btn-icon btn-sm ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon"
                />
              </div>
            </div>
            <div className="modal-body pt-0">

              <h3 className='mb-5'>{dataModal.title}</h3>
              <p>{dataModal.description}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
