import axios from "axios";

export const getDatatableEducationStep = () => {
  return function (dispatch) {
    // dispatch({ type: "LOADING", payload: true });
    const API = process.env.REACT_APP_API_URL_NEW;
    const TOKEN = process.env.REACT_APP_TOKEN_NEW;
    const payload = {
      page:1,
      limit:100,
      order_by:"",
      short:"asc",
      search:""
    }

    axios
      .post(`${API}/yayasan/dt-category`, payload, {
        headers: {
          key: TOKEN,
          "Accept": "application/json",
        }
      })
      .then(({ data }) => {
        if (data.status !== false) {
          dispatch({ type: "DATATABLE_EDUCATION_STEP", payload: data.data });
          // dispatch({ type: "LOADING", payload: false });
          const dataTable = {
            data: data.data,
            date: new Date()
          }
          // localStorage.setItem("dataTableEducationStep", JSON.stringify(dataTable));
        }
      })
      .catch((err) => {
        dispatch({ type: "DATATABLE_EDUCATION_STEP", payload: [] });
        // dispatch({ type: "LOADING", payload: false });
        // localStorage.setItem("dataTableEducationStep", JSON.stringify([]));
      });
  };
}

export const getTotalEducationStep = () => {
  return function (dispatch) {
    // dispatch({ type: "LOADING", payload: true });
    const API = process.env.REACT_APP_API_URL_NEW;
    const TOKEN = process.env.REACT_APP_TOKEN_NEW;

    axios
      .post(`${API}/yayasan/school-by-category`, {}, {
        headers: {
          key: TOKEN,
          "Accept": "application/json",
          "Content-Type": "application/json",
        }
      })
      .then(({ data }) => {
        if (data.status == 200) {
          dispatch({ type: "TOTAL_EDUCATION_STEP", payload: data.data });
          // dispatch({ type: "LOADING", payload: false });
          // localStorage.setItem("totalEducationStep", JSON.stringify(data.data));
        }
      })
      .catch((err) => {
        dispatch({ type: "TOTAL_EDUCATION_STEP", payload: [] });
        // dispatch({ type: "LOADING", payload: false });
        // localStorage.setItem("totalEducationStep", JSON.stringify([]));
      });
  };
}