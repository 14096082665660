import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'

const columnsatas = [
  {
    id: 'no',
    rowspan: '2',
    colspan: '1',
    align: 'left',
    label: 'No',
    minWidth: 50,
  },
  {
    id: 'wilayah',
    rowspan: '2',
    colspan: '1',
    align: 'left',
    label: 'Wilayah',
    minWidth: 200,
  },
  {
    id: 'total',
    label: 'Total',
    rowspan: '1',
    colspan: '3',
    align: 'center',
    minWidth: 50,
  },
  {
    id: 'tk',
    label: 'TK',
    rowspan: '1',
    colspan: '3',
    align: 'center',
    minWidth: 50,
  },
  {
    id: 'kb',
    label: 'KB',
    rowspan: '1',
    colspan: '3',
    align: 'center',
    minWidth: 50,
  },
  {
    id: 'tpa',
    label: 'TPA',
    rowspan: '1',
    colspan: '3',
    align: 'center',
    minWidth: 50,
  },
  {
    id: 'sps',
    label: 'SPS',
    rowspan: '1',
    colspan: '3',
    align: 'center',
    minWidth: 50,
  },
  {
    id: 'pkbm',
    label: 'PKBM',
    rowspan: '1',
    colspan: '3',
    align: 'center',
    minWidth: 50,
  },
  {
    id: 'skb',
    label: 'SKB',
    rowspan: '1',
    colspan: '3',
    align: 'center',
    minWidth: 50,
  },
  {
    id: 'sd',
    label: 'SD',
    rowspan: '1',
    colspan: '3',
    align: 'center',
    minWidth: 50,
  },
  {
    id: 'smp',
    label: 'SMP',
    rowspan: '1',
    colspan: '3',
    align: 'center',
    minWidth: 50,
  },
  {
    id: 'sma',
    label: 'SMA',
    rowspan: '1',
    colspan: '3',
    align: 'center',
    minWidth: 50,
  },
  {
    id: 'smk',
    label: 'SMK',
    rowspan: '1',
    colspan: '3',
    align: 'center',
    minWidth: 50,
  },
  {
    id: 'slb',
    label: 'SLB',
    rowspan: '1',
    colspan: '3',
    align: 'center',
    minWidth: 50,
  },
]
const columnsbawah = [
  {
    id: 'jmlt',
    label: 'JML',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
  },
  {
    id: 'nt',
    label: 'N',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'st',
    label: 'S',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'jmltk',
    label: 'JML',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
  },
  {
    id: 'ntk',
    label: 'N',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'stk',
    label: 'S',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'jmlkb',
    label: 'JML',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
  },
  {
    id: 'nkb',
    label: 'N',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'skb',
    label: 'S',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'jmltpa',
    label: 'JML',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
  },
  {
    id: 'ntpa',
    label: 'N',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'stpa',
    label: 'S',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'jmlsps',
    label: 'JML',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
  },
  {
    id: 'nsps',
    label: 'N',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'ssps',
    label: 'S',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'jmlpkbm',
    label: 'JML',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
  },
  {
    id: 'npkbm',
    label: 'N',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'spkbm',
    label: 'S',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'jmlskb',
    label: 'JML',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
  },
  {
    id: 'nskb',
    label: 'N',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'sskb',
    label: 'S',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'jmlsd',
    label: 'JML',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
  },
  {
    id: 'nsd',
    label: 'N',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'ssd',
    label: 'S',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'jmlsmp',
    label: 'JML',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
  },
  {
    id: 'nsmp',
    label: 'N',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'ssmp',
    label: 'S',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'jmlsma',
    label: 'JML',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
  },
  {
    id: 'nsma',
    label: 'N',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'ssma',
    label: 'S',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'jmlsmk',
    label: 'JML',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
  },
  {
    id: 'nsmk',
    label: 'N',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'ssmk',
    label: 'S',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'jmlslb',
    label: 'JML',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
  },
  {
    id: 'nslb',
    label: 'N',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'sslb',
    label: 'S',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
]
const columnsdata = [
  {
    id: 'no',
    label: 'No',
    minWidth: 50,
  },
  {
    id: 'wilayah',
    label: 'Wilayah',
    minWidth: 50,
  },
  {
    id: 'jmlt',
    label: 'JML',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
  },
  {
    id: 'nt',
    label: 'N',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'st',
    label: 'S',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'jmltk',
    label: 'JML',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
  },
  {
    id: 'ntk',
    label: 'N',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'stk',
    label: 'S',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'jmlkb',
    label: 'JML',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
  },
  {
    id: 'nkb',
    label: 'N',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'skb',
    label: 'S',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'jmltpa',
    label: 'JML',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
  },
  {
    id: 'ntpa',
    label: 'N',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'stpa',
    label: 'S',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'jmlsps',
    label: 'JML',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
  },
  {
    id: 'nsps',
    label: 'N',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'ssps',
    label: 'S',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'jmlpkbm',
    label: 'JML',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
  },
  {
    id: 'npkbm',
    label: 'N',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'spkbm',
    label: 'S',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'jmlskb',
    label: 'JML',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
  },
  {
    id: 'nskb',
    label: 'N',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'sskb',
    label: 'S',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'jmlsd',
    label: 'JML',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
  },
  {
    id: 'nsd',
    label: 'N',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'ssd',
    label: 'S',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'jmlsmp',
    label: 'JML',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
  },
  {
    id: 'nsmp',
    label: 'N',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'ssmp',
    label: 'S',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'jmlsma',
    label: 'JML',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
  },
  {
    id: 'nsma',
    label: 'N',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'ssma',
    label: 'S',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'jmlsmk',
    label: 'JML',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
  },
  {
    id: 'nsmk',
    label: 'N',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'ssmk',
    label: 'S',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'jmlslb',
    label: 'JML',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
  },
  {
    id: 'nslb',
    label: 'N',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'sslb',
    label: 'S',
    rowspan: '1',
    colspan: '1',
    minWidth: 50,
    align: 'left',
  },
]

function createData(
  no,
  wilayah,
  jmlt,
  nt,
  st,
  jmltk,
  ntk,
  stk,
  jmlkb,
  nkb,
  skb,
  jmltpa,
  ntpa,
  stpa,
  jmlsps,
  nsps,
  ssps,
  jmlpkbm,
  npkbm,
  spkbm,
  jmlskb,
  nskb,
  sskb,
  jmlsd,
  nsd,
  ssd,
  jmlsmp,
  nsmp,
  ssmp,
  jmlsma,
  nsma,
  ssma,
  jmlsmk,
  nsmk,
  ssmk,
  jmlslb,
  nslb,
  sslb
) {
  // const name = code;
  return {
    no,
    wilayah,
    jmlt,
    nt,
    st,
    jmltk,
    ntk,
    stk,
    jmlkb,
    nkb,
    skb,
    jmltpa,
    ntpa,
    stpa,
    jmlsps,
    nsps,
    ssps,
    jmlpkbm,
    npkbm,
    spkbm,
    jmlskb,
    nskb,
    sskb,
    jmlsd,
    nsd,
    ssd,
    jmlsmp,
    nsmp,
    ssmp,
    jmlsma,
    nsma,
    ssma,
    jmlsmk,
    nsmk,
    ssmk,
    jmlslb,
    nslb,
    sslb,
  }
}

const rows = [
  createData(
    1,
    'Prov. Jawa Barat',
    23.87,
    10.709,
    13.161,
    2.979,
    197,
    2.782,
    5.326,
    159,
    5.167,
    69,
    0,
    69,
    458,
    1,
    457,
    468,
    0,
    468,
    22,
    22,
    0,
    9.788,
    8.279,
    1.509,
    2.648,
    1.328,
    1.32,
    1.076,
    427,
    649,
    978,
    267,
    711,
    58,
    29,
    29
  ),
  createData(
    2,
    'Prov. Jawa Timur',
    23.87,
    10.709,
    13.161,
    2.979,
    197,
    2.782,
    5.326,
    159,
    5.167,
    69,
    0,
    69,
    458,
    1,
    457,
    468,
    0,
    468,
    22,
    22,
    0,
    9.788,
    8.279,
    1.509,
    2.648,
    1.328,
    1.32,
    1.076,
    427,
    649,
    978,
    267,
    711,
    58,
    29,
    29
  ),
  createData(
    3,
    'Prov. Jawa Tengah',
    23.87,
    10.709,
    13.161,
    2.979,
    197,
    2.782,
    5.326,
    159,
    5.167,
    69,
    0,
    69,
    458,
    1,
    457,
    468,
    0,
    468,
    22,
    22,
    0,
    9.788,
    8.279,
    1.509,
    2.648,
    1.328,
    1.32,
    1.076,
    427,
    649,
    978,
    267,
    711,
    58,
    29,
    29
  ),
  createData(
    4,
    'Prov. Sumatera Utara',
    23.87,
    10.709,
    13.161,
    2.979,
    197,
    2.782,
    5.326,
    159,
    5.167,
    69,
    0,
    69,
    458,
    1,
    457,
    468,
    0,
    468,
    22,
    22,
    0,
    9.788,
    8.279,
    1.509,
    2.648,
    1.328,
    1.32,
    1.076,
    427,
    649,
    978,
    267,
    711,
    58,
    29,
    29
  ),
  createData(
    5,
    'Prov. Sulawesi Selatan',
    23.87,
    10.709,
    13.161,
    2.979,
    197,
    2.782,
    5.326,
    159,
    5.167,
    69,
    0,
    69,
    458,
    1,
    457,
    468,
    0,
    468,
    22,
    22,
    0,
    9.788,
    8.279,
    1.509,
    2.648,
    1.328,
    1.32,
    1.076,
    427,
    649,
    978,
    267,
    711,
    58,
    29,
    29
  ),
]

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
})

export default function DataTableX() {
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader={true} aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columnsatas.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  rowSpan={column.rowspan}
                  colSpan={column.colspan}
                  style={{minWidth: column.minWidth}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              {columnsbawah.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  rowSpan={column.rowspan}
                  colSpan={column.colspan}
                  style={{minWidth: column.minWidth}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role='row' tabIndex={0} key={row.name}>
                  {columnsdata.map((column) => {
                    const value = row[column.id]
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
