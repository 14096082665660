import React, {useState} from 'react'
import DataTableX from './components/DataTableX'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'

const currencies = [
  {
    value: '1',
    label: 'Semester 2020/2021 Genap',
  },
  {
    value: '2',
    label: 'Semester 2020/2021 Ganjil',
  },
  {
    value: '3',
    label: 'Semester 2020/2020 Genap',
  },
  {
    value: '4',
    label: 'Semester 2020/2020 Ganjil',
  },
]

function HalamanDagu() {
  const [currency, setCurrency] = useState('')

  const handleChange = (event) => {
    const target = event.target
    const value = target.value
    setCurrency(value)
  }
  return (
    <div className='row'>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header'>
          <div className='col'>
            <h2 style={{paddingTop: '25px'}}>Data Pokok</h2>
          </div>
          <div className='col'>
            <form className='form form-label-right pt-5'>
              <div className='form-group row'>
                <select
                  className='form-control'
                  value={currency}
                  name='currency'
                  onChange={handleChange}
                  variant='outlined'
                >
                  <option value=''>{'- Pilih Satu:'}</option>
                  {currencies.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </form>
          </div>
        </div>
        <div className='card-body pt-9'>
          <form className='form form-label-right'>
            <div className='form-group row'>
              <div className='col-lg-2'>
                <form className='w-100 position-relative' autoComplete='off'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen021.svg'
                    className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y'
                  />

                  <input
                    type='text'
                    className='form-control form-control-solid px-15'
                    name='search'
                    placeholder='Search'
                  />
                </form>
                <small className='form-text text-muted'>
                  <b>Search</b> in all fields
                </small>
              </div>
              <div className='col-lg-8'>
                <a
                  href='#'
                  style={{backgroundColor: '#0081B4', marginRight: '4px'}}
                  className='btn btn-primary font-weight-bolder font-size-sm'
                >
                  Copy Table
                </a>
                <a
                  href='#'
                  style={{backgroundColor: '#0081B4', marginRight: '4px'}}
                  className='btn btn-primary font-weight-bolder font-size-sm'
                >
                  Unduh Excel
                </a>
                <a
                  href='#'
                  style={{backgroundColor: '#0081B4', marginRight: '4px'}}
                  className='btn btn-primary font-weight-bolder font-size-sm'
                >
                  Cetak Table
                </a>
              </div>
            </div>
          </form>
          <DataTableX />
        </div>
      </div>
    </div>
  )
}

export default HalamanDagu
