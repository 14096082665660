const initialState = {
  allDataMap: [],
  filterDataMap: [],
}

const allDataMapReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ALL_DATA_MAP':
      return {...state, allDataMap: action.payload}
    case 'FILTER_DATA_MAP':
      return {...state, filterDataMap: action.payload}
    default:
      return state
  }
}

export default allDataMapReducer
