const initialState = {
  allYayasan: {
    page: 1,
    total_pages: 0,
    data: [],
  },
  detailYayasan: []
}

const vervalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ALL_YAYASAN':
      return {...state, allYayasan: action.payload}
    case 'DETAIL_YAYASAN':
      return {...state, detailYayasan: action.payload}
    default:
      return state
  }
}

export default vervalReducer