import clsx from 'clsx'
import React, {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  HeaderLoginMenu,
  QuickLinks,
  Search,
} from '../../../partials'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        {/* Search */}
        {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
          <Search />
        </div> */}

        {/* begin::User */}
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          {!localStorage.access_token ? (
            <>
              <div
                className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
                data-kt-menu-trigger='click'
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='bottom'
              >
                <i className='fas fa-bars' style={{color: 'white'}}></i>
              </div>
              <HeaderLoginMenu />
            </>
          ) : (
            <>
              <div
                className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
                data-kt-menu-trigger='click'
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='bottom'
              >
                <img
                  className='h-25px w-25px rounded'
                  src={toAbsoluteUrl('/media/avatars/blank.png')}
                  alt='metronic'
                />
              </div>
              <HeaderUserMenu />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export {Topbar}
