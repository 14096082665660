/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useCallback} from 'react'
import {KTSVG} from '../../../helpers'
import $ from 'jquery'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import XLSX from 'xlsx'
import {useDropzone} from 'react-dropzone'
import {useSelector, useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import getDataLevel from '../../../../setup/redux/actions/getDataLevel'
import {RootState} from '../../../../setup'
import postGuru from '../../../../setup/redux/actions/postGuru'
import {useLocation} from 'react-router-dom'

function TablesWidget13() {
  const location = useLocation()
  const dispatch = useDispatch()
  const schId = location.pathname.split('/').reverse()[0]

  const [DataGuru, setDataGuru] = useState([] as any)
  const [IndexDataGuru, setIndexDataGuru] = useState(0)
  const [Nuptk, setNuptk] = useState(0)
  const [SchId, setSchId] = useState(schId)
  const [NamaGuru, setNamaGuru] = useState('')
  const [Jabatan, setJabatan] = useState('')
  const [TanggalMasuk, setTanggalMasuk] = useState('')
  const [HonorDiterima, setHonorDiterima] = useState(0)
  const [Pendidikan, setPendidikan] = useState('')
  const [StatusPegawai, setStatusPegawai] = useState('')
  const [TunjanganSertifikasi, setTunjanganSertifikasi] = useState('')
  const [IndexEdit, setIndexEdit] = useState(0)
  const [FileNames, setFileNames] = useState<File>() // also tried <string | Blob>
  const [FileExcel, setFileExcel] = useState([])
  const allDataLevel = useSelector((state: RootState) => state.allDataLevelReducer.allDataLevel)
  const respPostGuru = useSelector((state: RootState) => state.postGuruReducer.respGuru)
  const isLoading = useSelector((state: RootState) => state.loading.loading)

  const onDrop = useCallback((acceptedFiles) => {
    setFileNames(acceptedFiles.map((file: {name: any}) => file.name))
    acceptedFiles.forEach((file: Blob) => {
      const reader = new FileReader()
      const rABS = !!reader.readAsBinaryString // !! converts object to boolean
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = (e) => {
        // Do what you want with the file contents
        var bstr = e.target!.result
        var workbook = XLSX.read(bstr, {type: rABS ? 'binary' : 'array'})
        var sheet_name_list = workbook.SheetNames[0]
        var jsonFromExcel = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list], {
          raw: false,
          dateNF: 'MM-DD-YYYY',
          header: 1,
          defval: '',
        })
        setFileExcel(jsonFromExcel as any)
      }
      if (rABS) reader.readAsBinaryString(file)
      else reader.readAsArrayBuffer(file)
    })
  }, [])

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({onDrop})

  const MySwal = withReactContent(Swal)

  const submitForm = () => {
    if (
      Nuptk === 0 ||
      NamaGuru === '' ||
      Jabatan === '' ||
      TanggalMasuk === '' ||
      HonorDiterima === 0 ||
      Pendidikan === '' ||
      StatusPegawai === '' ||
      TunjanganSertifikasi === ''
    ) {
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Silahkan Lengkapi Form Data Dengan Benar',
      })
    } else {
      setIndexDataGuru(IndexDataGuru + 1)
      const tempDataGuru = DataGuru
      const pendName = allDataLevel.filter((item1: {id: any}) => item1.id == Pendidikan)

      tempDataGuru.unshift({
        indexGuru: IndexDataGuru,
        schId: schId,
        nuptk: Nuptk,
        namaGuru: NamaGuru,
        jabatan: Jabatan,
        tanggalMasuk: TanggalMasuk,
        honorDiterima: HonorDiterima,
        pendidikan: Pendidikan,
        pendidikanName: pendName[0].name,
        statusPegawai: StatusPegawai,
        tunjanganSertifikasi: TunjanganSertifikasi,
      })
      setDataGuru(tempDataGuru)
    }
    resetForm()
  }

  const submitFormEdit = () => {
    if (
      Nuptk === 0 ||
      NamaGuru === '' ||
      Jabatan === '' ||
      TanggalMasuk === '' ||
      HonorDiterima === 0 ||
      Pendidikan === '' ||
      StatusPegawai === '' ||
      TunjanganSertifikasi === ''
    ) {
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Silahkan Lengkapi Form Data Dengan Benar',
      })
    } else {
      const EditDataGuru = DataGuru
      const pendName = allDataLevel.filter((item1: {id: any}) => item1.id == Pendidikan)

      EditDataGuru.forEach((_guru: any) => {
        if (_guru.indexGuru === IndexEdit) {
          _guru.schId = SchId
          _guru.nuptk = Nuptk
          _guru.namaGuru = NamaGuru
          _guru.jabatan = Jabatan
          _guru.tanggalMasuk = TanggalMasuk
          _guru.honorDiterima = HonorDiterima
          _guru.pendidikan = Pendidikan
          _guru.pendidikanName = pendName[0].name
          _guru.statusPegawai = StatusPegawai
          _guru.tunjanganSertifikasi = TunjanganSertifikasi
        }
      })
      setDataGuru(EditDataGuru)
    }
    resetForm()
  }

  const submitAllData = () => {
    MySwal.fire({
      text: 'Kamu Yakin ingin menambahkan data Ini? mohon koreksi sebelum mengirim data',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Batal',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(postGuru(DataGuru))
      }
    })
  }

  const resetForm = () => {
    setNuptk(0)
    setNamaGuru('')
    setSchId('')
    setJabatan('')
    setTanggalMasuk('')
    setHonorDiterima(0)
    setPendidikan('')
    setStatusPegawai('')
    setTunjanganSertifikasi('')
    $('#pendidikan').prop('selectedIndex', 0)
    $('#statusPegawai').prop('selectedIndex', 0)
    $('#tunjanganSertifikasi').prop('selectedIndex', 0)
  }

  /* Fungsi formatRupiah */
  const formatRupiah = (angka: number, prefix: string) => {
    var number_string = angka.toString(),
      split = number_string.split(','),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi)

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    if (ribuan) {
      let separator = sisa ? '.' : ''
      rupiah += separator + ribuan.join('.')
    }

    rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah
    return prefix == undefined ? rupiah : rupiah ? 'Rp. ' + rupiah : ''
  }

  // Fungsi Format Hari
  const formatHari = (tanggal: Date) => {
    let myDays = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', "Jum'at", 'Sabtu']
    let date = new Date(tanggal)
    let thisDay = date.getDay()
    let nowDay = myDays[thisDay]
    return nowDay
  }

  const exportFile = () => {
    let op = [
      'NUPTK',
      'Nama Guru',
      'Jabatan',
      'Tgl Masuk',
      'Pendidikan',
      'Status Pegawai',
      'Tunjangan Sertifikasi',
      'Honor Diterima',
    ]
    let TableDataGuru = [op]
    const wb = XLSX.utils.book_new()
    const wsAll = XLSX.utils.aoa_to_sheet(TableDataGuru)
    XLSX.utils.book_append_sheet(wb, wsAll)
    XLSX.writeFile(wb, 'Format Import Soal Table Data Guru.xlsx')
  }

  const importDataGuru = () => {
    let tempFileExcel = FileExcel
    let tempData = []
    for (let i = 1; i < tempFileExcel.length; i++) {
      tempData.push({
        indexGuru: i - 1,
        schId: schId,
        nuptk: tempFileExcel[i][0],
        namaGuru: tempFileExcel[i][1],
        jabatan: tempFileExcel[i][2],
        tanggalMasuk: tempFileExcel[i][3],
        pendidikan: tempFileExcel[i][4],
        statusPegawai: tempFileExcel[i][5],
        tunjanganSertifikasi: tempFileExcel[i][6],
        honorDiterima: tempFileExcel[i][7],
      })
    }
    console.log(tempData)
    setDataGuru(tempData)
  }

  const deleteDataGuru = (index: number) => {
    MySwal.fire({
      text: 'Kamu Yakin ingin Menghapus Ini?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Hapus',
      cancelButtonText: 'Batal',
    }).then((result) => {
      if (result.isConfirmed) {
        var arr = DataGuru
        arr = arr.filter(function (item: any) {
          return item.indexGuru !== index
        })
        setDataGuru(arr)
        MySwal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Data Guru Berhasil Di Hapus',
          showConfirmButton: false,
          timer: 1000,
        })
      }
    })
  }

  const editDataGuru = (_guru: any) => {
    setIndexEdit(_guru.indexGuru)
    setNuptk(Number(_guru.nuptk))
    setSchId(schId)
    setNamaGuru(_guru.namaGuru.toString())
    setJabatan(_guru.jabatan.toString())
    setTanggalMasuk(_guru.tanggalMasuk.toString())
    setPendidikan(_guru.pendidikan.toString())
    setStatusPegawai(_guru.statusPegawai.toString())
    setTunjanganSertifikasi(_guru.tunjanganSertifikasi.toString())
    setHonorDiterima(Number(_guru.honorDiterima))
    $('#editNuptk').val(Number(_guru.nuptk))
    $('#editNamaGuru').val(_guru.namaGuru.toString())
    $('#editJabatan').val(_guru.jabatan.toString())
    $('#editTanggalMasuk').val(_guru.tanggalMasuk.toString())
    $('#editPendidikan').val(_guru.pendidikan.toString().toLowerCase())
    $('#editStatusPegawai').val(_guru.statusPegawai.toString().toLowerCase())
    $('#editTunjanganSertifikasi').val(_guru.tunjanganSertifikasi.toString().toLowerCase())
    $('#editHonorDiterima').val(Number(_guru.honorDiterima))
  }

  useEffect(() => {
    dispatch(getDataLevel())
    resetForm()
  }, [dispatch])

  return (
    <div className='card mb-5 mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>
            Table Data Guru Yang Belum Di Simpan
          </span>
          <span className='text-muted mt-1 fw-bold fs-7'>Guru Dan Pegawai</span>
        </h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-light-primary me-3'
            data-bs-toggle='modal'
            data-bs-target='#importModal'
          >
            Import
          </button>
          {/* MODAL IMPORT */}
          <div className='modal fade' tabIndex={-1} id='importModal'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Import Data Guru</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body'>
                  <button
                    type='button'
                    className='btn btn-sm btn-light-primary ml-5'
                    onClick={exportFile}
                  >
                    Generate Excel Format
                  </button>
                  <div className='form-group row'>
                    <section className='mt-5'>
                      <div
                        style={{
                          flex: '1',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: '20px',
                          borderWidth: '2px',
                          borderRadius: '2px',
                          borderColor: '#eeeeee',
                          borderStyle: 'dashed',
                          backgroundColor: '#fafafa',
                          color: '#bdbdbd',
                          outline: 'none',
                          transition: 'border .24s ease-in-out',
                        }}
                        {...getRootProps({className: 'dropzone'})}
                      >
                        <input {...getInputProps()} />
                        <p className='m-0'>
                          Drag 'n' drop some files here, or click to select files
                        </p>
                      </div>
                      <aside className='mt-3'>
                        <h4>Files</h4>
                        <ul>
                          {acceptedFiles.map((file) => (
                            <li>
                              {(file as any).path} - {file.size} bytes
                            </li>
                          ))}
                        </ul>
                      </aside>
                    </section>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                    Tutup
                  </button>
                  <button
                    onClick={importDataGuru}
                    type='button'
                    className='btn btn-primary'
                    data-bs-dismiss='modal'
                  >
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* END MODAL IMPORT */}

          {/* Modal BTN*/}
          <button
            type='button'
            className='btn btn-sm btn-primary me-3'
            data-bs-toggle='modal'
            data-bs-target='#addModal'
            onClick={resetForm}
          >
            Tambah
          </button>

          {/* MODAL CONTENT */}
          <div className='modal fade' tabIndex={-1} id='addModal'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Form Data Guru</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body'>
                  <div className='row'>
                    <div className='col-6 mb-5'>
                      <label className='form-label'>NUPTK</label>
                      <input
                        onChange={(e) => setNuptk(Number(e.target.value))}
                        value={Nuptk === 0 ? '' : Nuptk}
                        type='number'
                        max='9999999999999999'
                        min='0'
                        className='form-control'
                        placeholder='- NUPTK'
                      />
                    </div>
                    <div className='col-6 mb-5'>
                      <label className='form-label'>Nama Guru</label>
                      <input
                        onChange={(e) => setNamaGuru(e.target.value)}
                        value={NamaGuru === '' ? '' : NamaGuru}
                        type='text'
                        className='form-control'
                        placeholder='- Nama Guru'
                      />
                    </div>
                    <div className='col-6 mb-5'>
                      <label className='form-label'>Jabatan</label>
                      <input
                        onChange={(e) => setJabatan(e.target.value)}
                        value={Jabatan === '' ? '' : Jabatan}
                        type='text'
                        maxLength={20}
                        className='form-control'
                        placeholder='- Jabatan'
                      />
                    </div>
                    <div className='col-6 mb-5'>
                      <label className='form-label'>Tanggal Masuk</label>
                      <input
                        onChange={(e) => setTanggalMasuk(e.target.value)}
                        value={TanggalMasuk === '' ? '' : TanggalMasuk}
                        type='date'
                        className='form-control'
                      />
                    </div>
                    <div className='col-6 mb-5'>
                      <label className='form-label'>Pendidikan</label>
                      <select
                        id='pendidikan'
                        className='form-select'
                        onChange={(e) => setPendidikan(e.target.value)}
                      >
                        <option value=''>- Pilih Pendidikan</option>
                        {allDataLevel.length === 0 ? (
                          <option value=''>- Data jenjang kosong</option>
                        ) : (
                          allDataLevel.map((item: {id: boolean; name: string}) => {
                            return <option value={`${item.id}`}>{item.name}</option>
                          })
                        )}
                      </select>
                    </div>
                    <div className='col-6 mb-5'>
                      <label className='form-label'>Status Pegawai</label>
                      <select
                        className='form-select'
                        id='statusPegawai'
                        onChange={(e) => setStatusPegawai(e.target.value)}
                      >
                        <option>- Pilih Status Pegawai</option>
                        <option value='honorer'>Honorer</option>
                        <option value='pns'>PNS</option>
                        <option value='lainnya'>Lainnya</option>
                      </select>
                    </div>
                    <div className='col-6 mb-5'>
                      <label className='form-label'>Tunjangan Sertifikasi</label>
                      <select
                        className='form-select'
                        id='tunjanganSertifikasi'
                        onChange={(e) => setTunjanganSertifikasi(e.target.value)}
                      >
                        <option>- Pilih Tunjangan Sertifikasi</option>
                        <option value='ya'>Ya</option>
                        <option value='tidak'>Tidak</option>
                      </select>
                    </div>
                    <div className='col-6 mb-5'>
                      <label className='form-label'>Honor Diterima</label>
                      <input
                        onChange={(e) => setHonorDiterima(Number(e.target.value))}
                        value={HonorDiterima === 0 ? '' : HonorDiterima}
                        type='number'
                        min='0'
                        max='100000000'
                        className='form-control'
                        placeholder='- Honor Diterima'
                      />
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                    Tutup
                  </button>
                  <button
                    onClick={submitForm}
                    type='button'
                    className='btn btn-primary'
                    data-bs-dismiss='modal'
                  >
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* END MODAL CONTENT */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-13-check'
                    />
                  </div>
                </th>
                <th className='min-w-140px'>NUPTK</th>
                <th className='min-w-140px'>Nama Guru</th>
                <th className='min-w-140px'>Jabatan</th>
                <th className='min-w-100px'>Tgl Masuk</th>
                <th className='min-w-100px'>Pendidikan</th>
                <th className='min-w-100px'>Status Pegawai</th>
                <th className='min-w-100px'>Tunjangan Sertifikasi</th>
                <th className='min-w-120px'>Honor Diterima</th>
                <th className='min-w-80px'>Aksi</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {DataGuru.length === 0 ? (
                <tr>
                  <td colSpan={12} className='text-center'>
                    <span className='text-dark fw-bolder text-hover-primary fs-6'>
                      Data Not Found
                    </span>
                  </td>
                </tr>
              ) : (
                DataGuru.map((_guru: any) => {
                  return (
                    <tr key={_guru.indexGuru}>
                      <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input widget-13-check'
                            type='checkbox'
                            value='1'
                          />
                        </div>
                      </td>
                      <td>
                        <span className='text-dark fw-bolder text-hover-primary fs-6'>
                          {_guru.nuptk}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark fw-bolder text-hover-primary fs-6'>
                          {_guru.namaGuru}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark fw-bolder text-hover-primary fs-6'>
                          {_guru.jabatan}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark fw-bolder text-hover-primary fs-6'>
                          {_guru.tanggalMasuk}
                        </span>
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          {formatHari(_guru.tanggalMasuk)}
                        </span>
                      </td>
                      <td className='text-dark fw-bolder text-hover-primary fs-6'>
                        {_guru.pendidikanName}
                      </td>
                      <td>
                        <span className='text-dark fw-bolder text-hover-primary fs-6'>
                          {_guru.statusPegawai}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark fw-bolder text-hover-primary fs-6'>
                          {_guru.tunjanganSertifikasi}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark fw-bolder text-hover-primary fs-6'>
                          {formatRupiah(_guru.honorDiterima, 'Rp. ')}
                        </span>
                      </td>
                      <td>
                        <button
                          onClick={(e) => editDataGuru(_guru)}
                          data-bs-toggle='modal'
                          data-bs-target='#editModal'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />
                        </button>
                        <button
                          onClick={(e) => deleteDataGuru(_guru.indexGuru)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3'
                          />
                        </button>
                      </td>
                    </tr>
                  )
                })
              )}
              {/* <FormEditModal /> */}
            </tbody>
            {DataGuru.length > 0 ? (
              <tfoot>
                <tr>
                  <td colSpan={10} style={{float: 'right'}}>
                    <div className='pull-right'>
                      <button
                        type='button'
                        className='btn btn-sm btn-danger'
                        onClick={(e) => submitAllData()}
                        disabled={isLoading}
                      >
                        {!isLoading && <span className='indicator-label'>Simpan</span>}
                        {isLoading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Loading...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </td>
                </tr>
              </tfoot>
            ) : (
              ''
            )}

            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      {/* MODAL EDIT DATA GURU */}
      <div className='modal fade' tabIndex={-1} id='editModal'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Edit Data Guru</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row'>
                <div className='col-6 mb-5'>
                  <label className='form-label'>NUPTK</label>
                  <input
                    id='editNuptk'
                    onChange={(e) => setNuptk(Number(e.target.value))}
                    type='number'
                    max='9999999999999999'
                    min='0'
                    className='form-control'
                    placeholder='- NUPTK'
                  />
                </div>
                <div className='col-6 mb-5'>
                  <label className='form-label'>Nama Guru</label>
                  <input
                    id='editNamaGuru'
                    onChange={(e) => setNamaGuru(e.target.value)}
                    type='text'
                    className='form-control'
                    placeholder='- Nama Guru'
                  />
                </div>
                <div className='col-6 mb-5'>
                  <label className='form-label'>Jabatan</label>
                  <input
                    id='editJabatan'
                    onChange={(e) => setJabatan(e.target.value)}
                    type='text'
                    maxLength={20}
                    className='form-control'
                    placeholder='- Jabatan'
                  />
                </div>
                <div className='col-6 mb-5'>
                  <label className='form-label'>Tanggal Masuk</label>
                  <input
                    id='editTanggalMasuk'
                    onChange={(e) => setTanggalMasuk(e.target.value)}
                    type='date'
                    className='form-control'
                  />
                </div>
                <div className='col-6 mb-5'>
                  <label className='form-label'>Pendidikan</label>
                  <select
                    id='editPendidikan'
                    className='form-select'
                    onChange={(e) => setPendidikan(e.target.value)}
                  >
                    <option value=''>- Pilih Pendidikan</option>
                    {allDataLevel.length === 0 ? (
                      <option value=''>- Data jenjang kosong</option>
                    ) : (
                      allDataLevel.map((item: {id: boolean; name: string}) => {
                        return <option value={`${item.id}`}>{item.name}</option>
                      })
                    )}
                  </select>
                </div>
                <div className='col-6 mb-5'>
                  <label className='form-label'>Status Pegawai</label>
                  <select
                    className='form-select'
                    id='editStatusPegawai'
                    onChange={(e) => setStatusPegawai(e.target.value)}
                  >
                    <option>- Pilih Status Pegawai</option>
                    <option value='honorer'>Honorer</option>
                    <option value='pns'>PNS</option>
                    <option value='lainnya'>Lainnya</option>
                  </select>
                </div>
                <div className='col-6 mb-5'>
                  <label className='form-label'>Tunjangan Sertifikasi</label>
                  <select
                    className='form-select'
                    id='editTunjanganSertifikasi'
                    onChange={(e) => setTunjanganSertifikasi(e.target.value)}
                  >
                    <option>- Pilih Tunjangan Sertifikasi</option>
                    <option value='ya'>Ya</option>
                    <option value='tidak'>Tidak</option>
                  </select>
                </div>
                <div className='col-6 mb-5'>
                  <label className='form-label'>Honor Diterima</label>
                  <input
                    id='editHonorDiterima'
                    onChange={(e) => setHonorDiterima(Number(e.target.value))}
                    type='number'
                    min='0'
                    max='100000000'
                    className='form-control'
                    placeholder='- Honor Diterima'
                  />
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Tutup
              </button>
              <button
                onClick={submitFormEdit}
                type='button'
                className='btn btn-primary'
                data-bs-dismiss='modal'
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* END MODAL EDIT DATA GURU */}
    </div>
  )
}

export {TablesWidget13}
