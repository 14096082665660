import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import beritaContent from "./beritaContent.json";
import { log } from 'console';

type location = {
  pathname: string;
  title: string;
  content: string;
  date: string;
  category: string;
  image: string;
  references: [string];
  id: number;
}

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const BeritaContent = () => {
  const searchQuery = useQuery();
  const pathname = useLocation().pathname;
  const [dataBerita, setDataBerita] = React.useState<any>({
    title: '',
    content: '',
    date: '',
    category: '',
    image: '',
    references: [],
    id: 0
  })
  
  useEffect(() => {
      const search = new URLSearchParams(pathname.replaceAll(" ", "-").split('?').reverse()[0]);
      const id = searchQuery.get('id') || search.get('id');
      const data = beritaContent.find((item: any) => item.id === Number(id))
      console.log(search.get('id'));
      
      setDataBerita({...data})
  }, [pathname])
  
  return (
    <div>
      <div className="row">
        <div className="col-xl-12">
          <div className="content flex-row-fluid" id="kt_content">
            {/* begin::Post c */}
            <div className="card">
              {/* begin::Body */}
              <div className="card-body p-lg-20 pb-lg-0">
                {/* begin::Layout */}
                <div className="d-flex flex-column flex-xl-row">
                  {/* begin::Content */}
                  <div className="flex-lg-row-fluid me-xl-15">
                    {/* begin::Post content */}
                    <div className="mb-17">
                      {/* begin::Wrapper */}
                      <div className="mb-8">
                        {/* begin::Info */}
                        <div className="d-flex flex-wrap mb-6">
                          {/* begin::Item */}
                          <div className="me-9 my-1">
                            {/* begin::Icon */}
                            {/* begin::Svg Icon | path: icons/duotune/general/gen025.svg */}
                            <span className="svg-icon svg-icon-primary svg-icon-2 me-1">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                                <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                                <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                                <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                              </svg>
                            </span>
                            {/* end::Svg Icon */}
                            {/* end::Icon */}
                            {/* begin::Label */}
                            <span className="fw-bold text-gray-400">{dataBerita.date}</span>
                            {/* end::Label */}
                          </div>
                          {/* end::Item */}
                          {/* begin::Item */}
                          <div className="me-9 my-1">
                            {/* begin::Icon */}
                            {/* SVG file not found: icons/duotune/finance/fin006.svgFolder.svg */}
                            {/* end::Icon */}
                            {/* begin::Label */}
                            <span className="fw-bold text-gray-400">{dataBerita.category}</span>
                            {/* begin::Label */}
                          </div>
                          {/* end::Item */}
                          {/* begin::Item */}
                          <div className="my-1">
                            {/* begin::Icon */}
                            {/* begin::Svg Icon | path: icons/duotune/communication/com003.svg */}
                            {/* <span className="svg-icon svg-icon-primary svg-icon-2 me-1">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z" fill="currentColor"></path>
                                <path d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z" fill="currentColor"></path>
                              </svg>
                            </span> */}
                            {/* end::Svg Icon */}
                            {/* end::Icon */}
                            {/* begin::Label */}
                            {/* <span className="fw-bold text-gray-400">24 Comments</span> */}
                            {/* end::Label */}
                          </div>
                          {/* end::Item */}
                        </div>
                        {/* end::Info */}
                        {/* begin::Title */}
                        <a href="#" className="text-dark text-hover-primary fs-2 fw-bold"> { dataBerita.title } </a>
                        {/* end::Title */}
                        {/* begin::Container */}
                        <div className="overlay mt-8">
                          <div className="bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-350px">
                            <img
                              src={window.location.origin+'/media/berita/'+dataBerita.image}
                              alt=""
                              className="card-rounded min-h-350px w-100"
                            />
                          </div>
                        </div>
                        {/* end::Container */}
                      </div>
                      {/* end::Wrapper */}
                      {/* begin::Description */}
                      <div className="fs-5 fw-semibold text-gray-600">
                        {/* begin::Text */}
                        {
                          dataBerita.content && dataBerita !== "" 
                            ? <p className="mb-8" dangerouslySetInnerHTML={{ __html: dataBerita.content.replaceAll("\n", "<br />")}}></p>
                            : null
                        }
                        {/* end::Text */}
                      </div>
                      {/* end::Description */}
                      {/* begin::Block */}
                      <div className="d-flex flex-column border-1 border-dashed card-rounded p-5 p-lg-10 mb-14">
                        {/* begin::Text */}
                        <div className="mb-3 fs-4">
                          Reference
                        </div>
                        <div className="mb-0 fs-6">
                          {
                            dataBerita.references && dataBerita.references.map((item: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, i: React.Key | null | undefined) => {
                              return <div key={i}>{item}</div>
                            })
                          }
                        </div>
                        {/* end::Text */}
                      </div>
                      {/* end::Block */}
                      {/* begin::Icons */}
                      <div className="d-flex flex-center">
                        {/* begin::Icon */}
                        <a href="#" className="mx-4">
                          <img src="/metronic8/demo2/assets/media/svg/brand-logos/facebook-4.svg" className="h-20px my-2" alt="" />
                        </a>
                        {/* end::Icon */}
                        {/* begin::Icon */}
                        <a href="#" className="mx-4">
                          <img src="/metronic8/demo2/assets/media/svg/brand-logos/instagram-2-1.svg" className="h-20px my-2" alt="" />
                        </a>
                        {/* end::Icon */}
                        {/* begin::Icon */}
                        <a href="#" className="mx-4">
                          <img src="/metronic8/demo2/assets/media/svg/brand-logos/github.svg" className="h-20px my-2" alt="" />
                        </a>
                        {/* end::Icon */}
                        {/* begin::Icon */}
                        <a href="#" className="mx-4">
                          <img src="/metronic8/demo2/assets/media/svg/brand-logos/behance.svg" className="h-20px my-2" alt="" />
                        </a>
                        {/* end::Icon */}
                        {/* begin::Icon */}
                        <a href="#" className="mx-4">
                          <img src="/metronic8/demo2/assets/media/svg/brand-logos/pinterest-p.svg" className="h-20px my-2" alt="" />
                        </a>
                        {/* end::Icon */}
                        {/* begin::Icon */}
                        <a href="#" className="mx-4">
                          <img src="/metronic8/demo2/assets/media/svg/brand-logos/twitter.svg" className="h-20px my-2" alt="" />
                        </a>
                        {/* end::Icon */}
                        {/* begin::Icon */}
                        <a href="#" className="mx-4">
                          <img src="/metronic8/demo2/assets/media/svg/brand-logos/dribbble-icon-1.svg" className="h-20px my-2" alt="" />
                        </a>
                        {/* end::Icon */}
                      </div>
                      {/* end::Icons */}
                    </div>
                    {/* end::Post content */}
                  </div>
                  {/* end::Content */}
                  {/* begin::Sidebar */}
                  <div className="flex-column flex-lg-row-auto w-100 w-xl-300px mb-10">
                    {/* begin::Recent posts */}
                    <div className="m-0">
                      <h4 className="text-dark mb-7">Recent Posts</h4>
                      {/* begin::Item */}
                      {
                        beritaContent.map((item, i) => {
                          if (item.id !== dataBerita.id) {
                            return (
                              <div className="d-flex flex-stack mb-7" key={i}>
                                <div className="symbol symbol-60px symbol-2by3 me-4">
                                  <img 
                                    className="symbol-label"
                                    src={window.location.origin+'/media/berita/'+item.image}
                                    alt=""
                                    style={{
                                      objectFit: "scale-down"
                                    }}
                                  />
                                </div>
                                <div className="m-0">
                                  <Link
                                    to={{
                                      pathname: `/berita/${item.title.toLocaleLowerCase().replace(/[S,"/"]/g, '-')}?id=${item.id}`,
                                      state: {
                                        ...item
                                      }
                                    }}
                                    className="text-dark fw-bold text-hover-primary fs-6"
                                  >
                                    {item.title}
                                  </Link>
                                  <span className="text-gray-600 fw-semibold d-block pt-1 fs-7">{
                                    item.content.slice(0, 30)+'...'
                                  }</span>
                                </div>
                              </div>
                            )
                          }
                        })
                      }
                      {/* end::Item */}
                    </div>
                    {/* end::Recent posts */}
                  </div>
                  {/* end::Sidebar */}
                </div>
                {/* end::Layout */}
              </div>
              {/* end::Body */}
            </div>
            {/* end::Post card */}
          </div>
        </div>
      </div>
    </div>
  );
}