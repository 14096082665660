const initialState = {
  dataTableEducationStep: [],
  totalEducationStep: [],
}

const educationStepReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'DATATABLE_EDUCATION_STEP':
      return {...state, dataTableEducationStep: action.payload}
    case 'TOTAL_EDUCATION_STEP':
      return {...state, totalEducationStep: action.payload}
    default:
      return state
  }
}

export default educationStepReducer