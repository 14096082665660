import React, {useEffect} from 'react'
import {PageLink} from '../../_metronic/layout/core'
import {useLocation} from 'react-router-dom'
import getProfileSekolah from '../../setup/redux/actions/getProfileSekolah'
import {useDispatch} from 'react-redux'
import ProfilePage from '../modules/profile/ProfilePage'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Profile',
    path: '/sekolah/profile',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export function ProfileSekolah() {
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getProfileSekolah(location.pathname.split('/').reverse()[0]))
  }, [dispatch])

  return (
    <>
      <ProfilePage />
    </>
  )
}
