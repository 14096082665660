import React, { useEffect, useState } from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {tableYayasan} from '../../../setup/redux/actions/vervalYayasan'
import SearchBar from 'material-ui-search-bar'

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  React.useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay]
  )

  return debouncedValue
}

export default function StickyHeadTable({dataCabang}) {
  const location = useLocation()
  const path = location.pathname.split('/').reverse()[0]
  const columns = [
    {
      id: path === 'AW8673' ? 'npyp' : 'npsn',
      label: path === 'AW8673' ? 'NPYP' : 'NPSN',
      minWidth: 100
    },
    {id: 'nama', label: 'Nama', minWidth: 100},
    {
      id: 'kecamatan',
      label: 'Kecamatan',
    },
    {
      id: 'kabupaten',
      label: 'Kabupaten',
    },
    {
      id: 'provinsi',
      label: 'Provinsi',
    }
  ]
  
  const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      maxHeight: 440,
    },
  })
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.loading.loading)
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [keyword, setKeyword] = useState('')

  const search = useDebounce(keyword, 500) 

  const handleChangePage = (event, newPage) => {
    let payload = {
      page: newPage+1,
      limit: rowsPerPage,
      search: search || null,
      short:"asc",
      sort_by: 'name',
      path: path !== "verval" ? path : ""
    }
    dispatch(tableYayasan(payload))
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    let payload = {
      page: 1,
      limit: +event.target.value,
      search: search || null,
      short:"asc",
      sort_by: 'name',
      path: path !== "verval" ? path : ""
    }

    dispatch(tableYayasan(payload))
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const hanldeSearch = (value) => {
    setKeyword(value)
  }

  useEffect(() => {
    let payload = {
      page: 1,
      limit: rowsPerPage,
      search: search,
      short:"asc",
      sort_by: 'name',
      path: path !== "verval" ? path : ""
    }
    setPage(0)
    dispatch(tableYayasan(payload))
  }, [search])

  useEffect(() => {
    if (dataCabang) {
      if (dataCabang.recordsFiltered == dataCabang.recordsTotal) {
        setCount(dataCabang.recordsTotal)
      }else{
        setCount(dataCabang.recordsFiltered)
      }
    }
  }, [dataCabang])

  return (
    <Paper className={classes.root}>
      <div className='d-flex justify-content-between align-items-center flex-wrap'>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={count || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <SearchBar 
          placeholder={`Cari ${path === 'AW8673' ? "Nama Yayasan" : 'Sekolah'}`}
          value={keyword}
          onChange={(value) => hanldeSearch(value)}
          onRequestSearch={(value) => {}}
          className="shadow-none"
          onCancelSearch={() => hanldeSearch('')}
        /> 
      </div>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{minWidth: column.minWidth}}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              loading ? (
                  <TableRow
                    style={{
                      height: 250
                    }}
                  >
                    <TableCell colSpan={5} align='center'>
                      <h1>Loading...</h1>
                    </TableCell>
                  </TableRow>
              )
              :dataCabang?.data.map((row, index) => {
                return (
                  <TableRow hover role='checkbox' tabIndex={-1} key={index}>
                    <TableCell>
                      {
                        path === 'AW8673' 
                          ? <Link to={`/verval/${row.yayasan_id}`}>{row.npyp}</Link>
                          : <Link to={`/sekolah/profile/${row.sekolah_id}`}>{row.npsn}</Link>
                      }
                    </TableCell>
                    <TableCell>{row.nama}</TableCell>
                    <TableCell>{row.kecamatan}</TableCell>
                    <TableCell>{row.kabupaten}</TableCell>
                    <TableCell>{row.provinsi}</TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
      <div className='d-flex justify-content-between align-items-center flex-wrap'>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={count || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <SearchBar 
          placeholder={`Cari ${path === 'AW8673' ? "Nama Yayasan" : 'Sekolah'}`}
          value={keyword}
          onChange={(value) => hanldeSearch(value)}
          onRequestSearch={(value) => {}}
          className="shadow-none"
          onCancelSearch={() => hanldeSearch('')}
        /> 
      </div>
    </Paper>
  )
}
