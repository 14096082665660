import axios from 'axios'
import Swal from 'sweetalert2'

const postGuru = (params) => {
  return function (dispatch) {
    const API = process.env.REACT_APP_API_URL_NEW
    const TOKEN = process.env.REACT_APP_TOKEN_NEW

    dispatch({type: 'LOADING', payload: true})
    axios
      .post(`${API}/teacher/many-create`, params, {
        headers: {
          key: TOKEN,
          Authorization: `Bearer ${localStorage.access_token}`,
        },
      })
      .then(({data}) => {
        dispatch({type: 'LOADING', payload: false})
        dispatch({type: 'ALL_DATA_POST_GURU', payload: {status: true, data: data}})

        Swal.fire({
          position: 'center',
          icon: 'success',
          title: `Berhasil menambahkan data`,
          showConfirmButton: false,
          timer: 1500,
        })
      })
      .catch((err) => {
        dispatch({type: 'LOADING', payload: false})

        Swal.fire({
          position: 'center',
          icon: 'error',
          title: `Gagal menambahkan data`,
          showConfirmButton: false,
          timer: 1500,
        })

        if (err.response.status === 422) {
          dispatch({type: 'ALL_DATA_POST_GURU', payload: {status: false, data: err}})
        }
      })
  }
}

export default postGuru
