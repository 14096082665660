import axios from "axios";

export const getMarkerDetail = (id) => {
  return function (dispatch) {
    const API = process.env.REACT_APP_API_URL_NEW;
    const TOKEN = process.env.REACT_APP_TOKEN_NEW;
    axios
      .get(`${API}/school/detailmarker/${id}`, {
        headers: {
          key: TOKEN,
          Authorization: `Bearer ${localStorage.access_token}`,
        },
      })
      .then(({ data }) => {
        dispatch({ type: "GET_MARKER_DETAIL", payload: data.data });
        dispatch({ type: "LOADING_MARKER", payload: false });
      })
      .catch((err) => {
        dispatch({ type: "GET_MARKER_DETAIL", payload: [] });
        dispatch({ type: "LOADING_MARKER", payload: false });

        if (err.response.status === 401) {
          dispatch({ type: "AUTHORIZED", payload: false });
          localStorage.clear();
          window.location.reload();
        }
        console.log(err, "ini errr");
      });
  };
}