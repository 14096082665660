import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Profile from './components/Profile'
import {Akademik} from './components/Akademik'
import {DanaTaawun} from './components/DanaTaawun'
import EkskulMulok from './components/EkskulMulok'
import SanitaisTik from './components/SanitaisTik'
import Koneksi from './components/Koneksi'
// import {Campaigns} from './components/Campaigns'
// import {Keuangan} from './components/Keuangan'
// import {FormInputGuru} from './components/FormInputGuru'
import ProfileHeader from './ProfileHeader'
import {useLocation} from 'react-router-dom'

function ProfilePage() {
  const location = useLocation()
  return (
    <>
      <ProfileHeader />
      <Switch>
        <Route path={`/sekolah/profile/${location.pathname.split('/').reverse()[0]}`}>
          <PageTitle
            breadcrumbs={[
              {
                title: 'Profil',
                path: `${location}`,
                isSeparator: false,
                isActive: false,
              },
              {
                title: '',
                path: '',
                isSeparator: true,
                isActive: false,
              },
            ]}
          >
            Sekolah Madrasah Muhammadiyah
          </PageTitle>
          <Profile />
        </Route>
        <Route path={`/sekolah/dana-taawun/${location.pathname.split('/').reverse()[0]}`}>
          <PageTitle
            breadcrumbs={[
              {
                title: 'Dana Ta'+"'"+'awun',
                path: `${location}`,
                isSeparator: false,
                isActive: false,
              },
              {
                title: '',
                path: '',
                isSeparator: true,
                isActive: false,
              },
            ]}
          >
            Sekolah Madrasah Muhammadiyah
          </PageTitle>
          <DanaTaawun />
        </Route>
        <Route path={`/sekolah/akademik/${location.pathname.split('/').reverse()[0]}`}>
          <PageTitle
            breadcrumbs={[
              {
                title: 'Akademik',
                path: `${location}`,
                isSeparator: false,
                isActive: false,
              },
              {
                title: '',
                path: '',
                isSeparator: true,
                isActive: false,
              },
            ]}
          >
            Sekolah Madrasah Muhammadiyah
          </PageTitle>
          <Akademik />
        </Route>
        <Route path={`/sekolah/ekskul-mulok/${location.pathname.split('/').reverse()[0]}`}>
          <PageTitle
            breadcrumbs={[
              {
                title: 'Ekskul & Mulok',
                path: `${location}`,
                isSeparator: false,
                isActive: false,
              },
              {
                title: '',
                path: '',
                isSeparator: true,
                isActive: false,
              },
            ]}
          >
            Sekolah Madrasah Muhammadiyah
          </PageTitle>
          <EkskulMulok />
        </Route>
        <Route path={`/sekolah/sanitasi-tik/${location.pathname.split('/').reverse()[0]}`}>
          <PageTitle
            breadcrumbs={[
              {
                title: 'Sanitasi & TIK',
                path: `${location}`,
                isSeparator: false,
                isActive: false,
              },
              {
                title: '',
                path: '',
                isSeparator: true,
                isActive: false,
              },
            ]}
          >
            Sekolah Madrasah Muhammadiyah
          </PageTitle>
          <SanitaisTik />
        </Route>
        <Route path={`/sekolah/koneksi/${location.pathname.split('/').reverse()[0]}`}>
          <PageTitle
            breadcrumbs={[
              {
                title: 'Koneksi',
                path: `${location}`,
                isSeparator: false,
                isActive: false,
              },
              {
                title: '',
                path: '',
                isSeparator: true,
                isActive: false,
              },
            ]}
          >
            Sekolah Madrasah Muhammadiyah
          </PageTitle>
          <Koneksi />
        </Route>
        <Redirect
          from='/sekolah/profile'
          exact={true}
          to={`/sekolah/profile/${location.pathname.split('/').reverse()[0]}`}
        />
        <Redirect to={`/sekolah/profile/${location.pathname.split('/').reverse()[0]}`} />
      </Switch>
    </>
  )
}

export default ProfilePage
