const initialState = {
  markerDetail: [],
  loadingMarkerDetail: false,
}

const getMarkerDetail = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MARKER_DETAIL":
      return { ...state, markerDetail: action.payload };
    case "LOADING_MARKER":
      return { ...state, loadingMarkerDetail: action.payload };
    default:
      return state;
  }
}

export default getMarkerDetail