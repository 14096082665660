import React, { useState } from 'react';
import BootstrapTable, { BootstrapTableProps, ColumnDescription, SortOrder } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {KTSVG, toAbsoluteUrl} from "../../../../_metronic/helpers";

export function Overview() {
    // @ts-ignore
    interface CustomColumnDescription {
        dataField: string;
        text: string;
        sort: boolean;
        sortCaret: (order: SortOrder | "asc" | "desc" | undefined, column: ColumnDescription<any, any>) => JSX.Element | null;
        headerStyle?: { width: string };
        headerClasses?: string;
    }

    const columns: CustomColumnDescription[] = [
        {
            dataField: 'nama_sekolah',
            text: 'NAMA SEKOLAH/MADRASAH',
            sort: false,
            sortCaret: generateSortCaret,
            headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
        },
        {
            dataField: 'total_siswa',
            text: 'TOTAL SISWA/SISWA THN AJAR BARU',
            sort: true,
            sortCaret: generateSortCaret,
            headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
        },
        {
            dataField: 'jml_guru',
            text: 'JUMLAH GURU PNS/NON PNS',
            sort: true,
            sortCaret: generateSortCaret,
            headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
        },
        {
            dataField: 'jml_karyawan',
            text: 'JUMLAH KARYAWAN',
            sort: true,
            sortCaret: generateSortCaret,
            headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
        },
        {
            dataField: 'create_update',
            text: 'DATA DIBUAT DIREVISI',
            sort: true,
            sortCaret: generateSortCaret,
            headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
        },
        {
            dataField: 'k_dana_taawun',
            text: 'KEWAJIBAN DANA TA’AWUN',
            sort: true,
            sortCaret: generateSortCaret,
            headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
        },
        {
            dataField: 'k_uis',
            text: 'KEWAJIBAN UIS PERTAHUN',
            sort: true,
            sortCaret: generateSortCaret,
            headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
        },
        {
            dataField: 'k_uig',
            text: 'KEWAJIBAN UIG PERTAHUN',
            sort: true,
            sortCaret: generateSortCaret,
            headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
        },
        {
            dataField: 'k_uik',
            text: 'KEWAJIBAN UIK PERTAHUN',
            sort: true,
            sortCaret: generateSortCaret,
            headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
        },
        {
            dataField: 'k_dpp',
            text: 'KEWAJIBAN DPP PERTAHUN',
            sort: true,
            sortCaret: generateSortCaret,
            headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
        },
        {
            dataField: 'k_data_taawun_dibayarkan',
            text: 'DANA TA’AWUN DIBAYARKAN',
            sort: true,
            sortCaret: generateSortCaret,
            headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
        },
        {
            dataField: 'k_uis_dibayarkan',
            text: 'UIS PERTAHUN DIBAYARKAN',
            sort: true,
            sortCaret: generateSortCaret,
            headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
        },
        {
            dataField: 'k_uig_dibayarkan',
            text: 'UIG PERTAHUN DIBAYARKAN',
            sort: true,
            sortCaret: generateSortCaret,
            headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
        },
        {
            dataField: 'k_uik_dibayarkan',
            text: 'UIK PERTAHUN DIBAYARKAN',
            sort: true,
            sortCaret: generateSortCaret,
            headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
        },
        {
            dataField: 'k_dpp_dibayarkan',
            text: 'DPP PERTAHUN DIBAYARKAN',
            sort: true,
            sortCaret: generateSortCaret,
            headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
        },
        {
            dataField: 'selisih_pembayaran',
            text: 'SELISIH PEMBAYARAN',
            sort: true,
            sortCaret: generateSortCaret,
            headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
        },
        {
            dataField: 'penyetor_pembayaran',
            text: 'PENYETOR PEMBAYARAN',
            sort: true,
            sortCaret: generateSortCaret,
            headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
        },
        {
            dataField: 'status_pembayaran',
            text: 'STATUS PEMBAYARAN',
            sort: true,
            sortCaret: generateSortCaret,
            headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
        },
        {
            dataField: 'aksi',
            text: 'Aksi',
            sort: true,
            sortCaret: generateSortCaret,
            headerClasses: 'fixed-header' // Atur kelas CSS untuk header tetap
        }
    ];

    function generateSortCaret(order: SortOrder | undefined, column: any): JSX.Element | null {
        if (!order) return <span>&nbsp;&nbsp;<i className="fa fa-sort"></i></span>;
        else if (order === 'asc') return <span>&nbsp;&nbsp;<i className="fa fa-sort-up"></i></span>;
        else if (order === 'desc') return <span>&nbsp;&nbsp;<i className="fa fa-sort-down"></i></span>;
        return null;
    }
    const products = [
        { id: 1, nama_sekolah: 'SMK Muhammadiyah 3 Karanganyar', total_siswa: '1304/186', jml_guru: '186', jml_karyawan: 62, create_update: '02/20/24 / 02/25/24', k_dana_taawun: ' Rp32.658.680', k_uis: 'Rp19.595.208', k_uig: ' Rp3.919.042', k_uik: 'Rp2.612.694', k_dpp: 'Rp6.531.736', k_data_taawun_dibayarkan: 'Rp32.444.060', k_uis_dibayarkan: 'Rp19.466.436', k_uig_dibayarkan: 'Rp3.893.287', k_uik_dibayarkan: 'Rp2.595.525', k_dpp_dibayarkan: 'Rp6.488.812', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Muh. Andika', status_pembayaran: 'Dibayar', aksi: 'edit print'},
        { id: 2, nama_sekolah: 'SMA Negeri 1 Surakarta', total_siswa: '1200/50', jml_guru: '50', jml_karyawan: 40, create_update: '01/15/24 / 01/20/24', k_dana_taawun: 'Rp30.000.000', k_uis: 'Rp18.000.000', k_uig: 'Rp3.600.000', k_uik: 'Rp2.400.000', k_dpp: 'Rp6.000.000', k_data_taawun_dibayarkan: 'Rp29.785.380', k_uis_dibayarkan: 'Rp17.871.228', k_uig_dibayarkan: 'Rp3.574.246', k_uik_dibayarkan: 'Rp2.382.830', k_dpp_dibayarkan: 'Rp5.956.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Budi Santoso', status_pembayaran: 'Dibayar', aksi: 'edit print' },
        { id: 3, nama_sekolah: 'SMK Negeri 2 Solo', total_siswa: '1000/60', jml_guru: '60', jml_karyawan: 45, create_update: '03/05/24 / 03/10/24', k_dana_taawun: 'Rp35.000.000', k_uis: 'Rp21.000.000', k_uig: 'Rp4.200.000', k_uik: 'Rp2.800.000', k_dpp: 'Rp7.000.000', k_data_taawun_dibayarkan: 'Rp34.785.380', k_uis_dibayarkan: 'Rp20.871.228', k_uig_dibayarkan: 'Rp4.174.246', k_uik_dibayarkan: 'Rp2.782.830', k_dpp_dibayarkan: 'Rp6.956.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Lina Setiawan', status_pembayaran: 'Dibayar', aksi: 'edit print' },
        { id: 4, nama_sekolah: 'SMP Islam Terpadu Solo', total_siswa: '800/40', jml_guru: '40', jml_karyawan: 30, create_update: '04/10/24 / 04/15/24', k_dana_taawun: 'Rp25.000.000', k_uis: 'Rp15.000.000', k_uig: 'Rp3.000.000', k_uik: 'Rp2.000.000', k_dpp: 'Rp5.000.000', k_data_taawun_dibayarkan: 'Rp24.785.380', k_uis_dibayarkan: 'Rp14.871.228', k_uig_dibayarkan: 'Rp2.974.246', k_uik_dibayarkan: 'Rp1.982.830', k_dpp_dibayarkan: 'Rp4.956.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Rudi Hartono', status_pembayaran: 'Dibayar', aksi: 'edit print' },
        { id: 5, nama_sekolah: 'SD Islam Al-Azhar Solo', total_siswa: '600/30', jml_guru: '30', jml_karyawan: 25, create_update: '05/20/24 / 05/25/24', k_dana_taawun: 'Rp20.000.000', k_uis: 'Rp12.000.000', k_uig: 'Rp2.400.000', k_uik: 'Rp1.600.000', k_dpp: 'Rp4.000.000', k_data_taawun_dibayarkan: 'Rp19.785.380', k_uis_dibayarkan: 'Rp11.871.228', k_uig_dibayarkan: 'Rp2.374.246', k_uik_dibayarkan: 'Rp1.582.830', k_dpp_dibayarkan: 'Rp3.956.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Siti Rahayu', status_pembayaran: 'Dibayar', aksi: 'edit print' },
        { id: 6, nama_sekolah: 'TK Islam Terpadu Solo', total_siswa: '400/20', jml_guru: '20', jml_karyawan: 15, create_update: '06/30/24 / 07/05/24', k_dana_taawun: 'Rp15.000.000', k_uis: 'Rp9.000.000', k_uig: 'Rp1.800.000', k_uik: 'Rp1.200.000', k_dpp: 'Rp3.000.000', k_data_taawun_dibayarkan: 'Rp14.785.380', k_uis_dibayarkan: 'Rp8.871.228', k_uig_dibayarkan: 'Rp1.774.246', k_uik_dibayarkan: 'Rp1.182.830', k_dpp_dibayarkan: 'Rp2.956.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Dewi Setyawati', status_pembayaran: 'Dibayar', aksi: 'edit print' },
        { id: 7, nama_sekolah: 'SMA Kristen Petra Solo', total_siswa: '1100/55', jml_guru: '55', jml_karyawan: 45, create_update: '07/10/24 / 07/15/24', k_dana_taawun: 'Rp32.000.000', k_uis: 'Rp19.200.000', k_uig: 'Rp3.840.000', k_uik: 'Rp2.560.000', k_dpp: 'Rp6.400.000', k_data_taawun_dibayarkan: 'Rp31.785.380', k_uis_dibayarkan: 'Rp19.071.228', k_uig_dibayarkan: 'Rp3.814.246', k_uik_dibayarkan: 'Rp2.542.830', k_dpp_dibayarkan: 'Rp6.356.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Hendro Wibowo', status_pembayaran: 'Dibayar', aksi: 'edit print' },
        { id: 8, nama_sekolah: 'SMK Muhammadiyah 1 Surakarta', total_siswa: '1300/65', jml_guru: '65', jml_karyawan: 50, create_update: '08/25/24 / 08/30/24', k_dana_taawun: 'Rp36.000.000', k_uis: 'Rp21.600.000', k_uig: 'Rp4.320.000', k_uik: 'Rp2.880.000', k_dpp: 'Rp7.200.000', k_data_taawun_dibayarkan: 'Rp35.785.380', k_uis_dibayarkan: 'Rp21.471.228', k_uig_dibayarkan: 'Rp4.294.246', k_uik_dibayarkan: 'Rp2.862.830', k_dpp_dibayarkan: 'Rp7.156.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Wahyu Kurniawan', status_pembayaran: 'Dibayar', aksi: 'edit print' },
        { id: 9, nama_sekolah: 'SMP Negeri 3 Surakarta', total_siswa: '900/45', jml_guru: '45', jml_karyawan: 35, create_update: '09/05/24 / 09/10/24', k_dana_taawun: 'Rp28.000.000', k_uis: 'Rp16.800.000', k_uig: 'Rp3.360.000', k_uik: 'Rp2.240.000', k_dpp: 'Rp5.600.000', k_data_taawun_dibayarkan: 'Rp27.785.380', k_uis_dibayarkan: 'Rp16.671.228', k_uig_dibayarkan: 'Rp3.334.246', k_uik_dibayarkan: 'Rp2.222.830', k_dpp_dibayarkan: 'Rp5.556.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Fitri Ananda', status_pembayaran: 'Dibayar', aksi: 'edit print' },
        { id: 10, nama_sekolah: 'SD Negeri 1 Solo', total_siswa: '700/35', jml_guru: '35', jml_karyawan: 30, create_update: '10/20/24 / 10/25/24', k_dana_taawun: 'Rp22.000.000', k_uis: 'Rp13.200.000', k_uig: 'Rp2.640.000', k_uik: 'Rp1.760.000', k_dpp: 'Rp4.400.000', k_data_taawun_dibayarkan: 'Rp21.785.380', k_uis_dibayarkan: 'Rp13.071.228', k_uig_dibayarkan: 'Rp2.614.246', k_uik_dibayarkan: 'Rp1.742.830', k_dpp_dibayarkan: 'Rp4.356.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Rina Widya', status_pembayaran: 'Dibayar', aksi: 'edit print' },
        { id: 11, nama_sekolah: 'TK Kartika Solo', total_siswa: '500/25', jml_guru: '25', jml_karyawan: 20, create_update: '11/30/24 / 12/05/24', k_dana_taawun: 'Rp18.000.000', k_uis: 'Rp10.800.000', k_uig: 'Rp2.160.000', k_uik: 'Rp1.440.000', k_dpp: 'Rp3.600.000', k_data_taawun_dibayarkan: 'Rp17.785.380', k_uis_dibayarkan: 'Rp10.671.228', k_uig_dibayarkan: 'Rp2.134.246', k_uik_dibayarkan: 'Rp1.422.830', k_dpp_dibayarkan: 'Rp3.556.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Fita Wulandari', status_pembayaran: 'Dibayar', aksi: 'edit print' },
        { id: 12, nama_sekolah: 'SD Kristen Immanuel Solo', total_siswa: '800/40', jml_guru: '40', jml_karyawan: 35, create_update: '12/10/24 / 12/15/24', k_dana_taawun: 'Rp24.000.000', k_uis: 'Rp14.400.000', k_uig: 'Rp2.880.000', k_uik: 'Rp1.920.000', k_dpp: 'Rp4.800.000', k_data_taawun_dibayarkan: 'Rp23.785.380', k_uis_dibayarkan: 'Rp14.271.228', k_uig_dibayarkan: 'Rp2.854.246', k_uik_dibayarkan: 'Rp1.902.830', k_dpp_dibayarkan: 'Rp4.756.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Larasati', status_pembayaran: 'Dibayar', aksi: 'edit print' },
        { id: 13, nama_sekolah: 'SMA Negeri 5 Solo', total_siswa: '1000/50', jml_guru: '50', jml_karyawan: 40, create_update: '01/15/25 / 01/20/25', k_dana_taawun: 'Rp30.000.000', k_uis: 'Rp18.000.000', k_uig: 'Rp3.600.000', k_uik: 'Rp2.400.000', k_dpp: 'Rp6.000.000', k_data_taawun_dibayarkan: 'Rp29.785.380', k_uis_dibayarkan: 'Rp17.871.228', k_uig_dibayarkan: 'Rp3.574.246', k_uik_dibayarkan: 'Rp2.382.830', k_dpp_dibayarkan: 'Rp5.956.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Firman Maulana', status_pembayaran: 'Dibayar', aksi: 'edit print' },
        { id: 14, nama_sekolah: 'SMK Negeri 1 Surakarta', total_siswa: '1100/55', jml_guru: '55', jml_karyawan: 45, create_update: '02/25/25 / 03/02/25', k_dana_taawun: 'Rp32.000.000', k_uis: 'Rp19.200.000', k_uig: 'Rp3.840.000', k_uik: 'Rp2.560.000', k_dpp: 'Rp6.400.000', k_data_taawun_dibayarkan: 'Rp31.785.380', k_uis_dibayarkan: 'Rp19.071.228', k_uig_dibayarkan: 'Rp3.814.246', k_uik_dibayarkan: 'Rp2.542.830', k_dpp_dibayarkan: 'Rp6.356.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Sofyan', status_pembayaran: 'Dibayar', aksi: 'edit print' },
        { id: 15, nama_sekolah: 'SMP Negeri 2 Surakarta', total_siswa: '900/45', jml_guru: '45', jml_karyawan: 35, create_update: '03/10/25 / 03/15/25', k_dana_taawun: 'Rp28.000.000', k_uis: 'Rp16.800.000', k_uig: 'Rp3.360.000', k_uik: 'Rp2.240.000', k_dpp: 'Rp5.600.000', k_data_taawun_dibayarkan: 'Rp27.785.380', k_uis_dibayarkan: 'Rp16.671.228', k_uig_dibayarkan: 'Rp3.334.246', k_uik_dibayarkan: 'Rp2.222.830', k_dpp_dibayarkan: 'Rp5.556.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Ani Wijaya', status_pembayaran: 'Dibayar', aksi: 'edit print' },
        { id: 16, nama_sekolah: 'SD Kristen Petra Solo', total_siswa: '800/40', jml_guru: '40', jml_karyawan: 35, create_update: '04/20/25 / 04/25/25', k_dana_taawun: 'Rp24.000.000', k_uis: 'Rp14.400.000', k_uig: 'Rp2.880.000', k_uik: 'Rp1.920.000', k_dpp: 'Rp4.800.000', k_data_taawun_dibayarkan: 'Rp23.785.380', k_uis_dibayarkan: 'Rp14.271.228', k_uig_dibayarkan: 'Rp2.854.246', k_uik_dibayarkan: 'Rp1.902.830', k_dpp_dibayarkan: 'Rp4.756.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Rina Pratiwi', status_pembayaran: 'Dibayar', aksi: 'edit print' },
        { id: 17, nama_sekolah: 'TK Islam Al-Azhar Solo', total_siswa: '600/30', jml_guru: '30', jml_karyawan: 25, create_update: '05/05/25 / 05/10/25', k_dana_taawun: 'Rp20.000.000', k_uis: 'Rp12.000.000', k_uig: 'Rp2.400.000', k_uik: 'Rp1.600.000', k_dpp: 'Rp4.000.000', k_data_taawun_dibayarkan: 'Rp19.785.380', k_uis_dibayarkan: 'Rp11.871.228', k_uig_dibayarkan: 'Rp2.374.246', k_uik_dibayarkan: 'Rp1.582.830', k_dpp_dibayarkan: 'Rp3.956.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Lia Agustina', status_pembayaran: 'Dibayar', aksi: 'edit print' },
        { id: 18, nama_sekolah: 'SD Kristen Dharma Wacana Solo', total_siswa: '700/35', jml_guru: '35', jml_karyawan: 30, create_update: '06/10/25 / 06/15/25', k_dana_taawun: 'Rp22.000.000', k_uis: 'Rp13.200.000', k_uig: 'Rp2.640.000', k_uik: 'Rp1.760.000', k_dpp: 'Rp4.400.000', k_data_taawun_dibayarkan: 'Rp21.785.380', k_uis_dibayarkan: 'Rp13.071.228', k_uig_dibayarkan: 'Rp2.614.246', k_uik_dibayarkan: 'Rp1.742.830', k_dpp_dibayarkan: 'Rp4.356.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Devi Lestari', status_pembayaran: 'Dibayar', aksi: 'edit print' },
        { id: 19, nama_sekolah: 'SMA Islam Terpadu Solo', total_siswa: '1200/60', jml_guru: '60', jml_karyawan: 50, create_update: '07/20/25 / 07/25/25', k_dana_taawun: 'Rp35.000.000', k_uis: 'Rp21.000.000', k_uig: 'Rp4.200.000', k_uik: 'Rp2.800.000', k_dpp: 'Rp7.000.000', k_data_taawun_dibayarkan: 'Rp34.785.380', k_uis_dibayarkan: 'Rp20.871.228', k_uig_dibayarkan: 'Rp4.174.246', k_uik_dibayarkan: 'Rp2.782.830', k_dpp_dibayarkan: 'Rp6.956.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Putri Haryanto', status_pembayaran: 'Dibayar', aksi: 'edit print' },
        { id: 20, nama_sekolah: 'SMK Kristen Immanuel Solo', total_siswa: '1300/65', jml_guru: '65', jml_karyawan: 50, create_update: '08/05/25 / 08/10/25', k_dana_taawun: 'Rp36.000.000', k_uis: 'Rp21.600.000', k_uig: 'Rp4.320.000', k_uik: 'Rp2.880.000', k_dpp: 'Rp7.200.000', k_data_taawun_dibayarkan: 'Rp35.785.380', k_uis_dibayarkan: 'Rp21.471.228', k_uig_dibayarkan: 'Rp4.294.246', k_uik_dibayarkan: 'Rp2.862.830', k_dpp_dibayarkan: 'Rp7.156.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Agus Susanto', status_pembayaran: 'Dibayar', aksi: 'edit print' },
        { id: 21, nama_sekolah: 'SMP Kristen Petra Solo', total_siswa: '1000/50', jml_guru: '50', jml_karyawan: 40, create_update: '09/20/25 / 09/25/25', k_dana_taawun: 'Rp30.000.000', k_uis: 'Rp18.000.000', k_uig: 'Rp3.600.000', k_uik: 'Rp2.400.000', k_dpp: 'Rp6.000.000', k_data_taawun_dibayarkan: 'Rp29.785.380', k_uis_dibayarkan: 'Rp17.871.228', k_uig_dibayarkan: 'Rp3.574.246', k_uik_dibayarkan: 'Rp2.382.830', k_dpp_dibayarkan: 'Rp5.956.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Dewi Susanti', status_pembayaran: 'Dibayar', aksi: 'edit print' },
        { id: 22, nama_sekolah: 'SD Kristen Alfa Omega Solo', total_siswa: '800/40', jml_guru: '40', jml_karyawan: 35, create_update: '10/05/25 / 10/10/25', k_dana_taawun: 'Rp24.000.000', k_uis: 'Rp14.400.000', k_uig: 'Rp2.880.000', k_uik: 'Rp1.920.000', k_dpp: 'Rp4.800.000', k_data_taawun_dibayarkan: 'Rp23.785.380', k_uis_dibayarkan: 'Rp14.271.228', k_uig_dibayarkan: 'Rp2.854.246', k_uik_dibayarkan: 'Rp1.902.830', k_dpp_dibayarkan: 'Rp4.756.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Nita Pratiwi', status_pembayaran: 'Dibayar', aksi: 'edit print' },
        { id: 23, nama_sekolah: 'TK Kristen Joyful Solo', total_siswa: '600/30', jml_guru: '30', jml_karyawan: 25, create_update: '11/20/25 / 11/25/25', k_dana_taawun: 'Rp20.000.000', k_uis: 'Rp12.000.000', k_uig: 'Rp2.400.000', k_uik: 'Rp1.600.000', k_dpp: 'Rp4.000.000', k_data_taawun_dibayarkan: 'Rp19.785.380', k_uis_dibayarkan: 'Rp11.871.228', k_uig_dibayarkan: 'Rp2.374.246', k_uik_dibayarkan: 'Rp1.582.830', k_dpp_dibayarkan: 'Rp3.956.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Dian Fitriani', status_pembayaran: 'Dibayar', aksi: 'edit print' },
        { id: 24, nama_sekolah: 'SD Islam Al-Khairiyah Solo', total_siswa: '700/35', jml_guru: '35', jml_karyawan: 30, create_update: '12/05/25 / 12/10/25', k_dana_taawun: 'Rp22.000.000', k_uis: 'Rp13.200.000', k_uig: 'Rp2.640.000', k_uik: 'Rp1.760.000', k_dpp: 'Rp4.400.000', k_data_taawun_dibayarkan: 'Rp21.785.380', k_uis_dibayarkan: 'Rp13.071.228', k_uig_dibayarkan: 'Rp2.614.246', k_uik_dibayarkan: 'Rp1.742.830', k_dpp_dibayarkan: 'Rp4.356.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Nur Cahyani', status_pembayaran: 'Dibayar', aksi: 'edit print' },
        { id: 25, nama_sekolah: 'SMA Kristen Mardi Waluyo Solo', total_siswa: '1300/65', jml_guru: '65', jml_karyawan: 50, create_update: '01/05/26 / 01/10/26', k_dana_taawun: 'Rp36.000.000', k_uis: 'Rp21.600.000', k_uig: 'Rp4.320.000', k_uik: 'Rp2.880.000', k_dpp: 'Rp7.200.000', k_data_taawun_dibayarkan: 'Rp35.785.380', k_uis_dibayarkan: 'Rp21.471.228', k_uig_dibayarkan: 'Rp4.294.246', k_uik_dibayarkan: 'Rp2.862.830', k_dpp_dibayarkan: 'Rp7.156.558', selisih_pembayaran: 'Rp214.620', penyetor_pembayaran: 'Andi Setiawan', status_pembayaran: 'Dibayar', aksi: 'edit print' }
    ];

    const customTotal = (from: number, to: number, size: number) => (
      <span className="react-bootstrap-table-pagination-total">
    Showing { from } to { to } of { size } Results
  </span>
    );

    const options = {
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: 'First',
        prePageText: 'Previous',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '5', value: 5
        }, {
            text: '10', value: 10
        }, {
            text: 'All', value: products.length
        }]
    };

    return (
      <div className='row g-5 g-xxl-8'>
            <div className='col-xl-12'>
                <div className={`card mb-5 mb-xxl-8`}>
                    {/* begin::Body */}
                    <div className='card-body pb-0'>
                        {/* begin::Header */}
                        <div className='d-flex align-items-center mb-5'>
                            {/* begin::User */}
                            <div className='d-flex align-items-center flex-grow-1'>
                                {/* begin::Info */}
                                <div className='d-flex flex-column'>
                                    <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bolder'>
                                        Rekapitulasi Dana Ta’awun
                                    </a>
                                    <span className='text-gray-400 fw-bold'>Rekapitulasi Dana Ta’awun berdasarkan jenjang sekolah atau madrasah</span>
                                </div>
                                {/* end::Info */}
                            </div>
                            {/* end::User */}
                        </div>
                        {/* end::Header */}

                        {/* begin::Post */}
                        <div className='mb-5'>
                            {/* begin::Text */}
                            <div className='row'>

                                <div className='col-xl-2'>
                                    <div className='card bg-light-paud'>
                                        {/* begin::Body */}
                                        <div className='card-body bg-light-paud align-items-center justify-content-center'>
                                            <h1 className='card-title text-color-paud text-center'>7436</h1>
                                            <p className='text-dark text-center fw-bolder'>TK/PAUD</p>
                                        </div>
                                        <div className='align-items-center justify-content-center bg-paud'>
                                            <p className='text-dark align-items-center text-center'><span className="bi bi-calculator"></span> 253</p>

                                        </div>
                                        {/* end:: Body */}
                                    </div>
                                </div>
                                <div className='col-xl-2'>
                                    <div className='card bg-light-paud-FEECF0'>
                                        {/* begin::Body */}
                                        <div className='card-body bg-light-paud-FEECF0 align-items-center justify-content-center'>
                                            <h1 className='card-title text-color-paud-FEECF0 text-center'>2343</h1>
                                            <p className='text-dark text-center fw-bolder'>SD/MI</p>
                                        </div>
                                        <div className='align-items-center justify-content-center bg-paud-FEECF0'>
                                            <p className='text-dark align-items-center text-center'><span className="bi bi-calculator"></span> 1253</p>

                                        </div>
                                        {/* end:: Body */}
                                    </div>
                                </div>
                                <div className='col-xl-2'>
                                    <div className='card bg-light-paud-E7F5FD'>
                                        {/* begin::Body */}
                                        <div className='card-body bg-light-paud-E7F5FD align-items-center justify-content-center'>
                                            <h1 className='card-title text-color-paud-E7F5FD text-center'>1522</h1>
                                            <p className='text-dark text-center fw-bolder'>SMP/MTS</p>
                                        </div>
                                        <div className='align-items-center justify-content-center bg-paud-E7F5FD'>
                                            <p className='text-dark text-color-paud-E7F5FD align-items-center text-center'><span className="bi bi-calculator"></span> 823</p>

                                        </div>
                                        {/* end:: Body */}
                                    </div>
                                </div>
                                <div className='col-xl-2'>
                                    <div className='card bg-light-paud-EFEFEF'>
                                        {/* begin::Body */}
                                        <div className='card-body bg-light-paud-EFEFEF align-items-center justify-content-center'>
                                            <h1 className='card-title text-color-paud-EFEFEF text-center'>668</h1>
                                            <p className='text-dark text-center fw-bolder'>SMA/MA</p>
                                        </div>
                                        <div className='align-items-center justify-content-center bg-paud-EFEFEF'>
                                            <p className='text-dark align-items-center text-center'><span className="bi bi-calculator"></span> 553</p>

                                        </div>
                                        {/* end:: Body */}
                                    </div>
                                </div>
                                <div className='col-xl-2'>
                                    <div className='card bg-light-paud-FEF3EC'>
                                        {/* begin::Body */}
                                        <div className='card-body bg-light-paud-FEF3EC align-items-center justify-content-center'>
                                            <h1 className='card-title text-color-paud-FEF3EC text-center'>608</h1>
                                            <p className='text-dark text-center fw-bolder'>SMK/MAK</p>
                                        </div>
                                        <div className='align-items-center justify-content-center bg-paud-FEF3EC'>
                                            <p className='text-dark align-items-center text-center'><span className="bi bi-calculator"></span> 457</p>

                                        </div>
                                        {/* end:: Body */}
                                    </div>
                                </div>
                                <div className='col-xl-2'>
                                    <div className='card bg-light-paud-E9B600'>
                                        {/* begin::Body */}
                                        <div className='card-body bg-light-paud-E9B600 align-items-center justify-content-center'>
                                            <h1 className='card-title text-color-paud-E9B600 text-center'>37</h1>
                                            <p className='text-dark text-center fw-bolder'>SDLB/SMPLB/SLB</p>
                                        </div>
                                        <div className='align-items-center justify-content-center bg-paud-E9B600'>
                                            <p className='text-dark align-items-center text-center'><span className="bi bi-calculator"></span> 12</p>

                                        </div>
                                        {/* end:: Body */}
                                    </div>
                                </div>
                            </div>
                            {/* end::Text */}
                        </div>
                        {/* end::Post */}

                    </div>
                    {/* end::Body */}
                </div>
            </div>
      </div>
  )
}
