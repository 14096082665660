const initialState = {
  schoolSearch: [],
}

const getSchoolSearch = (state = initialState, action) => {
  switch (action.type) {
    case 'SCHOOL_SEARCH':
      return {...state, schoolSearch: action.payload}
    default:
      return state
  }
}

export default getSchoolSearch
