import React from 'react'
import {Redirect, Route, Switch, useLocation} from 'react-router-dom'
import { BeritaContent } from './BeritaContent'
import {HalamanBerita} from './HalamanBerita'

export default function BeritaPage() {
  const location = useLocation()
  return (
    <Switch>
      <Route path={`/berita/${location.pathname.split('/').reverse()[0]}`} component={BeritaContent} />
      <Route path={'/berita'} component={HalamanBerita} />

      <Redirect from='/berita' to='/berita' exact />
    </Switch>
  )
}
