import React, { useEffect } from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
})

export default function StickyHeadTable({dataTable}) {
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const columns = [
    {id: 'name', label: '#', minWidth: 100},
    {id: 'total_school', label: 'Sekolah', minWidth: 100},
    {
      id: 'total_siswa_l',
      label: 'Siswa Laki-Laki',
      minWidth: 100,
      align: 'right',
      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'total_siswa_p',
      label: 'Siswa Perempuan',
      minWidth: 100,
      align: 'right',
      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'total_rombongan',
      label: 'Rombel',
      minWidth: 100,
      align: 'right',
      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'total_guru',
      label: 'Guru',
      minWidth: 100,
      align: 'right',
      // format: (value) => value.value.toLocaleString('en-US'),
    }
  ]

  const getRows = () => {
    let rowss = []
    let totalSiswa  = 0
    let totalSiswi  = 0
    let totalRombel = 0
    let totalGuru   = 0
    let totalSekolah = 0

    Object.keys(dataTable).map((key, index) => {
      rowss.push(dataTable[key])
      totalSiswa  += parseInt(dataTable[key].total_siswa_l)
      totalSiswi  += parseInt(dataTable[key].total_siswa_p)
      totalRombel += parseInt(dataTable[key].total_rombongan)
      totalGuru   += parseInt(dataTable[key].total_guru)
      totalSekolah += parseInt(dataTable[key].total_school)
    })

    rowss.push({
      name: "Total",
      total_guru: totalGuru,
      total_rombongan: totalRombel,
      total_school: totalSekolah,
      total_siswa: totalSiswa,
      total_siswa_l: totalSiswa,
      total_siswa_p: totalSiswi,
    })

    return rowss
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  useEffect(() => {
    getRows()
  }, [dataTable])

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    fontWeight: 'bolder'
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              getRows().map((item, index) => {
                return (
                  <TableRow hover role='checkbox' tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      const value = item[column.id]
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        count={getRows().length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
