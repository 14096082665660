import axios from 'axios'
import Swal from 'sweetalert2'

const login = (email, password) => {
  return function (dispatch) {
    const API = process.env.REACT_APP_API_URL_NEW
    dispatch({type: 'LOADING', payload: true})
    axios
      .post(`${API}/oauth/login`, {
        email: email,
        password: password,
      })
      .then(({data}) => {
        console.log(data)
        localStorage.setItem('access_token', data.access_token)
        localStorage.setItem('sch_id', data.user['sch_id'])
        localStorage.setItem('emailx', data.user['email'])
        localStorage.setItem('namez', data.user['name'])
        dispatch({type: 'LOADING', payload: false})
        dispatch({type: 'USER', payload: data})
        dispatch({type: 'AUTHORIZED', payload: true})
      })
      .catch((error) => {
        dispatch({type: 'LOADING', payload: false})
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: `Email / Password Salah`,
          showConfirmButton: false,
          timer: 1500,
        })
      })
  }
}

export default login
