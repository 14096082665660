import axios from "axios";

const tableYayasan = (payload) => {
  return (dispatch) => {
    dispatch({type: "LOADING", payload: true});
    const API = process.env.REACT_APP_API_URL_NEW
    const TOKEN = "7d4cfd980bca13f37e3840b8b0caa661"
    const path = payload.path == "AW8673" || payload.path == "verval" ? "" : payload.path
    try {
      axios({
        method: "POST",
        url: `${API}/yayasan/dt/${path}`,
        headers: {
          key: TOKEN,
          "Content-Type": "application/json",
          "Set-Cookie": " XSRF-TOKEN=eyJpdiI6Ii9yNTBaZmFFVDVyUmk0M09jelZhaVE9PSIsInZhbHVlIjoiR1l6R1BiUm9pQUNZN2hGYVQvY0JTWnYwQU1KRjB5Yk4vam5vMHdVRzVCbHB2OFFkajEwd2dZQzRIL1dRZjdrVGdYZ0ovNFVzUnExNzFnVksxY3MxRThHN2huZmNhaUUvbWZ0MSs4R05kNFpBcnQwSzFKelpRVVk1RXoyYVRQakwiLCJtYWMiOiIxMTY2MjkxYjYyYzVjYjc1ZmRkM2MxZTk0MWY0NjU2ZGRlZjY3YTM2OGU2NjQ4MWZjMThlZDdhOGQzNjMwNWJmIn0%3D",
          "Accept": "application/json",
        },
        data: payload
      }).then(({data}) => {
        dispatch({type: "LOADING", payload: false});
        if (data.status === 200) {
          dispatch({
            type: "ALL_YAYASAN",
            payload: data,
          }) 
        }
      })
    } catch (error) {
      console.log("ada yang salah di tableYayasan");
    }
  }
}

const detailYayasan = (payload) => {
  return (dispatch) => {
    dispatch({type: "LOADING", payload: true});
    const Api = process.env.REACT_APP_API_URL_NEW
    const TOKEN = "7d4cfd980bca13f37e3840b8b0caa661"
    try {
      if (payload.yayasan_id !== "verval" && payload.yayasan_id !== "AW8673") {
        axios({
          method: "POST",
          url: `${Api}/yayasan/detail/${payload.yayasan_id}`,
          headers: {
            key: TOKEN,
            "Content-Type": "application/json",
            "Set-Cookie": " XSRF-TOKEN=eyJpdiI6Ii9yNTBaZmFFVDVyUmk0M09jelZhaVE9PSIsInZhbHVlIjoiR1l6R1BiUm9pQUNZN2hGYVQvY0JTWnYwQU1KRjB5Yk4vam5vMHdVRzVCbHB2OFFkajEwd2dZQzRIL1dRZjdrVGdYZ0ovNFVzUnExNzFnVksxY3MxRThHN2huZmNhaUUvbWZ0MSs4R05kNFpBcnQwSzFKelpRVVk1RXoyYVRQakwiLCJtYWMiOiIxMTY2MjkxYjYyYzVjYjc1ZmRkM2MxZTk0MWY0NjU2ZGRlZjY3YTM2OGU2NjQ4MWZjMThlZDdhOGQzNjMwNWJmIn0%3D",
            "Accept": "application/json",
          },
          data: payload
        }).then(({data}) => {
          if (data.status === 200) {
            dispatch({type: "LOADING", payload: false});
            dispatch({
              type: "DETAIL_YAYASAN",
              payload: data.data,
            }) 
          }
        })
      }
    } catch (error) {
      console.log("ada yang salah di detailYayasan");
    }
  }
}

export {tableYayasan, detailYayasan}