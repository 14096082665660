import axios from 'axios'
// import Swal from 'sweetalert2'

const login = (email, firstname, lastname, password, changepassword) => {
  return function (dispatch) {
    const API = process.env.REACT_APP_API_URL_NEW
    dispatch({type: 'LOADING', payload: true})
    axios
      .post(`${API}/oauth/register`, {
        name: `${firstname} ${lastname}`,
        email: email,
        password: password,
        password_confirmation: changepassword,
      })
      .then(({data}) => {
        dispatch({type: 'LOADING', payload: false})
        dispatch({type: 'AUTHORIZED', payload: true})
      })
      .catch((err) => {
        dispatch({type: 'LOADING', payload: false})
        console.log(err)
        // Swal.fire({
        // position: 'center',
        // icon: 'error',
        // title: `Email / Password Salah`,
        // showConfirmButton: false,
        // timer: 1500
        // })
      })
  }
}

export default login
