/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import {MasterInit} from '../../_metronic/layout/MasterInit'
import {PublicRoutes} from './PublicRoutes'

const Routes: FC = () => {
  const authorized = useSelector((state: RootState) => state.authorizedReducer.authorized)
  return (
    <>
      <Switch>
        {!localStorage.getItem('access_token') && !authorized ? (
          <Route>
            <PublicRoutes />
          </Route>
        ) : (
          <Redirect from='/auth' to='/' />
        )}
        <Route path='/error' component={ErrorsPage} />
        <Route path='/logout' component={Logout} />

        {!localStorage.getItem('access_token') ? (
          <Redirect to='/auth/login' />
        ) : (
          <>
            <MasterLayout>
              <PrivateRoutes />
            </MasterLayout>
          </>
        )}
      </Switch>
      <MasterInit />
    </>
  )
}

export {Routes}
