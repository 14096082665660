import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {HalamanBantuan} from './HalamanBantuan'

export default function BantuanPage() {
  return (
    <Switch>
      <Route path={'/bantuan'} component={HalamanBantuan} />
    </Switch>
  )
}
