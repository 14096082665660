const initialState = {
  authorized: false,
}

const authorizedReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'AUTHORIZED':
      return {...state, authorized: action.payload}
    default:
      return state
  }
}

export default authorizedReducer
