import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import IconGen024 from '../../../../assets/icons/general/gen024.svg'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {ReplaceToRupiah} from '../../../utils/replace'
import DummyImageSlide1 from '../../../../assets/images/dummy/dummy-slide-1.jpeg'
import DummyImageSlide2 from '../../../../assets/images/dummy/dummy-slide-2.jpeg'
import DummyImageSlide3 from '../../../../assets/images/dummy/dummy-slide-3.jpeg'
import {MapContainer, Marker, TileLayer} from 'react-leaflet'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import {Bar} from 'react-chartjs-2'
import {faker} from '@faker-js/faker'

const dataImageDummy = [
  {
    image: DummyImageSlide1,
  },
  {
    image: DummyImageSlide2,
  },
  {
    image: DummyImageSlide3,
  },
]

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
}
const labels = ['2018/2019', '2019/2020', '2020/2021', '2021/2022', '2022/2023']
const data = {
  labels,
  datasets: [
    {
      data: labels.map(() => faker.datatype.number({min: 80, max: 400})),
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      data: labels.map(() => faker.datatype.number({min: 80, max: 400})),
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
}

const Profile = () => {
  const dataOperator = useSelector((state: RootState) => state.profileSekolahReducer.dataOperator)
  const dataGuru = useSelector((state: RootState) => state.profileSekolahReducer.dataGuru)
  const dataPendidikanGuru = useSelector(
    (state: RootState) => state.profileSekolahReducer.dataPendidikanGuru
  )
  const dataRataRataHonorer = useSelector(
    (state: RootState) => state.profileSekolahReducer.dataRataRataHonorer
  )
  const dataListGuru = useSelector((state: RootState) => state.profileSekolahReducer.dataListGuru)
  const detailSekolah = useSelector((state: RootState) => state.profileSekolahReducer.detailSekolah)

  const [indexImage, setIndexImage] = useState(0)
  const [totalSiswa, setTotalSiswa] = useState({
    laki: 0,
    perempuan: 0,
    rombel: 0
  })
  const [totalGuru, setTotalGuru] = useState({
    total: 0,
    laki: 0,
    perempuan: 0,
    strata: 0,
    diploma: 0,
    slta: 0,
    lainnya: 0
  })
  const [ruang, setRuang] = useState({
    kelas: 0,
    laboratorium: 0,
    perpustakaan: 0,
    sanitasiSiswa: 0
  })
  const handleChangeImage = useCallback((index: number) => {
    setIndexImage(index)
  }, [])

  useEffect(() => {    
    if (detailSekolah) {
      let totalGuru: number = 0
      let guruLaki: number = detailSekolah?.details?.ptk_guru_l || 0
      let guruPerempuan: number = detailSekolah?.details?.ptk_guru_p || 0
      let guruStrata: number = 0
      let guruDiploma: number = 0
      let guruSlta: number = 0
      let guruLainnya: number = 0
      let siswaLaki: number = 0
      let siswaPerempuan: number = 0
      let rombel: number = 0
      
      let ruangKelas: number = 0
      let laboratorium: number = 0
      let perpustakaan: number = 0
      let sanitasiSiswa: number = 0

      for (let key in detailSekolah.details) {
        if (key.includes('guru')) {
          totalGuru += detailSekolah.details[key]
          if (key.includes('ptk_guru_s')) guruStrata += detailSekolah.details[key]
          else if (key.includes('ptk_guru_d')) guruDiploma += detailSekolah.details[key]
          else if (key.includes('ptk_guru_kurang')) guruSlta += detailSekolah.details[key]
          else guruLainnya += detailSekolah.details[key]
        }
        if (key.includes('pd_tkt')) {
          let keySiswa = key.split("_")
          if (keySiswa[keySiswa.length - 1 ] === "l") siswaLaki += detailSekolah.details[key]
          else siswaPerempuan += detailSekolah.details[key]
        }
        if (key.includes('rombel')) rombel += detailSekolah.details[key]

        if (key.includes('ruang_kelas')) ruangKelas += detailSekolah.details[key]
        if (key.includes('laboratorium')) laboratorium += detailSekolah.details[key]
        if (key.includes('perpustakaan')) perpustakaan += detailSekolah.details[key]
      }

      setTotalGuru({
        total: totalGuru,
        laki: guruLaki,
        perempuan: guruPerempuan,
        strata: guruStrata,
        diploma: guruDiploma,
        slta: guruSlta,
        lainnya: guruLainnya
      })
      setTotalSiswa({
        ...totalSiswa,
        laki: siswaLaki,
        perempuan: siswaPerempuan,
        rombel: rombel
      })
      setRuang({
        ...ruang,
        kelas: ruangKelas,
        laboratorium: laboratorium,
        perpustakaan: perpustakaan,
        sanitasiSiswa: sanitasiSiswa
      })
    }
  }, [detailSekolah])

  useEffect(() => {
    if (dataGuru) {
    }
  }, [dataOperator, dataGuru, dataPendidikanGuru, dataRataRataHonorer, dataListGuru])

  return (
    <Fragment>
      <div className='row mb-7'>
        <div className='col-md-12'>
          <div className='row gy-5 g-xl-8'>
            <div className='col-xl-4'>
              <div className='card card-custom gutter-b card-stretch'>
                <div className='card-header align-items-center border-0 mt-4'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='fw-bolder mb-2 text-dark'>{detailSekolah?.details?.operator}</span>
                    <span className='text-muted fw-bold fs-7'>Opertor Sekolah</span>
                  </h3>

                  <div className='card-toolbar'>
                    <button className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'>
                      <span className='svg-icon svg-icon-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24px'
                          height='24px'
                          viewBox='0 0 24 24'
                        >
                          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                            <rect x='5' y='5' width='5' height='5' rx='1' fill='#000000' />
                            <rect
                              x='14'
                              y='5'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='5'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='14'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div className='col-xl-12 border-2 border-bottom'></div>
                </div>
                <div className='card-body pt-5'>
                  <div className='d-flex align-items-sm-center mb-7'>
                    <div className='symbol symbol-50px me-5'>
                      <span className='symbol-label'>
                        <img
                          style={{height: '30px', width: '30px'}}
                          src={'/media/sekolah/guru.png'}
                        />
                      </span>
                    </div>

                    <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                      <div className='flex-grow-1 me-2'>
                        <span className='text-gray-800 fs-6 fw-bolder'>Guru</span>
                        <span className='text-muted fw-bold d-block fs-7'>Pengajar</span>
                      </div>
                      <span className='badge badge-light fw-bolder my-2'>
                        {totalGuru.total}
                      </span>
                    </div>
                  </div>

                  <div className='d-flex align-items-sm-center mb-7'>
                    <div className='symbol symbol-50px me-5'>
                      <span className='symbol-label'>
                        <img
                          style={{height: '30px', width: '30px'}}
                          src={'/media/sekolah/students.png'}
                        />
                      </span>
                    </div>

                    <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                      <div className='flex-grow-1 me-2'>
                        <span className='text-gray-800 fs-6 fw-bolder'>Rombongan</span>
                        <span className='text-muted fw-bold d-block fs-7'>Belajar</span>
                      </div>
                      <span className='badge badge-light fw-bolder my-2'>
                        {totalSiswa.rombel}
                      </span>
                    </div>
                  </div>

                  <div className='d-flex align-items-sm-center mb-7'>
                    <div className='symbol symbol-50px me-5'>
                      <span className='symbol-label'>
                        <img
                          style={{height: '30px', width: '30px'}}
                          src={'/media/sekolah/siswa.jpg'}
                        />
                      </span>
                    </div>

                    <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                      <div className='flex-grow-1 me-2'>
                        <span className='text-gray-800 fs-6 fw-bolder'>Siswa</span>
                        <span className='text-muted fw-bold d-block fs-7'>Laki-laki</span>
                      </div>
                      <span className='badge badge-light fw-bolder my-2'>
                        {totalSiswa.laki}
                      </span>
                    </div>
                  </div>

                  <div className='d-flex align-items-sm-center mb-7'>
                    <div className='symbol symbol-50px me-5'>
                      <span className='symbol-label'>
                        <img
                          style={{height: '30px', width: '30px'}}
                          src={'/media/sekolah/siswi.png'}
                        />
                      </span>
                    </div>

                    <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                      <div className='flex-grow-1 me-2'>
                        <span className='text-gray-800 fs-6 fw-bolder'>Siswa</span>
                        <span className='text-muted fw-bold d-block fs-7'>Perempuan</span>
                      </div>
                      <span className='badge badge-light fw-bolder my-2'>
                        {totalSiswa.perempuan}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xl-8'>
              <div className='card card-custom gutter-b card-stretch'>
                <div className='card-body'>
                  <div className='wrapper-profile-school-image-slider'>
                    <img
                      className='content-image'
                      src={`${dataImageDummy[indexImage].image}`}
                      alt='Img'
                    />

                    <div className='wrapper-list-image-slide'>
                      <div className='wrapper-item'>
                        {dataImageDummy.map((d, i) => {
                          return (
                            <div className={`item`} key={i} onClick={() => handleChangeImage(i)}>
                              <img width='100%' height='100%' src={d.image} alt='Img' />
                              <div className={`${i === indexImage && 'active'}`}></div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-7'>
        <div className='col-md-12'>
          <div className='row gy-5 g-xl-8'>
            {/* <div className='col-xl-4'>
              <div className='card card-custom gutter-b card-stretch'>
                <div className='card-header align-items-center border-0 mt-4'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='fw-bolder mb-2 text-dark'>Data Guru</span>
                    <span className='text-muted fw-bold fs-7'>Status Pernikahan</span>
                  </h3>

                  <div className='card-toolbar'>
                    <button className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'>
                      <span className='svg-icon svg-icon-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24px'
                          height='24px'
                          viewBox='0 0 24 24'
                        >
                          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                            <rect x='5' y='5' width='5' height='5' rx='1' fill='#000000' />
                            <rect
                              x='14'
                              y='5'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='5'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='14'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>

                <div className='card-body pt-5'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='d-flex align-items-sm-center mb-7'>
                        <div className='symbol symbol-50px me-5'>
                          <span className='symbol-label bg-light-info'>
                            <span className='svg-icon svg-icon-2x svg-icon-info'>
                              <img
                                style={{height: '30px', width: '30px'}}
                                src={'/media/icons/duotune/art/art007.svg'}
                              />
                            </span>
                          </span>
                        </div>

                        <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                          <div className='flex-grow-1 me-2'>
                            <span className='text-gray-800 fs-6 fw-bolder'>
                              {totalGuru.laki}
                            </span>
                            <span className='text-muted fw-bold d-block fs-7'>Guru Laki-laki</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='d-flex align-items-sm-center mb-7'>
                        <div className='symbol symbol-50px me-5'>
                          <span className='symbol-label bg-light-danger'>
                            <img
                              style={{height: '30px', width: '30px'}}
                              src={'/media/icons/duotune/abstract/abs027.svg'}
                            />
                          </span>
                        </div>

                        <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                          <div className='flex-grow-1 me-2'>
                            <span className='text-gray-800 fs-6 fw-bolder'>
                              {totalGuru.perempuan}
                            </span>
                            <span className='text-muted fw-bold d-block fs-7'>Guru Perempuan</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='d-flex align-items-sm-center mb-7'>
                        <div className='symbol symbol-50px me-5'>
                          <span className='symbol-label bg-light-success'>
                            <img
                              style={{height: '30px', width: '30px'}}
                              src={'/media/icons/duotune/ecommerce/ecm002.svg'}
                            />
                          </span>
                        </div>

                        <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                          <div className='flex-grow-1 me-2'>
                            <span className='text-gray-800 fs-6 fw-bolder'>
                              {dataGuru.total_guru_married}
                            </span>
                            <span className='text-muted fw-bold d-block fs-7'>Menikah</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='d-flex align-items-sm-center mb-7'>
                        <div className='symbol symbol-50px me-5'>
                          <span className='symbol-label bg-light-primary'>
                            <span className='svg-icon svg-icon-2x svg-icon-primary'>
                              <img
                                style={{height: '30px', width: '30px'}}
                                src={'/media/icons/duotune/ecommerce/ecm010.svg'}
                              />
                            </span>
                          </span>
                        </div>

                        <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                          <div className='flex-grow-1 me-2'>
                            <span className='text-gray-800 fs-6 fw-bolder'>
                              {dataGuru.total_guru_no_married}
                            </span>
                            <span className='text-muted fw-bold d-block fs-7'>Belum Menikah</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xl-4'>
              <div className='card card-custom gutter-b card-stretch'>
                <div className='card-header align-items-center border-0 mt-4'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='fw-bolder mb-2 text-dark'>Data Pendidikan Guru</span>
                    <span className='text-muted fw-bold fs-7'>Gelar Pendidikan</span>
                  </h3>

                  <div className='card-toolbar'>
                    <button className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'>
                      <span className='svg-icon svg-icon-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24px'
                          height='24px'
                          viewBox='0 0 24 24'
                        >
                          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                            <rect x='5' y='5' width='5' height='5' rx='1' fill='#000000' />
                            <rect
                              x='14'
                              y='5'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='5'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='14'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
                <div className='card-body pt-5'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='d-flex align-items-sm-center mb-7'>
                        <div className='symbol symbol-50px me-5'>
                          <span className='symbol-label bg-light-info'>
                            <span className='svg-icon svg-icon-2x svg-icon-info'>
                              <img
                                style={{height: '30px', width: '30px'}}
                                src={'/media/icons/duotune/art/art007.svg'}
                              />
                            </span>
                          </span>
                        </div>

                        <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                          <div className='flex-grow-1 me-2'>
                            <span className='text-gray-800 fs-6 fw-bolder'>
                              {totalGuru.strata}
                            </span>
                            <span className='text-muted fw-bold d-block fs-7'>Strata</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='d-flex align-items-sm-center mb-7'>
                        <div className='symbol symbol-50px me-5'>
                          <span className='symbol-label bg-light-danger'>
                            <img
                              style={{height: '30px', width: '30px'}}
                              src={'/media/icons/duotune/abstract/abs027.svg'}
                            />
                          </span>
                        </div>

                        <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                          <div className='flex-grow-1 me-2'>
                            <span className='text-gray-800 fs-6 fw-bolder'>
                              {totalGuru.diploma}
                            </span>
                            <span className='text-muted fw-bold d-block fs-7'>Diploma</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='d-flex align-items-sm-center mb-7'>
                        <div className='symbol symbol-50px me-5'>
                          <span className='symbol-label bg-light-success'>
                            <img
                              style={{height: '30px', width: '30px'}}
                              src={'/media/icons/duotune/ecommerce/ecm002.svg'}
                            />
                          </span>
                        </div>

                        <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                          <div className='flex-grow-1 me-2'>
                            <span className='text-gray-800 fs-6 fw-bolder'>
                              {totalGuru.slta}
                            </span>
                            <span className='text-muted fw-bold d-block fs-7'>SLTA</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='d-flex align-items-sm-center mb-7'>
                        <div className='symbol symbol-50px me-5'>
                          <span className='symbol-label bg-light-primary'>
                            <span className='svg-icon svg-icon-2x svg-icon-primary'>
                              <img
                                style={{height: '30px', width: '30px'}}
                                src={'/media/icons/duotune/ecommerce/ecm010.svg'}
                              />
                            </span>
                          </span>
                        </div>

                        <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                          <div className='flex-grow-1 me-2'>
                            <span className='text-gray-800 fs-6 fw-bolder'>
                              {totalGuru.lainnya}
                            </span>
                            <span className='text-muted fw-bold d-block fs-7'>Lainnya</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xl-4'>
              <div className='card card-custom gutter-b card-stretch'>
                <div className='card-header align-items-center border-0 mt-4'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='fw-bolder mb-2 text-dark'>Rata-Rata Honorer</span>
                    <span className='text-muted fw-bold fs-7'>Guru dan Pegawai</span>
                  </h3>

                  <div className='card-toolbar'>
                    <button className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'>
                      <span className='svg-icon svg-icon-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24px'
                          height='24px'
                          viewBox='0 0 24 24'
                        >
                          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                            <rect x='5' y='5' width='5' height='5' rx='1' fill='#000000' />
                            <rect
                              x='14'
                              y='5'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='5'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='14'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
                <div className='card-body pt-5'>
                  <div className='d-flex align-items-sm-center mb-7'>
                    <div className='symbol symbol-50px me-5'>
                      <span className='symbol-label bg-lighten'>
                        <span className='svg-icon svg-icon-1'>
                          <img
                            style={{height: '30px', width: '30px'}}
                            src={'/media/icons/duotune/maps/map004.svg'}
                          />
                        </span>
                      </span>
                    </div>

                    <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                      <div className='flex-grow-1 me-2'>
                        <span className='text-gray-800 fs-6 fw-bolder'>Kepsek</span>
                        <span className='text-muted fw-bold d-block fs-7'>Wakil Kepsek</span>
                      </div>
                      <span className='fw-bolder my-2'>
                        Rp {ReplaceToRupiah(dataRataRataHonorer.head_master_avg)}
                      </span>
                    </div>
                  </div>

                  <div className='d-flex align-items-sm-center mb-7'>
                    <div className='symbol symbol-50px me-5'>
                      <span className='symbol-label bg-lighten'>
                        <span className='svg-icon svg-icon-1'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24px'
                            height='24px'
                            viewBox='0 0 24 24'
                            className='mh-50px'
                          >
                            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                              <rect x='5' y='5' width='5' height='5' rx='1' fill='#000000'></rect>
                              <rect
                                x='14'
                                y='5'
                                width='5'
                                height='5'
                                rx='1'
                                fill='#000000'
                                opacity='0.3'
                              ></rect>
                              <rect
                                x='5'
                                y='14'
                                width='5'
                                height='5'
                                rx='1'
                                fill='#000000'
                                opacity='0.3'
                              ></rect>
                              <rect
                                x='14'
                                y='14'
                                width='5'
                                height='5'
                                rx='1'
                                fill='#000000'
                                opacity='0.3'
                              ></rect>
                            </g>
                          </svg>
                        </span>
                      </span>
                    </div>

                    <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                      <div className='flex-grow-1 me-2'>
                        <span className='text-gray-800 fs-6 fw-bolder'>Guru</span>
                        <span className='text-muted fw-bold d-block fs-7'>Pengajar</span>
                      </div>
                      <span className='fw-bolder my-2'>
                        Rp {ReplaceToRupiah(dataRataRataHonorer.teacher_avg)}
                      </span>
                    </div>
                  </div>

                  <div className='d-flex align-items-sm-center mb-7'>
                    <div className='symbol symbol-50px me-5'>
                      <span className='symbol-label bg-lighten'>
                        <span className='svg-icon svg-icon-1'>
                          <img
                            style={{height: '30px', width: '30px'}}
                            src={'/media/icons/duotune/electronics/elc005.svg'}
                          />
                        </span>
                      </span>
                    </div>

                    <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                      <div className='flex-grow-1 me-2'>
                        <span className='text-gray-800 fs-6 fw-bolder'>Pegawai</span>
                        <span className='text-muted fw-bold d-block fs-7'>Sekolah</span>
                      </div>
                      <span className='fw-bolder my-2'>
                        Rp {ReplaceToRupiah(dataRataRataHonorer.employee_avg)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* Kurikulum */}
            <div className='col-xl-6'>
              <div className='card card-custom gutter-b card-stretch pb-10 h-400px'>
                <div className='card-header align-items-center border-0 py-4'>
                  <h3 className='card-title'>
                    <span className='fw-bolder mb-2 text-dark'>Kurikulum</span>
                  </h3>

                  <div className='card-toolbar'>
                    <button className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'>
                      <span className='svg-icon svg-icon-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24px'
                          height='24px'
                          viewBox='0 0 24 24'
                        >
                          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                            <rect x='5' y='5' width='5' height='5' rx='1' fill='#000000' />
                            <rect
                              x='14'
                              y='5'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='5'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='14'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div className='col-xl-12 border-2 border-bottom'></div>
                </div>

                <div className='card-body py-5 position-relative'>
                  <div className='d-flex flex-column mb-7 z-index-2'>
                    <span className='fw-bolder fs-6 mb-1 z-index-2'>Kurikulum :</span>
                    <span className='text-gray-800 fs-7 z-index-2'>
                      {detailSekolah?.details?.Kurikulum || "-"}
                    </span>
                  </div>
                  <div className='d-flex flex-column mb-7 z-index-2'>
                    <span className='fw-bolder fs-6 mb-1 z-index-2'>Penyelenggaraan</span>
                    <span className='text-gray-800 fs-7'>
                      {detailSekolah?.details?.waktu_penyelenggaraan || "-"}
                    </span>
                  </div>
                  <div className='d-flex flex-column mb-7 z-index-2'>
                    <span className='fw-bolder fs-6 mb-1 z-index-2'>Menajemen Berbasis Sekolah</span>
                    <span className='text-gray-800 fs-7 z-index-2'>
                      {detailSekolah?.details?.mbs == '1' ? (
                        <span>
                          <i className='fa fa-check-circle text-dark fs-4' aria-hidden='true'></i>{' '}
                          Sudah
                        </span>
                      ) : (
                        <span> Belum </span>
                      )}
                    </span>
                  </div>
                  <div className='d-flex flex-column mb-7 z-index-2'>
                    <span className='fw-bolder fs-6 mb-1 z-index-2'>Semester Data</span>
                    <span className='text-gray-800 fs-7 z-index-2'>2021/2022-2</span>
                  </div>
                  <span
                    className='position-absolute z-index-1'
                    style={{
                      bottom: 36,
                      right: 11,
                    }}
                  >
                    <svg
                      width='301'
                      height='180'
                      viewBox='0 0 301 180'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M292.643 41.8134L161.476 1.64179C154.328 -0.547262 146.617 -0.547262 139.474 1.64179L8.30198 41.8134C-2.76733 45.2024 -2.76733 59.7945 8.30198 63.1836L31.1695 70.1867C26.1521 76.3694 23.0673 83.9116 22.7617 92.1709C18.2333 94.7584 15.0451 99.4271 15.0451 105C15.0451 110.054 17.7161 114.305 21.5626 117.024L9.55751 170.873C8.51359 175.556 12.0874 180 16.8979 180H43.2827C48.0979 180 51.6717 175.556 50.6278 170.873L38.6227 117.024C42.4692 114.305 45.1401 110.054 45.1401 105C45.1401 99.5771 42.0977 95.0396 37.7762 92.4053C38.1336 85.3647 41.745 79.1397 47.5054 75.1929L139.469 103.36C143.729 104.663 151.902 106.29 161.471 103.36L292.643 63.1883C303.717 59.7945 303.717 45.2071 292.643 41.8134ZM165.892 117.699C152.476 121.805 141.044 119.536 135.049 117.699L66.8555 96.8162L60.1876 150C60.1876 166.57 100.609 180 150.473 180C200.336 180 240.757 166.57 240.757 150L234.09 96.8115L165.892 117.699Z'
                        fill='#EBEBEB'
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>

            {/* Internet dan Bangunan */}
            <div className='col-xl-6'>
              <div className='card card-custom gutter-b card-stretch h-400px'>
                <div className='card-header align-items-center border-0 py-4'>
                  <h3 className='card-title'>
                    <span className='fw-bolder mb-2 text-dark'>Internet dan Bangunan</span>
                  </h3>

                  <div className='card-toolbar'>
                    <button className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'>
                      <span className='svg-icon svg-icon-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24px'
                          height='24px'
                          viewBox='0 0 24 24'
                        >
                          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                            <rect x='5' y='5' width='5' height='5' rx='1' fill='#000000' />
                            <rect
                              x='14'
                              y='5'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='5'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='14'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div className='col-xl-12 border-2 border-bottom'></div>
                </div>

                <div className='card-body pt-5 position-relative'>
                  <div className='d-flex flex-column mb-7 z-index-2'>
                    <span className='fw-bolder fs-6 mb-1'>Akses Internet</span>
                    <span className='text-gray-800 fs-7'>
                      {detailSekolah?.details?.akses_internet_1} - {detailSekolah?.details?.akses_internet_2}
                    </span>
                  </div>
                  <div className='d-flex flex-column mb-7 z-index-2'>
                    <span className='fw-bolder fs-6 mb-1'>Sumber Listrik</span>
                    <span className='text-gray-800 fs-7'>
                      {detailSekolah?.details?.sumber_listrik || "-"}
                    </span>
                  </div>
                  <div className='d-flex flex-column mb-7 z-index-2'>
                    <span className='fw-bolder fs-6 mb-1'>Daya Listrik</span>
                    <span className='text-gray-800 fs-7'>
                      {detailSekolah?.details?.daya_listrik || "-"}
                    </span>
                  </div>
                  <div className='d-flex flex-column mb-7 z-index-2'>
                    <span className='fw-bolder fs-6 mb-1'>Luas Tanan</span>
                    <span className='text-gray-800 fs-7'>
                      {(
                        parseInt(detailSekolah?.details?.luas_tanah_bukan_milik) +
                        parseInt(detailSekolah?.details?.luas_tanah_milik)
                      ).toLocaleString('id-ID') || 0}{' '}
                      M&#178;
                    </span>
                  </div>

                  <span
                    className='position-absolute z-index-1'
                    style={{
                      bottom: 36,
                      right: 36,
                    }}
                  >
                    <svg
                      width='255'
                      height='180'
                      viewBox='0 0 255 180'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M252.797 49.3714C182.254 -16.4692 72.5408 -16.4451 2.02631 49.3714C-0.625453 51.8464 -0.677214 56.0371 1.88695 58.6045L15.52 72.2531C17.9647 74.704 21.8986 74.7562 24.4388 72.4058C82.5386 18.6951 172.276 18.683 230.388 72.4058C232.928 74.7562 236.862 74.7 239.307 72.2531L252.94 58.6045C255.5 56.0371 255.448 51.8464 252.797 49.3714ZM127.411 128.571C113.336 128.571 101.929 140.083 101.929 154.286C101.929 168.489 113.336 180 127.411 180C141.487 180 152.894 168.489 152.894 154.286C152.894 140.083 141.487 128.571 127.411 128.571ZM208.107 94.9862C162.215 54.0321 92.5564 54.0763 46.716 94.9862C43.9687 97.437 43.8811 101.692 46.4891 104.287L60.2018 117.944C62.5907 120.323 66.437 120.483 68.9812 118.266C102.407 89.108 152.492 89.1723 185.838 118.266C188.382 120.483 192.228 120.327 194.617 117.944L208.33 104.287C210.942 101.692 210.85 97.433 208.107 94.9862Z'
                        fill='black'
                        fillOpacity='0.08'
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>

            {/* Fasilitas */}
            <div className='col-xl-4'>
              <div className='card card-custom gutter-b card-stretch h-475px'>
                <div className='card-header align-items-center border-0 py-4'>
                  <h3 className='card-title'>
                    <span className='fw-bolder mb-2 text-dark'>Fasilitas</span>
                  </h3>

                  <div className='card-toolbar'>
                    <button className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'>
                      <span className='svg-icon svg-icon-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24px'
                          height='24px'
                          viewBox='0 0 24 24'
                        >
                          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                            <rect x='5' y='5' width='5' height='5' rx='1' fill='#000000' />
                            <rect
                              x='14'
                              y='5'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='5'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='14'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div className='col-xl-12 border-2 border-bottom'></div>
                </div>

                <div className='card-body pt-5 position-relative'>
                  <div className='d-flex flex-column mb-7 z-index-2'>
                    <span className='fw-bolder fs-6 mb-1'>Ruang Kelas</span>
                    <span className='text-gray-800 fs-7'>{ruang.kelas}</span>
                  </div>
                  <div className='d-flex flex-column mb-7 z-index-2'>
                    <span className='fw-bolder fs-6 mb-1'>Laboratorium</span>
                    <span className='text-gray-800 fs-7'>{ruang.laboratorium}</span>
                  </div>
                  <div className='d-flex flex-column mb-7 z-index-2'>
                    <span className='fw-bolder fs-6 mb-1'>Perpustakaan</span>
                    <span className='text-gray-800 fs-7'>{ruang.perpustakaan}</span>
                  </div>
                  <div className='d-flex flex-column mb-7 z-index-2'>
                    <span className='fw-bolder fs-6 mb-1'>Sanitasi Siswa</span>
                    <span className='text-gray-800 fs-7'>{ruang.sanitasiSiswa}</span>
                  </div>

                  <span
                    className='position-absolute z-index-1'
                    style={{
                      bottom: 28,
                      right: 19,
                    }}
                  >
                    <svg
                      width='175'
                      height='200'
                      viewBox='0 0 175 200'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M170.312 187.5H162.5V9.375C162.5 4.19727 158.303 0 153.125 0H21.875C16.6973 0 12.5 4.19727 12.5 9.375V187.5H4.6875C2.09883 187.5 0 189.599 0 192.188V200H175V192.188C175 189.599 172.901 187.5 170.312 187.5ZM50 29.6875C50 27.0988 52.0988 25 54.6875 25H70.3125C72.9012 25 75 27.0988 75 29.6875V45.3125C75 47.9012 72.9012 50 70.3125 50H54.6875C52.0988 50 50 47.9012 50 45.3125V29.6875ZM50 67.1875C50 64.5988 52.0988 62.5 54.6875 62.5H70.3125C72.9012 62.5 75 64.5988 75 67.1875V82.8125C75 85.4012 72.9012 87.5 70.3125 87.5H54.6875C52.0988 87.5 50 85.4012 50 82.8125V67.1875ZM70.3125 125H54.6875C52.0988 125 50 122.901 50 120.312V104.688C50 102.099 52.0988 100 54.6875 100H70.3125C72.9012 100 75 102.099 75 104.688V120.312C75 122.901 72.9012 125 70.3125 125ZM100 187.5H75V154.688C75 152.099 77.0988 150 79.6875 150H95.3125C97.9012 150 100 152.099 100 154.688V187.5ZM125 120.312C125 122.901 122.901 125 120.312 125H104.688C102.099 125 100 122.901 100 120.312V104.688C100 102.099 102.099 100 104.688 100H120.312C122.901 100 125 102.099 125 104.688V120.312ZM125 82.8125C125 85.4012 122.901 87.5 120.312 87.5H104.688C102.099 87.5 100 85.4012 100 82.8125V67.1875C100 64.5988 102.099 62.5 104.688 62.5H120.312C122.901 62.5 125 64.5988 125 67.1875V82.8125ZM125 45.3125C125 47.9012 122.901 50 120.312 50H104.688C102.099 50 100 47.9012 100 45.3125V29.6875C100 27.0988 102.099 25 104.688 25H120.312C122.901 25 125 27.0988 125 29.6875V45.3125Z'
                        fill='#EBEBEB'
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>

            {/* Maps */}
            <div className='col-xl-8'>
              <div className='card card-custom gutter-b card-stretch h-475px'>
                <div className='card-header align-items-center border-0 py-4'>
                  <h3 className='card-title'>
                    <span className='fw-bolder mb-2 text-dark'>Maps</span>
                  </h3>

                  <div className='card-toolbar'>
                    <button className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'>
                      <span className='svg-icon svg-icon-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24px'
                          height='24px'
                          viewBox='0 0 24 24'
                        >
                          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                            <rect x='5' y='5' width='5' height='5' rx='1' fill='#000000' />
                            <rect
                              x='14'
                              y='5'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='5'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='14'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div className='col-xl-12 border-2 border-bottom'></div>
                </div>

                <div className='card-body pt-5'>
                  {detailSekolah &&
                  detailSekolah?.latitude &&
                  detailSekolah?.longitude ? (
                    <MapContainer
                      style={{height: '100%', width: '100%', zIndex: '0'}}
                      zoom={15}
                      maxZoom={15}
                      center={[detailSekolah?.latitude, detailSekolah?.longitude]}
                    >
                      <TileLayer
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      ></TileLayer>
                      <Marker
                        position={[detailSekolah?.latitude, detailSekolah?.longitude]}
                      ></Marker>
                    </MapContainer>
                  ) : null}
                </div>
              </div>
            </div>

            {/* Prestasi */}
            {/* <div className='col-xl-6'>
              <div className='card card-custom gutter-b card-stretch'>
                <div className='card-header align-items-center border-0 py-4'>
                  <h3 className='card-title'>
                    <span className='fw-bolder mb-2 text-dark'>Prestasi</span>
                  </h3>

                  <div className='card-toolbar'>
                    <button className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'>
                      <span className='svg-icon svg-icon-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24px'
                          height='24px'
                          viewBox='0 0 24 24'
                        >
                          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                            <rect x='5' y='5' width='5' height='5' rx='1' fill='#000000' />
                            <rect
                              x='14'
                              y='5'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='5'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='14'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div className='col-xl-12 border-2 border-bottom'></div>
                </div>

                <div className='card-body pt-0'>
                  <div className='table-responsive'>
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                      <thead>
                        <tr className='fw-bolder text-muted'>
                          <th>No</th>
                          <th className='min-w-100px'>Prestasi</th>
                          <th className='min-w-100px'>Tingkat</th>
                          <th className='min-w-100px'>Tanggal</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Juara Desch Voli</td>
                          <td>Kota</td>
                          <td>2022-08-03</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div> */}

            {/* Visis & Misi */}
            {/* <div className='col-xl-6'>
              <div className='card card-custom gutter-b card-stretch'>
                <div className='card-header align-items-center border-0 py-4'>
                  <h3 className='card-title'>
                    <span className='fw-bolder mb-2 text-dark'>Visi & Misi</span>
                  </h3>

                  <div className='card-toolbar'>
                    <button className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'>
                      <span className='svg-icon svg-icon-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24px'
                          height='24px'
                          viewBox='0 0 24 24'
                        >
                          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                            <rect x='5' y='5' width='5' height='5' rx='1' fill='#000000' />
                            <rect
                              x='14'
                              y='5'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='5'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='14'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div className='col-xl-12 border-2 border-bottom'></div>
                </div>

                <div className='card-body pt-5'>
                  <div className='w-100 bg-primary text-center rounded mb-5 py-3 px-3'>
                    <p className='fs-5 fw-bolder text-white mb-5'>Visi</p>
                    <p className='text-white fs-7 fw-bold'>
                      “Madrasah yang Unggul dalam Prestasi, Inovatif dan Berakhlakulkarimah”
                    </p>
                  </div>
                  <div className='w-100 bg-success text-center rounded mb-5 py-3 px-3'>
                    <p className='fs-5 fw-bolder text-white mb-5'>Misi</p>
                    <ol className='text-start lh-base'>
                      <li className='text-white fw-bold fs-7 mb-1'>
                        Menyelenggarakan Pendidikan yang Kondusif Guna Menghasilkan Lulusan
                        Berkualitas Akademik dan Non-Akademik.
                      </li>
                      <li className='text-white fw-bold fs-7 mb-1'>
                        Mengembangkan SDM Madrasah yang Kompeten.
                      </li>
                      <li className='text-white fw-bold fs-7 mb-1'>
                        Menciptakan dan Memelihara Lingkungan yang Sehat, kondusif dan Harmonis.
                      </li>
                      <li className='text-white fw-bold fs-7 mb-1'>
                        Mengembangkan Potensi Madrasah yang Kreatif dan Mandiri.
                      </li>
                      <li className='text-white fw-bold fs-7 mb-1'>
                        Mengembangkan Potensi Madrasah yang Kreatif dan Mandiri.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div> */}

            {/* Sambutan Kepala Sekolah */}
            {/* <div className='col-xl-6'>
              <div className='card card-custom gutter-b card-stretch'>
                <div className='card-header align-items-center border-0 py-4'>
                  <h3 className='card-title'>
                    <span className='fw-bolder mb-2 text-dark'>Sambutan Kepala Sekolah</span>
                  </h3>

                  <div className='card-toolbar'>
                    <button className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'>
                      <span className='svg-icon svg-icon-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24px'
                          height='24px'
                          viewBox='0 0 24 24'
                        >
                          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                            <rect x='5' y='5' width='5' height='5' rx='1' fill='#000000' />
                            <rect
                              x='14'
                              y='5'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='5'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='14'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div className='col-xl-12 border-2 border-bottom'></div>
                </div>

                <div className='card-body py-5'>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo laboriosam iste odit
                  beatae dicta excepturi atque quod maiores in explicabo incidunt harum, deserunt
                  vero animi repellendus, obcaecati esse, voluptas odio!
                </div>
              </div>
            </div> */}

            {/* Struktur Organisasi */}
            {/* <div className='col-xl-6'>
              <div className='card card-custom gutter-b card-stretch'>
                <div className='card-header align-items-center border-0 py-4'>
                  <h3 className='card-title'>
                    <span className='fw-bolder mb-2 text-dark'>Struktur Organisasi</span>
                  </h3>

                  <div className='card-toolbar'>
                    <button className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'>
                      <span className='svg-icon svg-icon-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24px'
                          height='24px'
                          viewBox='0 0 24 24'
                        >
                          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                            <rect x='5' y='5' width='5' height='5' rx='1' fill='#000000' />
                            <rect
                              x='14'
                              y='5'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='5'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='14'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div className='col-xl-12 border-2 border-bottom'></div>
                </div>

                <div className='card-body pt-0'>
                  <div className='table-responsive'>
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                      <thead>
                        <tr className='fw-bolder text-muted'>
                          <th>No</th>
                          <th className='text-start'>Nama</th>
                          <th className='text-center'>Jabatan</th>
                          <th className='text-center'>Golongna</th>
                          <th className='text-center'>Status</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td className='fs-7'>1</td>
                          <td className='fs-7 text-start'>Ade Ansorulloh, S.Ag, M.Pd</td>
                          <td className='fs-7 text-center'>Kepala Sekolah</td>
                          <td className='fs-7 text-center'>Penata Tk.1/III.d</td>
                          <td className='fs-7 text-center'>PNS</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div> */}

            {/* Kepemimpinan dari masa ke masa */}
            {/* <div className='col-xl-6'>
              <div className='card card-custom gutter-b card-stretch'>
                <div className='card-header align-items-center border-0 py-4'>
                  <h3 className='card-title'>
                    <span className='fw-bolder mb-2 text-dark'>Kepemimpinan dari masa ke masa</span>
                  </h3>

                  <div className='card-toolbar'>
                    <button className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'>
                      <span className='svg-icon svg-icon-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24px'
                          height='24px'
                          viewBox='0 0 24 24'
                        >
                          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                            <rect x='5' y='5' width='5' height='5' rx='1' fill='#000000' />
                            <rect
                              x='14'
                              y='5'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='5'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='14'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div className='col-xl-12 border-2 border-bottom'></div>
                </div>

                <div className='card-body pt-0'>
                  <div className='table-responsive'>
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                      <thead>
                        <tr className='fw-bolder text-muted'>
                          <th>Tahun</th>
                          <th className='text-start'>Nama</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td className='fs-7'>2022</td>
                          <td className='fs-7 text-start'>Ade Ansorulloh, S.Ag, M.Pd</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div> */}

            {/* Grafik siswa */}
            {/* <div className='col-xl-6'>
              <div className='card card-custom gutter-b card-stretch'>
                <div className='card-header align-items-center border-0 py-4'>
                  <h3 className='card-title'>
                    <span className='fw-bolder mb-2 text-dark'>Grafik Siswa</span>
                  </h3>

                  <div className='card-toolbar'>
                    <button className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'>
                      <span className='svg-icon svg-icon-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24px'
                          height='24px'
                          viewBox='0 0 24 24'
                        >
                          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                            <rect x='5' y='5' width='5' height='5' rx='1' fill='#000000' />
                            <rect
                              x='14'
                              y='5'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='5'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <rect
                              x='14'
                              y='14'
                              width='5'
                              height='5'
                              rx='1'
                              fill='#000000'
                              opacity='0.3'
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div className='col-xl-12 border-2 border-bottom'></div>
                </div>

                <div className='card-body pt-0'>
                  <Bar options={options} data={data} />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* table data gaji guru */}
      {/* <div className='row mb-7'>
        <div className='col-md-12'>
          <div className='card card-custom gutter-b card-stretch'>
            <div className='card-header align-items-center border-0 mt-4'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='fw-bolder mb-2 text-dark'>Tabel Data Gaji Guru</span>
                <span className='text-muted fw-bold fs-7'>Data Guru Honorer</span>
              </h3>

              <div className='card-toolbar'>
                <button className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'>
                  <span className='svg-icon svg-icon-2'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24px'
                      height='24px'
                      viewBox='0 0 24 24'
                    >
                      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                        <rect x='5' y='5' width='5' height='5' rx='1' fill='#000000' />
                        <rect
                          x='14'
                          y='5'
                          width='5'
                          height='5'
                          rx='1'
                          fill='#000000'
                          opacity='0.3'
                        />
                        <rect
                          x='5'
                          y='14'
                          width='5'
                          height='5'
                          rx='1'
                          fill='#000000'
                          opacity='0.3'
                        />
                        <rect
                          x='14'
                          y='14'
                          width='5'
                          height='5'
                          rx='1'
                          fill='#000000'
                          opacity='0.3'
                        />
                      </g>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
            <div className='card-body py-3'>
              <div className='table-responsive'>
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      <th className='w-25px'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input className='form-check-input' type='checkbox' />
                        </div>
                      </th>
                      <th className='min-w-100px'>NUPTK</th>
                      <th className='min-w-100px'>Nama Guru</th>
                      <th className='min-w-100px'>Tanggal Masuk</th>
                      <th className='min-w-100px'>Pendidikan</th>
                      <th className='min-w-100px'>Honor Per-Jam</th>
                      <th className='min-w-100px'>Status Pernikahan</th>
                      <th className='min-w-100px text-end'>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {dataListGuru.map((d, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input className='form-check-input' type='checkbox' />
                            </div>
                          </td>
                          <td className='text-dark fw-bolder text-hover-primary fs-6'>{d.nuptk}</td>
                          <td className='text-dark fw-bolder text-hover-primary fs-6'>{d.name}</td>
                          <td className='text-dark fw-bolder text-hover-primary fs-6'>
                            <span className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                              {d.entry_date}
                            </span>
                            <span className='text-muted fw-bold text-muted d-block fs-7'>
                              {d.entry_date_name}
                            </span>
                          </td>
                          <td className='text-dark fw-bolder text-hover-primary fs-6'>
                            {d.level_name}
                          </td>
                          <td className='text-dark fw-bolder text-hover-primary fs-6'>
                            Rp {ReplaceToRupiah(d.hourly_salary)}
                          </td>
                          <td className='text-dark fw-bolder text-hover-primary fs-6'>
                            {d.marital_status === 'Menikah' && (
                              <span className='badge badge-light-success'>{d.marital_status}</span>
                            )}
                            {d.marital_status === 'Belum Menikah' && (
                              <span className='badge badge-light-warning'>{d.marital_status}</span>
                            )}
                          </td>
                          <td className='text-end'>
                            <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                              <span className='svg-icon svg-icon-3'>
                                <img src={'/media/icons/duotune/general/gen019.svg'} />
                              </span>
                            </button>
                            <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                              <span className='svg-icon svg-icon-3'>
                                <img src={'/media/icons/duotune/art/art005.svg'} />
                              </span>
                            </button>
                            <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                              <span className='svg-icon svg-icon-3'>
                                <span className='svg-icon svg-icon-3'>
                                  <img src={'/media/icons/duotune/general/gen027.svg'} />
                                </span>
                              </span>
                            </button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      
      {/* sekolah terdekat */}
      {/* <div className='row'>
        <div className='col-xl-12'>
          <div className='card card-custom gutter-b card-stretch min-h-475px'>
            <div className='card-header align-items-center border-0 py-4'>
              <h3 className='card-title'>
                <span className='fw-bolder mb-2 text-dark'>Sekolah Muhammadiyah Terdekat</span>
              </h3>

              <div className='card-toolbar'>
                <button className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'>
                  <span className='svg-icon svg-icon-2'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24px'
                      height='24px'
                      viewBox='0 0 24 24'
                    >
                      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                        <rect x='5' y='5' width='5' height='5' rx='1' fill='#000000' />
                        <rect
                          x='14'
                          y='5'
                          width='5'
                          height='5'
                          rx='1'
                          fill='#000000'
                          opacity='0.3'
                        />
                        <rect
                          x='5'
                          y='14'
                          width='5'
                          height='5'
                          rx='1'
                          fill='#000000'
                          opacity='0.3'
                        />
                        <rect
                          x='14'
                          y='14'
                          width='5'
                          height='5'
                          rx='1'
                          fill='#000000'
                          opacity='0.3'
                        />
                      </g>
                    </svg>
                  </span>
                </button>
              </div>
              <div className='col-xl-12 border-2 border-bottom'></div>
            </div>

            <div className='card-body pt-5'>
              <div className='row'>
                <div className='col-xl-4 mb-6'>
                  <div className='card card-custom gutter-b card-stretch min-h-100px border border-2'>
                    <div className='card-body p-4'>
                      <p className='fs-5 fw-bolder mb-2'>SMA MUHAMMADIYAH 11 SURABAYA</p>
                      <p className='fs-6 mb-2' style={{color: '#484848'}}>
                        <i className='bi bi-geo-alt-fill text-danger me-2 fs-3'></i> 2.4 km
                      </p>
                      <p className='fs-7' style={{color: '#3F4254'}}>
                        Jl. Raya Sutorejo No.98 - 100, Dukuh Sutorejo, Kec. Mulyorejo, Kota SBY,
                        Jawa Timur 60113
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-4 mb-6'>
                  <div className='card card-custom gutter-b card-stretch min-h-100px border border-2'>
                    <div className='card-body p-4'>
                      <p className='fs-5 fw-bolder mb-2'>SMA MUHAMMADIYAH 11 SURABAYA</p>
                      <p className='fs-6 mb-2' style={{color: '#484848'}}>
                        <i className='bi bi-geo-alt-fill text-danger me-2 fs-3'></i> 2.4 km
                      </p>
                      <p className='fs-7' style={{color: '#3F4254'}}>
                        Jl. Raya Sutorejo No.98 - 100, Dukuh Sutorejo, Kec. Mulyorejo, Kota SBY,
                        Jawa Timur 60113
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-4 mb-6'>
                  <div className='card card-custom gutter-b card-stretch min-h-100px border border-2'>
                    <div className='card-body p-4'>
                      <p className='fs-5 fw-bolder mb-2'>SMA MUHAMMADIYAH 11 SURABAYA</p>
                      <p className='fs-6 mb-2' style={{color: '#484848'}}>
                        <i className='bi bi-geo-alt-fill text-danger me-2 fs-3'></i> 2.4 km
                      </p>
                      <p className='fs-7' style={{color: '#3F4254'}}>
                        Jl. Raya Sutorejo No.98 - 100, Dukuh Sutorejo, Kec. Mulyorejo, Kota SBY,
                        Jawa Timur 60113
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-4 mb-6'>
                  <div className='card card-custom gutter-b card-stretch min-h-100px border border-2'>
                    <div className='card-body p-4'>
                      <p className='fs-5 fw-bolder mb-2'>SMA MUHAMMADIYAH 11 SURABAYA</p>
                      <p className='fs-6 mb-2' style={{color: '#484848'}}>
                        <i className='bi bi-geo-alt-fill text-danger me-2 fs-3'></i> 2.4 km
                      </p>
                      <p className='fs-7' style={{color: '#3F4254'}}>
                        Jl. Raya Sutorejo No.98 - 100, Dukuh Sutorejo, Kec. Mulyorejo, Kota SBY,
                        Jawa Timur 60113
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </Fragment>
  )
}

export default Profile
