import React, { FC} from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import {Overview} from './components/Overview'
import {Projects} from './components/Projects'
import {ProfileHeader} from './ProfileHeader'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import 'react-toastify/dist/ReactToastify.css';



const profileBreadCrumbs: Array<PageLink> = [
    {
        title: 'Profil Majelis',
        path: '/profil-majelis/dashboard-majelis',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]



// @ts-ignore
const DashboardMajelis: FC = () => {
  const intl = useIntl()
  return (
    <>
        <ProfileHeader />
        <Switch>
            <Route path='/profil-majelis/dashboard-majelis'>
                <PageTitle breadcrumbs={profileBreadCrumbs}>Dashboard Majelis</PageTitle>
                <Overview />
            </Route>
            <Route path='/profil-majelis/danataawun'>
                <PageTitle breadcrumbs={profileBreadCrumbs}>Pangkalan Data Sekolah dan Madrasah Muhammadiyah</PageTitle>
                <Projects />
            </Route>
            <Redirect from='/profil-majelis' exact={true} to='/profil-majelis/dashboard-majelis' />
            <Redirect to='/profil-majelis/dashboard-majelis' />
        </Switch>
    </>
  )
}

// @ts-ignore
export { DashboardMajelis }
