import React, {useEffect} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import beritaContent from './beritaContent.json'


export function HalamanBerita() {
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {}, [])

  return (
    <div>
      {/* begin::Dashboard */}

      {/* begin::Row */}
      <div className='row'>
        {
          beritaContent.map((item, index) => {
            return (
              <div className='col-xl-4 mb-5' key={index}>
                <div className='card card-custom gutter-b card-stretch'>
                  {/* begin::Header */}
                  <div className='card-header border-0 pt-5'>
                    <div className='card-title'>
                      <div className='card-label'>
                        <div className='font-weight-bolder'>
                          <Link 
                            to={{
                              pathname: `/berita/${item.title.toLocaleLowerCase().replace(/[S,"/"]/g, '-')}?id=${item.id}`
                            }}
                          >
                            {item.title}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end::Header */}

                  {/* begin::Body */}
                  <div className='card-body'>
                    {/* begin::Foto */}
                    <div className='card card-customs bgi-no-repeat'>
                      <img
                        src={window.location.origin+'/media/berita/'+item.image}
                        style={{
                          height: '245px',
                          objectFit: 'scale-down',
                        }}
                      />
                      {/* begin::Item */}
                    </div>
                    {/* end::Foto */}

                    {/* begin::Items */}
                    <div className='gutter-b d-flex flex-column align-items-center justify-content-center pt-10 pb-3 bgi-size-cover bgi-no-repeat rounded-top'>
                      {/* begin::Item */}
                      <div className='gutter-b d-flex flex-column align-items-center justify-content-center pt-10 pb-3'>
                        <p>
                          {item.content.slice(0, 200)}...
                        </p>
                      </div>
                      {/* end::Item */}
                    </div>
                    {/* end::Items */}
                  </div>
                  {/* end::Body */}
                </div>
              </div>
            )
          })
        }
      </div>
      {/* end::Row */}
      {/* end::Dashboard */}
    </div>
  )
}
