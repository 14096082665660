import axios from 'axios'

const profile_sekolah = (sekolahId) => {
  return function (dispatch) {
    dispatch({type: 'DETAIL_SEKOLAH', payload: []})
    const API = process.env.REACT_APP_API_URL_NEW
    const TOKEN = process.env.REACT_APP_TOKEN_NEW
    const dataParams = {
      schoolId: sekolahId,
    }

    const sign = require('jwt-encode')
    const secret = TOKEN
    const jwt = sign(dataParams, secret)

    const data = {
      params: {
        items: jwt,
      },
    }

    axios
      .get(`${API}/school/detail`, {
        headers: {
          key: TOKEN,
          Authorization: `Bearer ${localStorage.access_token}`,
        },
        params: data,
      })
      .then(({data}) => {
        dispatch({type: 'DETAIL_SEKOLAH', payload: data.data})
      })
      .catch((err) => {
        // if (err.response["00"] === 401) {
        //   dispatch({type: 'AUTHORIZED', payload: false})
        //   localStorage.clear()
        //   window.location.reload()
        // }
        dispatch({type: 'DETAIL_SEKOLAH', payload: []})
        console.warn("catch detail sekolah");
      })

    axios
      .get(`https://pd.dikdasmenppm.org/api/school/detail/operation`, {
        headers: {
          key: TOKEN,
          Authorization: `Bearer ${localStorage.access_token}`,
        },
        params: data,
      })
      .then(({data}) => {
        console.log('data', data)
        // dispatch({ type: 'DETAIL_SEKOLAH', payload: data.data })
      })
      .catch((err) => {
        console.log('err', err)
      })
  }
}

export default profile_sekolah
