import axios from 'axios'

const all_data_level = () => {
  return function (dispatch) {
    const API = process.env.REACT_APP_API_URL_NEW
    const TOKEN = process.env.REACT_APP_TOKEN_NEW
    dispatch({type: 'LOADING', payload: true})
    axios
      .get(`${API}/jenjang`, {
        headers: {
          key: TOKEN,
          Authorization: `Bearer ${localStorage.access_token}`,
        },
      })
      .then(({data}) => {
        dispatch({type: 'ALL_DATA_LEVEL', payload: data.data})
        dispatch({type: 'LOADING', payload: false})
      })
      .catch((err) => {
        dispatch({type: 'ALL_DATA_LEVEL', payload: []})
        dispatch({type: 'LOADING', payload: false})

        if (err.response.status === 401) {
          dispatch({type: 'AUTHORIZED', payload: false})
          localStorage.clear()
          window.location.reload()
        }
        console.log(err, 'ini errr')
      })
  }
}

export default all_data_level
