const initialState = {
  respGuru: [],
}

const postGuruReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ALL_DATA_POST_GURU':
      return {...state, respGuru: action.payload}
    default:
      return state
  }
}

export default postGuruReducer
