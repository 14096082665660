const initialState = {
  detailSekolah: [],
  keuangan: [],
  pageKeuangan: [],
  dataOperator: {
    total_guru: 5,
    total_rombongan: 6,
    total_siswa_l: 0,
    total_siswa_p: 0,
  },
  dataGuru: {
    total_guru_l: 3,
    total_guru_p: 2,
    total_guru_married: 0,
    total_guru_no_married: 0,
  },
  dataPendidikanGuru: {
    total_strata: 4,
    total_diploma: 0,
    total_other: 0,
    total_slta: 1,
  },
  dataRataRataHonorer: {
    head_master_salary: 10000000,
    head_master_count: 4,
    head_master_avg: 2500000,
    teacher_salary: 158160315,
    teacher_count: 73,
    teacher_avg: 2166579.657534247,
    employee_salary: 7500000,
    employee_count: 3,
    employee_avg: 2500000,
  },
  dataListGuru: [
    {
      id: 141,
      sch_id: 'CEC238CA-0A19-455D-A5B2-51A53E3416E3',
      level_id: '8',
      nuptk: '123123',
      name: 'Bayu',
      position: 'guru',
      entry_date: '2021-12-12',
      status: 'honorer',
      is_allowance: '0',
      fee_allowance: 12000,
      created_at: null,
      updated_at: null,
      entry_date_name: 'Minggu',
      level_name: 'sastra1',
      hourly_salary: 75,
      marital_status: 'Menikah',
    },
    {
      id: 142,
      sch_id: 'CEC238CA-0A19-455D-A5B2-51A53E3416E3',
      level_id: '8',
      nuptk: '321321',
      name: 'Indra',
      position: 'Guru',
      entry_date: '2021-12-12',
      status: 'pns',
      is_allowance: '0',
      fee_allowance: 120000,
      created_at: null,
      updated_at: null,
      entry_date_name: 'Minggu',
      level_name: 'sastra1',
      hourly_salary: 750,
      marital_status: 'Menikah',
    },
  ],
  imageList: [
    'https://foto2.data.kemdikbud.go.id/getImage/20402814/0.jpg',
    'https://foto2.data.kemdikbud.go.id/getImage/20402814/1.jpg',
    'https://foto2.data.kemdikbud.go.id/getImage/20402814/2.jpg',
    'https://foto2.data.kemdikbud.go.id/getImage/20402814/3.jpg',
    'https://foto2.data.kemdikbud.go.id/getImage/20402814/4.jpg',
    'https://foto2.data.kemdikbud.go.id/getImage/20402814/5.jpg',
    'https://foto2.data.kemdikbud.go.id/getImage/20402814/6.jpg',
    'https://foto2.data.kemdikbud.go.id/getImage/20402814/7.jpg',
    'https://foto2.data.kemdikbud.go.id/getImage/20402814/8.jpg',
    'https://foto2.data.kemdikbud.go.id/getImage/20402814/9.jpg',
    'https://foto2.data.kemdikbud.go.id/getImage/20402814/10.jpg',
    'https://foto2.data.kemdikbud.go.id/getImage/20402814/11.jpg',
  ],
}

const profileSekolahReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'DETAIL_SEKOLAH':
      return {...state, detailSekolah: action.payload}
    case 'KEUANGAN':
      return {...state, keuangan: action.payload}
    case 'PAGE_KEUANGAN':
      return {...state, pageKeuangan: action.payload}
    default:
      return state
  }
}

export default profileSekolahReducer
